/**
 * LPLocationPath Component
 * 
 * This component displays the location path based on the current state of the application.
 * It updates the document title and dispatches actions to update the state based on the URL and menu items.
 * 
 * Written by Fabrice Miras
 * Date: November 24, 2024
 */

import { Theme, alpha } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import Utils from '../felles/Utils';
import C from '../felles/UtilsCSS';
import * as actions from '../store/actions/index';
import { AppState } from '../store/rootReducer';

// Define styles using makeStyles
const useStyles = makeStyles()((theme: Theme) => ({
  base: {
    fontFamily: '"Inter", sans-serif',
    fontWeight: 'normal',
    fontSize: '1.1em',
    marginLeft: 0,
    marginTop: 0,
    color: '#fff',
    [C.r(theme, C.XXS, C.XXX)]: { fontSize: '1.1em' },
    [C.r(theme, C.XS, C.XSX)]: { fontSize: '1.1em' },
    [C.r(theme, C.SM, C.SMX)]: { fontSize: '1.2em' },
    [C.r(theme, C.MD, C.MDX)]: { fontSize: '1.3em' },
    [C.r(theme, C.LG, C.LGX)]: { fontSize: '1.4em' },
    [C.r(theme, C.XL, C.XLX)]: { fontSize: '1.5em' },
    [C.r(theme, C.XXL, C.YLX)]: { fontSize: '1.6em' },
    [C.IPAD_10]: { color: '#000', fontSize: '1em', marginTop: 15 },
    [C.SAMSUNG]: { color: '#000', fontSize: '1em', marginTop: 15 },
  },
  pathItem: {
    display: 'flex',
    columnGap: '6px',
  },
  visible: {
    visibility: 'visible',
    opacity: '1',
    transition: 'opacity .3s linear',
    color: '#201f1f',
    padding: '3px 1px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  hidden: {
    visibility: 'hidden',
    opacity: '0',
    transition: 'visibility 0s .1s, opacity .1s linear',
    backgroundColor: alpha('#2E2D2C', 1),
    borderRadius: '5px',
    color: '#f6f6f6',
    padding: '3px 17px',
  },
}));

// Main component
export default function LPLocationPath() {
  const { classes } = useStyles();
  const dispatch = useDispatch();

  // Selectors to get state from the Redux store
  const menuItems = useSelector((state: AppState) => state.menuItem.menuItems);
  const isDebugEnabled = useSelector((state: AppState) => state.utils.isDebugEnabled);
  const currentBreakpoint = useSelector((state: AppState) => state.ui.currentBreakpoint);
  const scrollTop = useSelector((state: AppState) => state.ui.scrollTop);
  const selected = useSelector((state: AppState) => state.menuItem.selected);
  const locationPath = useSelector((state: AppState) => state.ui.locationPath);

  // useEffect to handle side effects
  useEffect(() => {
    // Get the last four characters from the URL
    const tranId = Utils.getLastFourCharactersFromURL();

    // If tranId is found and menuItems are available
    if (tranId && menuItems.length > 0) {
      const menuItem = Utils.getTransactionsMenuItem(menuItems, tranId);
      if (menuItem) {
        // Set the document title
        document.title = `${menuItem.code} ${menuItem.description}`;
        // Dispatch actions to update the state
        dispatch(actions.menuItemSetExpanded([menuItem.rootName]));
        dispatch(actions.setLocationPath(Utils.getLocationPathFromMenuEl(menuItem)));
      }
    } else if (!tranId && menuItems.length > 0) {
      // If tranId is not found, set the location path from the selected menu item
      dispatch(actions.setLocationPath(Utils.getLocationPathFromSelected(selected)));
    }
  }, [dispatch, menuItems, selected]);

  // Render the component
  return (
    <div className={classes.base}>
      <div className={classes.pathItem}>
        {isDebugEnabled && `${currentBreakpoint} | `}
        <div className={scrollTop > 20 || scrollTop === 0 ? classes.visible : classes.hidden}>
          {locationPath}
        </div>
      </div>
    </div>
  );
}