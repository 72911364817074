import { Button, Tooltip } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const baseButtonStyle = {
    display: 'inline-block',
    color: '#fff',
    borderRadius: 3,
    boxShadow: '0 0 5px -1px rgba(0,0,0,0.2)',
    cursor: 'pointer',
    verticalAlign: 'middle',
    width: '100px',
    padding: '5px',
    marginTop: '13px',

    // textAlign: "center',
    '&:hover': {
        color: 'white',
        cursor: 'pointer',
    },
    '&:active': {
        color: 'white',
    },
    '&:disabled': {
        color: 'white',
        background: '#a9a9a9',  // Darker grey for disabled state
    },
};

// Styles
const useStyles = makeStyles()(() => ({
    // Button styles
    vitecBtRed: {
        ...baseButtonStyle,
        background: '#d30535',
        '&:hover': {
            background: '#ea063b',
        },
        '&:active': {
            background: '#f91047',
        },
        '&:disabled': {

            color: 'white',
            background: '#a9a9a9',  // Darker grey for disabled state
        },
    },
    vitecBtGreen: {
        ...baseButtonStyle,
        background: '#1d6f42',
        '&:hover': {
            background: '#21824d',
        },
        '&:active': {
            background: '#279659',
        },
        '&:disabled': {
            color: 'white',
            background: '#a9a9a9',  // Darker grey for disabled state
        },
    },
    vitecBtBlue: {
        ...baseButtonStyle,
        background: '#1976d2',
        '&:hover': {
            background: '#1f82e5',
            color: '"#f3f3f3"',
        },
        '&:active': {
            color: '"#f3f3f3"',
            background: '#368fe7',
        },
        '&:disabled': {
            color: '"#f3f3f3"',
            background: '#a9a9a9',  // Darker grey for disabled state
        },
    },
    customTooltip: { fontSize: 15, fontWeight: 100, color: 'white', margin: 0, marginTop: 10, marginLeft: -5, },
    customArrow: { fontSize: 15, },
})
);
// ButtonVitec component
export default function ButtonVitec(props) {
    const { classes } = useStyles();
    //
   
    return (
        <Tooltip classes={{
            tooltip: classes.customTooltip,
            arrow: classes.customArrow
        }} title={props._tooltip} placement={"top"} arrow>
            <span>
                <Button type={props && props._type ? props._type : 'button'}
                    className={props && props._color?.toLowerCase() === 'green' ? classes.vitecBtGreen : props._color?.toLowerCase() === 'blue' ? classes.vitecBtBlue : classes.vitecBtRed}
                    onClick={e => (typeof props._onclick === 'function' ? props._onclick(e) : null)}
                    disabled={props.disabled}
                    style={{
                        fontSize: props._fontsize,
                        fontFamily: 'roboto',
                        lineHeight: 1.5,
                        fontWeight: 450,
                        width: props._width, height: props._height, margin: props._padding,
                        boxShadow: '4px 4px 6px rgba(0, 0, 0, 0.2)',
                    }}
                    name={props._name}
                    value={props._value}
                >{props._label}</Button>
            </span>
        </Tooltip>
    );
}