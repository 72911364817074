import { push } from '@lagunovsky/redux-react-router';
import { EventChannel, eventChannel } from 'redux-saga';
import { call, fork, put, select, take } from 'redux-saga/effects';
import Utils from '../../felles/Utils';

import HeirloomFormData from '../../models/payload/HeirloomFormData';
import SimpleMSG from '../../models/SimpleMSG';
import UserUpdatePayload from '../../models/UserUpdatePayload';
import * as actions from '../actions';
import * as selectors from '../selectors';
import { postErrorToSupport } from '../../felles/APICalls_SPRING';
import { fetchTransById, getHelpTransVitec, getWSDataFromAxios, postHeirloomsFormDataToJavaAPI, postTransFormDataAxios } from '../../felles/APICalls_VSE';
import Enums from '../../felles/Enums';

let socket: WebSocket;
let channel: EventChannel<any>;

export function* setInputArrayStates(response: any, formDataforBugRepporting: HeirloomFormData = new HeirloomFormData()) {
  // console.log(' htmlContent.body.innerHTML; ', response.data.body.textContent.includes('COBOL runtime error'));
  const formData: string = formDataforBugRepporting?.formData ? formDataforBugRepporting.formData : 'No form data provided.';
  const transId: string = formDataforBugRepporting?.transId ? formDataforBugRepporting?.transId + ' ' : 'No transId.';

  try {
    if (response.data?.body?.textContent?.includes('COBOL runtime error')) {
      yield sendErrorToSupport(response.data.body.textContent, formData, transId);
      return;
    }

    // Clean up
    yield put(actions.resetKeyInputFieldBinding());

    // 25 lines
    const inputArrayLineState: any[] = [[], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []];

    //
    if (response.data.body.getAttribute('onload')?.toLowerCase()?.includes('focusoneditablefield')) {
      const fieldToAttachFocus = response.data.body.getAttribute('onload')?.split("('")[1].split("')")[0] || '';
      yield put(actions.setFieldSelectEnabled(true));
      yield put(actions.setFieldToAttachFocus(fieldToAttachFocus));
      yield put(actions.setDefaultFieldFocus(fieldToAttachFocus));
    }
    //
    const form = response.data.body.getElementsByTagName('form')[0] as HTMLFormElement;
    const sdf: HTMLCollection = (form as any).getElementsByTagName('input') as HTMLCollection;
    const result = [].slice.call(sdf);
    //
    const initState: any = {};
    result.forEach((element) => {
      if ((element as HTMLFormElement).getAttribute('class')?.includes('line')) {
        const index = (element as HTMLFormElement).getAttribute('class')?.split('line')[1].split(' ')[0];
        if (index) {
          inputArrayLineState[parseInt(index)].push((element as HTMLFormElement).getAttribute('name'));
        }
      }
      //
      if ((element as HTMLInputElement).value) {
        initState[(element as HTMLInputElement).name] = (element as HTMLInputElement).value;
      }
    });
    //
    const inputArrayLineStateFiltered = inputArrayLineState.filter(function (el) {
      return el.length > 0 ? el : null;
    });
    //
    yield put(actions.updateInputArrayState(result));
    // Populates inputs coords list as [y][x]
    yield put(actions.updateInputArrayLineState(inputArrayLineStateFiltered));
    //
    yield put(actions.setInputState(initState));
  } catch (error) {
    const formData: string = (formDataforBugRepporting as HeirloomFormData)?.formData
      ? (formDataforBugRepporting as HeirloomFormData).formData + ' -----===== ERROR =====-----> ' + (error as any)?.message
      : 'No form data provided.  -----===== ERROR =====-----> ' + (error as any)?.message;
    const transId: string = (formDataforBugRepporting as HeirloomFormData)?.transId ? (formDataforBugRepporting as HeirloomFormData)?.transId + ' ' : 'No transId.';
    yield sendErrorToSupport(response.data.body.textContent, formData, transId);
  }
}

// todo: initialize UserUpdatePayload
export function* sendErrorToSupport(error: any, formData, transId) {
  const userProfileData: UserUpdatePayload = yield select(selectors.userProfileData);
  yield put(actions.transFormDataPostFailed(new SimpleMSG('Cobol Runtime', error.toString())));
  //
  try {
    yield put(actions.AutoErrorSendingPostStarted());
    const responseFromPost = yield postErrorToSupport(userProfileData.email, error, formData, transId);
    yield put(actions.AutoErrorSendingPostSuccess(responseFromPost.data));
  } catch (error) {
    yield put(actions.AutoErrorSendingPostFailed(error));
  }
}

function getConnection(wsMSG, isNewSocket = false): EventChannel<any> {
  //
  return eventChannel((emitter) => {
    //
    const initConnection = () => {
      // let connectionUrl: string = Utils.IS_ENV_DEV(true)
      //        ? `ws://${Utils.HOST_NAME}/vitec/terminalwebsocket`
      //        : `${Utils.WEBSOCKET_ADDRESS}/vitec/terminalwebsocket`;

      const connectionUrl: string = Utils.WEBSOCKET_ADDRESS + '/vitec/terminalwebsocket';
      //
      if (isNewSocket) {
        socket = new WebSocket(connectionUrl);
      } else {
        if (!socket) {
          socket = new WebSocket(connectionUrl);
        }
      }
      if (wsMSG !== '' && socket.readyState === socket.OPEN) {
        socket.send(wsMSG);
      }
      socket.onopen = () => {
        socket.send(wsMSG);
      };
      socket.onmessage = (event) => {
        emitter(event.data);
      };
      socket.onclose = () => {
        if (channel) {
          channel.close();
        }
        // setTimeout(initConnection, 4000); //"Reconnect in 4s
        // or
        // setTimeout(() => getConnection(wsMSG,isNewSocket), 2000); //"Reconnect in 4s
      };
    };
    initConnection();
    return () => {
      console.log('Socket off');
    };
  });
}

function* wsConnectionWorker(action, isNewSocket) {
  let wsRequest = '';
  switch (true) {
    case typeof action === 'string':
      wsRequest = action;
      break;
    case action.payload?.wsMessage !== '':
      wsRequest = action.payload?.wsMessage;
      break;
    default:
      wsRequest = '';
      break;
  }
  /*const channel: EventChannel<boolean> =*/
  channel = yield call(getConnection, wsRequest, isNewSocket);
  // On Channel ready
  if (channel) {
    while (true) {
      const _payload: any = yield take(channel);
      //
      if (!Utils.isAModalCall(_payload)) yield put(actions.setTermID(_payload));
      if (_payload) {
        yield getWsdata(_payload);
      }
    }
  }
}

export function* userLogoutFromTerminalSaga() {
  try {
    if (socket) {
      socket.close();
      yield console.log('Closing WS socket');
    }
  } catch (error) {
    console.log('Problem closing websocket -> userLogoutFromTerminalSaga');
  }
}

/*
 * Simple GET method that calls the transaction presentation
 *
 *
 * ░░███╗░░░██████╗████████╗
 * ░████║░░██╔════╝╚══██╔══╝
 * ██╔██║░░╚█████╗░░░░██║░░░
 * ╚═╝██║░░░╚═══██╗░░░██║░░░
 * ███████╗██████╔╝░░░██║░░░
 * ╚══════╝╚═════╝░░░░╚═╝░░░
 *
 */
export function* fecthTransactionById(action: any) {
  const tabId = yield select(selectors.tabId);
  let zTermId: any = undefined;
  yield put(actions.resetHTMLContent());
  yield put(push(`${Utils.HOMEPAGE_CONTEXT}/vse?transid=${action.payload.code}`));
  try {
    yield put(actions.transGetInit());
    const response = yield fetchTransById(action.payload, tabId);
    if (response && response.data) {
      yield setInputArrayStates(response, new HeirloomFormData());
      const bob = (response.data.head as Document).querySelectorAll('script');
      bob.forEach((e) => {
        if (e.innerHTML.includes('termId')) {
          zTermId = e.innerHTML.split('termId')[1].split('"')[1];
        }
      });

      yield put(actions.transGetSuccess(response.data, zTermId));
      yield put(actions.setIsTransPostInProgress(false));
      yield put(actions.setIsTransGetInProgress(false));
      yield put(actions.menuItemSetTarget(action.payload));
      yield put(actions.menuItemSetSelected([action.payload.rootItem, action.payload.item + action.payload.rootItem]));
      if (zTermId) {
        yield fork(wsConnectionWorker, 'termId=' + zTermId, true);
      }
    }
  } catch (error) {
    yield put(actions.transGetFailed(error));
  }
}

/*
 * POST data, parse the response and search for a termid value that will trigger a websocket call
 *
 * ██████╗░███╗░░██╗██████╗░
 * ╚════██╗████╗░██║██╔══██╗
 * ░░███╔═╝██╔██╗██║██║░░██║
 * ██╔══╝░░██║╚████║██║░░██║
 * ███████╗██║░╚███║██████╔╝
 * ╚══════╝╚═╝░░╚══╝╚═════╝░
 *
 */
export function* postTransFormdata(action: any) {
  const tabId: string = yield select(selectors.tabId);
  const progId: string = yield select(selectors.progId);
  try {
    // Important to the next channel event.
    yield fork(wsConnectionWorker, '', false);

    // Initialize the post and loading screen
    yield put(actions.transFormDataPostInit());

    // Call API
    const response = yield postTransFormDataAxios(action.payload, tabId);

    // Await the response
    if (response && response.data) {
      yield fork(setInputArrayStates, response, action.payload);
      yield put(actions.transFormDataPostSuccess(response.data));
      // Creates a new websocket connection ready for the next post
      yield fork(wsConnectionWorker, 'termId=' + action.payload.termId, true);
      
     
      /*  //KEEPING THIS FOR FUTURE REFERENCE
      const doc:Document = response.data;
      if (doc.body.textContent?.includes('SLETTING FULLFØRT. FORTSETT') || doc.body.textContent?.includes('REGISTRERING FULLFØRT. FORTSETT')) {
        if (progId && Enums.AuthorizedTransactionPostToJavaAPI.includes(progId)) {
          try {
            yield postHeirloomsFormDataToJavaAPI(action.payload, progId, (action.payload as HeirloomFormData).productId);
          } catch (error) {
            if (process.env.NODE_ENV === 'development') {
              console.log('Error in postTransFormdata: ', error);
            }
          }
        }
      }
        /**/
    }
  } catch (error) {
    yield put(actions.transFormDataPostFailed(new SimpleMSG('Error', (error as any).toString())));
    yield put(push(`${Utils.HOMEPAGE_CONTEXT}/support`));
  }
}

/*
 *
 *
 */
export function* postToWebSocket(action: any) {
  try {
    yield fork(wsConnectionWorker, action.payload, false);
  } catch (error) {
    console.log('Post to ws failed: ', error);
  }
}

/*
 * GET triggered by postTransFormdata Websocket callback
 */
export function* getWsdata(action: any) {
  const tabId = yield select(selectors.tabId);
  // OPEN MODAL WINDOW CALLED
  if (Utils.isAModalCall(action)) {
    try {
      yield put(actions.transOpenModalWindowPostInit());
      yield put(actions.transOpenModalWindowPostSuccess(action));
    } catch (error) {
      yield put(actions.transOpenModalWindowPostFailed(error));
    }
  } else {
    const params: any = Utils.getJsonFromUrl(action.replace('/servlet?', '&'));
    const navFormData = new HeirloomFormData(params.transid, params.transid ? params.scode : '', '');
    try {
      yield put(push(`${Utils.HOMEPAGE_CONTEXT}${action.replace('/servlet', '/vse')}`));
      const response = yield getWSDataFromAxios(navFormData, tabId);
      if (response && response.data /* && response.body*/) {
        yield fork(setInputArrayStates, response, new HeirloomFormData());
      }
      yield put(actions.transFormDataPostSuccess(response.data));
    } catch (error) {
      yield put(actions.transFormDataPostFailed(new SimpleMSG('Error in getWsdata', (error as any).toString())));
    }
  }
}

/*
 *
 *
 */
export function* fecthHelpTransaction(action: any) {
  yield put(actions.transHelpGetInit());
  try {
    const repVitec = yield getHelpTransVitec(action.payload);
    if (repVitec) {
      if (repVitec.data) {
        const by = new Uint8Array(repVitec.data);
        const utf8RespText = Array.from(by)
          .map(function (item) {
            return String.fromCharCode(item);
          })
          .join('');
        yield put(actions.transHelpGetSuccess(utf8RespText));
      }
    }
  } catch (error) {
    yield put(actions.transHelpGetFailed(error));
  }
}
