// Importing necessary components and libraries
import { alpha, Checkbox, FormControlLabel, Grid, Pagination, PaginationItem, Paper, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Theme } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import 'react-grid-layout/css/styles.css';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import ButtonVitec from '../../../components/ButtonVitec';
import ToolTipTfWrapper from '../../../components/ToolTipTfWrapper';
import Utils from '../../../felles/Utils';
import { NV03Firma, NV03Headers, default as NV03Valf, default as NV03Vare } from '../../../models/NV03Vare';
import PageImpl from '../../../models/PageImpl';
import * as actions from '../../../store/actions/index';
import * as actionsNV03 from '../../../store/actions/indexNV03';
import { AppState } from '../../../store/rootReducer';
import NV03VALFTableHead, { hInit } from './NV03VALFTableHead';
import { history } from '../../../store/history';

// Define custom styles using makeStyles
const useStyles = makeStyles()((theme: Theme) => ({
  // Root container styling
  root: { margin: 20, fontSize: '1em !important', color: '#fff' },
  container: {
    border: 'none',
    "&.label.Mui-focused ": { color: 'white', }
  },
  table: { minWidth: 700, fontSize: 200, borderRadius: '5px', },
  tC: {
    paddingLeft: 20, textAlign: 'left', fontSize: 16,
  },
  tCB: {
    paddingLeft: 20, textAlign: 'left', fontSize: 16, color: 'RED',
  },
  ba: { border: 'none', marginTop: 10 },
  bb: { color: '#2E2D2C' },
  tField: {
    display: 'flex',
    backgroundColor: alpha('#fff', 0.4),
    // border: '2px solid orange',
    '& label': { fontSize: 20, height: 45, lineHeight: .8, backgroundColor: '1px solid #4f4d4c', },
    height: 45,
    //'& label.Mui-focused': { top: 12, animationIterationCount: 1, transform: 'translate(0, -10px) scale(1)', fontSize: '.75em', color: '#d30535', },
    //'& .MuiInputLabel-formControl': { top: 11, left: 6, position: 'absolute', transform: 'translate(0, 0) scale(1)', color: '#d30535', },
    '& .MuiInput-underline:after': { height: 45, width: 0, fontSize: 20 },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      fontSize: 20,
      border: '1px solid #c4c4c4',
    },
    // (Note: space or no space after `&` matters. See SASS "parent selector".)
    '& .MuiOutlinedInput-root': {
      color: 'black',
      height: 47,
      fontSize: 20,

      // - The Input-root, inside the TextField-root
      '& fieldset': {
        border: '2px solid white',
        /* - The <fieldset> inside the Input-root */
      },
      '&:hover fieldset': {},
      '&.Mui-focused ': {
        backgroundColor: alpha('#fff', 0.8),
      },
    },
  },
}),
);

// Define the form input types
type FormInputs = {
  alfaKode: string,
  artikkel: string,
  varebetegnelse: string,
  varegruppe: string,
  pageNb: number,
  size: number,
  sort: string,
  ckBehold: boolean,
  ckVisAlle: boolean,
  ckVeilpris: boolean,
  ckLokasjon: boolean,
  exportToExcel: boolean,
  nV03Headers: NV03Headers[]
};

// Define props for the NV03VALFFormHook component
interface NV03VALFFormHookProps {
  size: {
    readonly width: number | null;
    readonly height: number | null;
  };
}

// Main functional component
const NV03VALFFormHook: React.FC<NV03VALFFormHookProps> = ({ size }) => {
  const { classes: cls } = useStyles();  // Accessing styles
  const dispatch = useDispatch();  // Redux dispatch
  const page: PageImpl<NV03Vare> = useSelector((state) => (state as AppState).NV03.page);
  // const isBeholdningChecked: boolean = useSelector((state) => (state as AppState).NV03.isBeholdningChecked);
  const isVeilPrisChecked: boolean = useSelector((state) => (state as AppState).NV03.isVeilPrisChecked);
  const isVisAlleLagerChecked: boolean = useSelector((state) => (state as AppState).NV03.isVisAlleLagerChecked);
  const lagerNavn: NV03Firma = useSelector((state) => (state as AppState).NV03.nV03Firma);
  const [defaultPageCount, setDefaultPageCount] = useState(10);
  const nV03Headers: NV03Headers[] = useSelector((state) => (state as AppState).NV03.nV03Headers);
  const [currentFocusedField, setCurrentFocusedField] = useState('');
  const isLoading: boolean = useSelector((state) => (state as AppState).utils.isLoading);
  const [isHentDisabled, setIsHentDisabled] = useState(true);
  const keyValuePairs4VSE: { key: string; value: any }[] = useSelector((state) => (state as AppState).ui.keyValuePairs4VSE);
  const keyValuePairs4NV03: { key: string; value: any }[] = useSelector((state) => (state as AppState).NV03.keyValuePairs4NV03);
  const currentRowFocused: number = useSelector((state) => (state as AppState).NV03.currentRowFocused);
  const scrollTopPosition: number = useSelector((state) => (state as AppState).NV03.scrollTopPosition);
  const [scrollPosition, setScrollPosition] = useState(0); // State to store scroll position
  const tableContainerRef = useRef<HTMLDivElement>(null); // Ref for the scrollable container


  // React Hook Form configuration with default values
  const methods = useForm<FormInputs>({
    defaultValues: {
      alfaKode: '',
      artikkel: '',
      varebetegnelse: '',
      varegruppe: '',
      pageNb: 0,
      size: 100,
      sort: 'artikkel',
      ckBehold: false,
      ckVisAlle: false,
      ckVeilpris: false,
      ckLokasjon: false,
      exportToExcel: false,
      nV03Headers: []
    },
  });


  {/* --------------------------------------- Effects ------------------------------------- */ }
  useEffect(() => {
    //
    const handleScroll = (e: any) => {
      if (tableContainerRef.current) {
        setScrollPosition(tableContainerRef.current.scrollTop); // Update scroll position
      }
    };

    // Get the table container
    const tableContainer = tableContainerRef.current;

    // Add event listener for scroll
    if (tableContainer) {
      tableContainer.addEventListener('scroll', Utils.debounce_new((e) => { handleScroll(e) }));
      tableContainer.scrollTop = scrollTopPosition;
    }

    // Clean up the event listener on component unmount
    return () => {
      if (tableContainer) {
        tableContainer.removeEventListener('scroll', Utils.debounce_new((e) => { handleScroll(e) }));
      }
    };
  }, []);



  useEffect(() => {
    // NettbrettNETTBRETT hack - Remove event listeners on component unmount
    document.body.removeEventListener('touchmove', Utils.preventDefault);

    // Set default page count based on content
    page.content?.length > 0 ? setDefaultPageCount(page.totalPages) : setDefaultPageCount(10);

    // Reset sandwich button state on load
    dispatch(actions.setSandwichButtonStateClicked(false));

    // Automatically focus on the first input field (alfa) when the component mounts
    if (currentFocusedField === '') {
      const inputField: HTMLInputElement | null = document.querySelector('input[name="alfaKode"]');
      if (inputField && inputField.value === '') {
        inputField.focus();
      }
    }
    // Load column headers if not already loaded
    if (nV03Headers.length === 0) {
      dispatch(actionsNV03.NV03UpdateColumnHeaderList(hInit));
    }
    // Add event listeners for keyboard shortcuts
    document.addEventListener('keyup', handleKeyUpPressedAtDocumentLevel);
    // Keep this for now
    // document.addEventListener('keydown', handleKeyDownPressedAtDocumentLevel);

    // Await for the keyValuePairs4VSE to be set before redirecting to VSE
    if (keyValuePairs4VSE.length > 0) {
      history.push(Utils.HOMEPAGE_CONTEXT + '/vse?transid=VAVE');
    }

    if (keyValuePairs4NV03.length > 0) {
      keyValuePairs4NV03.forEach((element: { key: string; value: any }) => {
        methods.setValue(element.key as keyof FormInputs, element.value);
      });
    }

    if (keyValuePairs4NV03.length > 0 && isHentDisabled) {
      setIsHentDisabled(false);
      methods.handleSubmit(onSubmit)();
      dispatch(actionsNV03.setKeyValuePairs4NV03([]));
    }
    // Cleanup function to dispatch cleanComponent action on component unmount
    return () => {
      // Remove event listeners for keyboard shortcuts
      document.removeEventListener('keyup', handleKeyUpPressedAtDocumentLevel);
      // Keep this for now
      // document.removeEventListener('keydown', handleKeyDownPressedAtDocumentLevel);
    };
  }, [page.content, nV03Headers, lagerNavn, isLoading, currentRowFocused, keyValuePairs4VSE, currentFocusedField, dispatch]);


  // Handle keyboard shortcuts 
  // Keep this for now
  // const handleKeyDownPressedAtDocumentLevel = (event) => { };


  // Handle keyboard shortcuts (F7, F8, F9)
  const handleKeyUpPressedAtDocumentLevel = (event) => {
    const { key } = event;
    const actionSource = key || event.target.value;
    event.preventDefault(); //event.stopPropagation();
    const index = page.content.findIndex(item => item.id === currentRowFocused);
    //setKey
    switch (true) {
      case actionSource === 'Escape': {
        dispatch(actionsNV03.setCurrentRowFocused(-1));
        const inputField: HTMLInputElement | null = document.querySelector('input[name="alfaKode"]');
        if (inputField) {
          inputField.focus();
        }
        // TODO: Give focus back to the previous used input field
        break;
      }
      case actionSource === 'ArrowUp': {
        const activeUp = (page.content[index - 1]?.id ? page.content[index - 1].id : page.content[0]?.id ? page.content[page.content.length - 1]?.id : 0);
        dispatch(actionsNV03.setCurrentRowFocused(activeUp));
        break;
      }
      case actionSource === 'ArrowDown': {
        const activeDown = (page.content[index + 1]?.id ? page.content[index + 1].id : page.content[0]?.id ? page.content[0]?.id : 0);
        dispatch(actionsNV03.setCurrentRowFocused(activeDown));
        break;
      }
      case actionSource === 'F4' || (actionSource === 'Enter' && currentRowFocused !== -1): {
        // Reset the form state
        dispatch(actionsNV03.NV03SetResetFormEnabled(false));
        // Reset the child component state
        if (page.content.length > 0) {
          const n: NV03Vare | undefined = page.content.find((n: NV03Vare) => n.id === currentRowFocused);
          if (n) {
            // Set the URL callback to NV03
            dispatch(actions.setURLCallBack('NV03'));
            // Set the key value pairs for the VSE component
            const keyValuePairs4VSE: { key: string; value: any }[] = [];
            keyValuePairs4VSE.push({ key: 'productId', value: n.id });
            keyValuePairs4VSE.push({ key: 'transid', value: 'VAVE' });
            keyValuePairs4VSE.push({ key: 'SKODE', value: 'X' });
            keyValuePairs4VSE.push({ key: 'SALFA', value: n.alfa });
            keyValuePairs4VSE.push({ key: 'SARTNR', value: n.artikkelNummer.trim() });
            keyValuePairs4VSE.push({ key: 'SEDBNR', value: n.edbNr.trim() });
            keyValuePairs4VSE.push({ key: 'VGR', value: n.vareGruppe });
            dispatch(actions.setKeyValuePairs4VSE(keyValuePairs4VSE));
          }
        }
        break;
      }
      // F7 navigates to the previous page
      case actionSource === 'F7':
        if (methods.getValues('pageNb') >= 1) {
          methods.setValue('pageNb', methods.getValues('pageNb') - 1)
          methods.handleSubmit(onSubmit)();
        }
        break;
      // F8 navigates to the next page
      case actionSource === 'F8':
        if (methods.getValues('pageNb') < defaultPageCount - 1) {
          methods.setValue('pageNb', methods.getValues('pageNb') + 1)
          methods.handleSubmit(onSubmit)();
        }
        break;
      // Reset button resets the form and data
      case actionSource === 'F9': {
        // Reset the form state
        methods.reset();
        // Reset the child component state
        dispatch(actionsNV03.NV03ResetForm(true));
        // Focus on the first input field (alfa) after reset
        const inputField: HTMLInputElement | null = document.querySelector('input[name="alfaKode"]');
        if (inputField && inputField.value === '') {
          inputField.focus();
        }
        break;
      }
      default:
        return;
    }
  };

  // 
  const handleRowSelection = (event, n: NV03Vare) => {
    dispatch(actionsNV03.setScrollTopPosition(scrollPosition));
    dispatch(actionsNV03.setCurrentRowFocused(n.id));
  };

  // Handle keyboard shortcuts (F7, F8, Enter, etc.)
  const handleKeyPress = (event) => {
    setIsHentDisabled(false);
    if (methods.getValues('alfaKode') === ''
      && methods.getValues('artikkel') === ''
      && methods.getValues('varebetegnelse') === ''
      && methods.getValues('varegruppe') === '') {
      setIsHentDisabled(true);
    }
    // Get the key pressed
    const { key } = event;
    const actionSource = key || event.target.value;
    event.preventDefault();
    //setKey
    switch (true) {
      // Enter submits the form
      case (actionSource === 'Enter' || actionSource === 'Export') && !isHentDisabled:
        actionSource === 'Export' ? methods.setValue('exportToExcel', true) : methods.setValue('exportToExcel', false);
        methods.setValue('pageNb', 0)
        methods.handleSubmit(onSubmit)();
        break;
      // Reset button resets the form and data
      case actionSource === 'Reset': {
        // Reset the form state
        methods.reset();
        dispatch(actionsNV03.setCurrentRowFocused(-1));
        setIsHentDisabled(true);
        // Reset the child component state
        dispatch(actionsNV03.NV03ResetForm(true));
        dispatch(actionsNV03.setKeyValuePairs4NV03([])); // Reset the key value pairs
        // Focus on the first input field (alfa) after reset
        const inputField: HTMLInputElement | null = document.querySelector('input[name="alfaKode"]');
        if (inputField && inputField.value === '') {
          inputField.focus();
        }
        break;
      }
      default:
        return;
    }
  };

  // Function to handle form submission
  const onSubmit = (data: NV03Valf | any) => {
    // check if alfaKode is not empty
    if (methods.getValues('alfaKode').toString().length > 0) {
      // check if nV03Headers is empty
      if (data.nV03Headers.length === 0) {
        data.nV03Headers = hInit;
      }
      // Set the key value pairs for the NV03 component
      const keyValuePairs4NV03: { key: string; value: any }[] = [];
      keyValuePairs4NV03.push({ key: 'alfaKode', value: methods.getValues('alfaKode') });
      keyValuePairs4NV03.push({ key: 'artikkel', value: methods.getValues('artikkel') });
      keyValuePairs4NV03.push({ key: 'varebetegnelse', value: methods.getValues('varebetegnelse') });
      keyValuePairs4NV03.push({ key: 'varegruppe', value: methods.getValues('varegruppe') });
      dispatch(actionsNV03.setKeyValuePairs4NV03(keyValuePairs4NV03));
      // Check if the form is in loading state and prevent multiple submissions
      if (!isLoading) {
        dispatch(actionsNV03.NV03VALFListeGet(data));
        methods.setValue('exportToExcel', false)
      }
    }
  };

  // Function to handle pagination changes
  const pagechange = (e: any, pageValue = 0) => {
    methods.setValue('pageNb', pageValue - 1)// offsetting by 1
    methods.handleSubmit(onSubmit)();
  };

  // Function to handle sorting
  const setSort = (value: NV03Headers[]) => {
    methods.setValue('nV03Headers', value);
    methods.handleSubmit(onSubmit)();
  };

  // Helper function to find object by ID from headers
  function getObjectById(id: string): NV03Headers | undefined {
    return nV03Headers.find(row => row.id === id);
  }

  // Function to shift focus
  const goToNextField = (e, val) => {
    if (e.value.length == e.getAttribute('maxlength')) {
      const inputField: HTMLInputElement | null = document.querySelector('input[name="' + val + '"]');
      // setCurrentFocusedField(val); issue on F3
      if (inputField) {
        inputField.focus();
      }
    }
  };

  //  
  const toggledWithSpaceBar = (event) => {
    const { key } = event;
    const inputName = event.currentTarget.querySelector("input")?.name;
    //  
    if (inputName && key === ' ') {
      methods.setValue(inputName, !methods.getValues(inputName));
      dispatch(actionsNV03.setIsBeholdningCheckboxState(methods.getValues(inputName)))
      methods.handleSubmit(onSubmit)();
    }
  }

  // Function to check if any filter is active
  const hasHearderListAFilterActive = (): boolean => {
    return nV03Headers.some((header) => header.filter !== '');
  };

  // Rendering the form and table
  return (
    <FormProvider {...methods}>
      <form className={cls.root} onKeyUp={handleKeyPress}>
        <input  {...methods.register("pageNb")} hidden />
        <input  {...methods.register("size")} hidden />
        <input  {...methods.register("sort")} hidden />
        <input  {...methods.register("exportToExcel")} hidden />
        <Paper elevation={0} sx={{ backgroundColor: alpha('#fff', .6) }} >
          <TableContainer sx={{ borderRadius: '5px' }} >
            <Grid container width={'100%'} height={page.content.length > 0 ? `calc(100vh - 122px)` : 'auto'} style={{ maxHeight: '100vh' }}>
              {/* HEADER SECTION */}
              <Grid container height={'130px'} justifyContent='left' className={cls.ba} >
                <Grid container height={'40px'} className={cls.bb} rowSpacing={0} columnSpacing={3} direction="row"
                  justifyContent="left" alignItems="center">
                  <Grid container height={'10px'} alignItems="center" justifyContent='left' className={cls.bb} />
                  <Grid item />
                  <Grid item xs={1} sx={{ minWidth: '100px' }}>
                    <ToolTipTfWrapper id='talfa'
                      title={<div>Alfabetisk gruppebetegnelse for varen, som kan bestå av null, to eller tre tegn.</div>}
                      placement={"top"} delay={8000}>
                      <TextField label="Alfa" autoComplete="off" className={cls.tField} inputProps={{ maxLength: 3, shrink: 'false' }}
                        {...methods.register("alfaKode", { required: false })}
                        onClick={(e) => { dispatch(actionsNV03.setCurrentRowFocused(-1)); }}
                        onChange={(e) => {
                          dispatch(actionsNV03.setCurrentRowFocused(-1));
                          goToNextField(e.target, 'artikkel');
                          methods.setValue('alfaKode', (e.target as any).value.toUpperCase());
                        }}
                      />
                    </ToolTipTfWrapper>
                  </Grid>
                  <Grid item xs={3} sx={{ minWidth: '200px' }}>
                    <ToolTipTfWrapper id="artikkel"
                      title={<div>Her tastes det artikkelnummer du vil starte på, eller deler av et artikkelnummer.<br />
                        Det kan også hoppes over.</div>} placement={"top"} delay={8000}>
                      <TextField label="Artikkel" autoComplete="off" className={cls.tField} {...methods.register("artikkel", { required: false })}
                        onClick={(e) => { dispatch(actionsNV03.setCurrentRowFocused(-1)); }}
                        onChange={(e) => {
                          dispatch(actionsNV03.setCurrentRowFocused(-1));
                          methods.setValue('artikkel', (e.target as any).value);
                        }}
                      />
                    </ToolTipTfWrapper> </Grid>
                  <Grid item xs={2}>
                    <ToolTipTfWrapper id='varebetegnelse'
                      title={<div>Dersom du kun ønsker artikler innenfor en varebetegnelse, kan denne tastes inn her.</div>}
                      placement={"top"} delay={8000}>
                      <TextField label="Varebetegnelse" autoComplete="off" className={cls.tField} {...methods.register("varebetegnelse", { required: false })}
                        onClick={(e) => { dispatch(actionsNV03.setCurrentRowFocused(-1)); }}
                        onChange={(e) => {
                          dispatch(actionsNV03.setCurrentRowFocused(-1));
                          methods.setValue('varebetegnelse', (e.target as any).value);
                        }}
                      />
                    </ToolTipTfWrapper></Grid>
                  <Grid item xs={2}>
                    <ToolTipTfWrapper id='varegruppe'
                      title={<div>Dersom du kun ønsker artikler innenfor en varegruppe, kan denne tastes inn her.</div>} placement={"top"} delay={8000}>
                      <TextField label="Varegruppe" autoComplete="off" className={cls.tField} {...methods.register("varegruppe", { required: false })}
                        onClick={(e) => { dispatch(actionsNV03.setCurrentRowFocused(-1)); }}
                        onChange={(e) => {
                          dispatch(actionsNV03.setCurrentRowFocused(-1));
                          methods.setValue('varegruppe', (e.target as any).value);
                        }}
                      /></ToolTipTfWrapper>
                  </Grid>
                  <Grid item>
                    <div style={{ marginBottom: 10 }}>
                      <ButtonVitec _onclick={handleKeyPress} _width={105} _height={37} _weight={600} _type={'button'}
                        _label={'HENT'} _value={'Enter'} _tooltip={'Klikk på knappen for å sende inn skjemaet, eller trykk Enter'}
                        disabled={isHentDisabled}
                      />
                    </div>
                  </Grid>
                  <Grid item xs="auto" />
                </Grid>
                {/* CHECKBOX SECTION */}
                <Grid container height={'10px'} className={cls.bb} rowSpacing={0} columnSpacing={3} direction="row"
                  justifyContent="left" alignItems="center">
                  <Grid item />
                  {/* Checkbox for Beholdning */}
                  <Grid item xs={1.05} sx={{ minWidth: '200px' }}>
                    <FormControlLabel sx={{ width: '220px', paddingTop: '10px' }} label={"Kun med beholdning"}
                      control={<Controller control={methods.control}
                        name="ckBehold" render={({ field: { onChange, value } }) => (
                          <ToolTipTfWrapper id='ckBehold'
                            title={<div> Vil kun vise data der beholdningsverdien ikke er lik 0.</div>} placement={"top"} delay={8000}>
                            <Checkbox onKeyUp={toggledWithSpaceBar} onChange={(event, item) => {
                              onChange(item);
                              dispatch(actionsNV03.setIsBeholdningCheckboxState(!value))
                              methods.handleSubmit(onSubmit)();
                            }} name={'ckBehold'} checked={value} /></ToolTipTfWrapper>)}
                      />} />
                  </Grid>
                  {/* Checkbox for Vis alle lager */}
                  <Grid item xs={1.05} sx={{ minWidth: '140px' }}>
                    <FormControlLabel sx={{ width: '220px', paddingTop: '10px' }} label="Vis alle lager" control={<Controller control={methods.control}
                      name="ckVisAlle" render={({ field: { onChange, value } }) => (
                        <Checkbox onKeyUp={toggledWithSpaceBar} onChange={(event, item) => {
                          onChange(item);
                          dispatch(actionsNV03.setIsVisAlleLagerCheckboxState(!value))
                          methods.handleSubmit(onSubmit)();
                        }} name={'ckVisAlle'} checked={value || false} />)}
                    />} />
                  </Grid>
                  {/* Checkbox for Veil. pris */}
                  <Grid item xs={0.85} sx={{ minWidth: '100px' }}>
                    <FormControlLabel sx={{ width: '120px', paddingTop: '10px' }} label="Veil. pris"
                      control={<Controller control={methods.control}
                        name="ckVeilpris" render={({ field: { onChange, value } }) => (
                          <Checkbox onKeyUp={toggledWithSpaceBar} onChange={(event, item) => {

                            onChange(item);
                            dispatch(actionsNV03.setIsVeilPrisCheckboxState(!value))
                            methods.handleSubmit(onSubmit)();
                          }} name={'ckVeilpris'} checked={value} />)}
                      />} />
                  </Grid>
                  {/* Checkbox for Lokasjon */}
                  <Grid item xs={1} sx={{ minWidth: '190px' }}>
                    <FormControlLabel sx={{ width: '190px', paddingTop: '10px' }} label="Kun med lokasjon"
                      control={<Controller control={methods.control}
                        name="ckLokasjon" render={({ field: { onChange, value } }) => (
                          <Checkbox onKeyUp={toggledWithSpaceBar} onChange={(event, item) => {
                            onChange(item);
                            dispatch(actionsNV03.setIsLocationCheckboxState(!value))
                            methods.handleSubmit(onSubmit)();
                          }} name={'ckLokasjon'} checked={value} />)}
                      />} />
                  </Grid>
                  {/****************************************************************  Excel Export Button */}
                  <Grid item>
                    <div style={{ marginBottom: 10 }}>
                      <ButtonVitec _onclick={handleKeyPress} _width={105} _height={37} _type={'button'} _color={'green'}
                        _label={'EXCEL'} _value={'Export'} disabled={page.content.length === 0 || isHentDisabled} />
                    </div>
                  </Grid>
                  <Grid item>
                    <div style={{ marginBottom: 10 }}>
                      <ButtonVitec _onclick={handleKeyPress} _width={105} _height={37} _weight={600} _type={'button'}
                        _label={'NULLSTILL'} _value={'Reset'} _tooltip={'Tilbakestill skjemaet F9'}
                        disabled={
                          false
                          //page.content.length === 0 && !hasHearderListAFilterActive()
                        }
                        _color={'red'} />
                    </div>
                  </Grid>
                  <Grid item xs="auto" />
                </Grid>
                <Grid container height={'5px'} alignItems="center" justifyContent='left' className={cls.bb} />
              </Grid>
              {/* CONTENT SECTION - Table */}
              <Grid container
                height={page.content.length > 0 ? `calc(100vh - 273px)` : 'auto'}
                alignItems="top"
                justifyContent='center'
                className={cls.ba}
                ref={tableContainerRef}
                sx={{
                  maxHeight: '100vh', overflow: 'auto',
                  "&::-webkit-scrollbar-thumb": { backgroundColor: "#706c6a", },
                }}
              >
                {page.content.length > 0 || hasHearderListAFilterActive() ?
                  <Grid item width={'100%'} >
                    <Table stickyHeader className={cls.table} size="small" aria-labelledby="tableTitle" >
                      <NV03VALFTableHead onRequestSort={setSort} />
                      <TableBody sx={{ width: '100%' }}>
                        {
                          Array.isArray(page.content) && page.content?.length > 0 ? (
                            page.content.map((n: NV03Vare) => (
                              <TableRow
                                aria-checked={false}
                                key={n.id}
                                selected={false}
                                sx={{
                                  backgroundColor: currentRowFocused === n.id ? '#d8eff2' : n.slett === 'S' ? '#FFCCCB' : '#fff',
                                  border: '1px solid #fff',
                                  '&:hover': {
                                    backgroundColor: currentRowFocused === n.id ? '#d8eff2' : '#f3f3f3', // Change this to your desired hover color
                                  }
                                }}
                                onClick={(event) => { handleRowSelection(event, n) }}
                              >
                                <TableCell className={n.beholdning.toString().includes('-') ? cls.tCB : cls.tC}
                                  sx={{ width: hInit.find(hd => hd.id === 'alfa')?.width }}>{n.alfa}</TableCell>
                                <TableCell className={n.beholdning.toString().includes('-') ? cls.tCB : cls.tC} sx={{ width: hInit.find(hd => hd.id === 'artikkelNummer')?.width }}>{n.artikkelRaw}</TableCell>
                                <TableCell className={n.beholdning.toString().includes('-') ? cls.tCB : cls.tC} sx={{ width: hInit.find(hd => hd.id === 'vareSpes')?.width }}>{n.vareSpes}</TableCell>
                                <TableCell className={n.beholdning.toString().includes('-') ? cls.tCB : cls.tC} sx={{ width: hInit.find(hd => hd.id === 'vareGruppe')?.width }}>{n.vareGruppe}</TableCell>
                                <TableCell className={n.beholdning.toString().includes('-') ? cls.tCB : cls.tC} sx={{ width: hInit.find(hd => hd.id === 'utSalgPris')?.width }}>{n.utSalgPris}</TableCell>
                                {isVeilPrisChecked
                                  ? <TableCell className={n.beholdning.toString().includes('-') ? cls.tCB : cls.tC} sx={{ width: hInit.find(hd => hd.id === 'veilPris')?.width }}>{n.varettt?.veilPris}</TableCell> : null}
                                <TableCell className={n.beholdning.toString().includes('-') ? cls.tCB : cls.tC} sx={{ width: hInit.find(hd => hd.id === 'lokasjon')?.width }}>{n.lokasjon}</TableCell>
                                {/*isBeholdningChecked ? <TableCell className={n.beholdning.toString().includes('-') ? cls.tCB : cls.tC}>{n.beholdning}</TableCell> : null*/}
                                <TableCell className={n.beholdning.toString().includes('-') ? cls.tCB : cls.tC} sx={{ width: hInit.find(hd => hd.id === 'beholdning')?.width }}>{n.beholdning}</TableCell>
                                {isVisAlleLagerChecked && getObjectById('lk10')?.vis === 1
                                  ? <TableCell className={n.beholdning.toString().includes('-') ? cls.tCB : cls.tC} sx={{ width: hInit.find(hd => hd.id === 'lk10')?.width }}>{n.lk10}</TableCell> : null}
                                {isVisAlleLagerChecked && getObjectById('lk18')?.vis === 1
                                  ? <TableCell className={n.beholdning.toString().includes('-') ? cls.tCB : cls.tC} sx={{ width: hInit.find(hd => hd.id === 'lk18')?.width }}>{n.lk18}</TableCell> : null}
                                {isVisAlleLagerChecked && getObjectById('lk13')?.vis === 1
                                  ? <TableCell className={n.beholdning.toString().includes('-') ? cls.tCB : cls.tC} sx={{ width: hInit.find(hd => hd.id === 'lk13')?.width }}>{n.lk13}</TableCell> : null}
                                {isVisAlleLagerChecked && getObjectById('lk15')?.vis === 1
                                  ? <TableCell className={n.beholdning.toString().includes('-') ? cls.tCB : cls.tC} sx={{ width: hInit.find(hd => hd.id === 'lk15')?.width }}>{n.lk15}</TableCell> : null}
                                {isVisAlleLagerChecked && getObjectById('lk17')?.vis === 1
                                  ? <TableCell className={n.beholdning.toString().includes('-') ? cls.tCB : cls.tC} sx={{ width: hInit.find(hd => hd.id === 'lk17')?.width }}>{n.lk17}</TableCell> : null}
                                {isVisAlleLagerChecked && getObjectById('lk92')?.vis === 1
                                  ? <TableCell className={n.beholdning.toString().includes('-') ? cls.tCB : cls.tC} sx={{ width: hInit.find(hd => hd.id === 'lk92')?.width }}>{n.lk92}</TableCell> : null}
                                {isVisAlleLagerChecked && getObjectById('lk93')?.vis === 1
                                  ? <TableCell className={n.beholdning.toString().includes('-') ? cls.tCB : cls.tC} sx={{ width: hInit.find(hd => hd.id === 'lk93')?.width }}>{n.lk93}</TableCell> : null}
                                <TableCell className={n.beholdning.toString().includes('-') ? cls.tCB : cls.tC} sx={{ width: hInit.find(hd => hd.id === 'auto')?.width }}>{n.auto}</TableCell>
                                <TableCell className={n.beholdning.toString().includes('-') ? cls.tCB : cls.tC} sx={{ width: hInit.find(hd => hd.id === 'merknad')?.width }}>{n.merknad}</TableCell>
                              </TableRow>
                            ))
                          ) : (
                            <TableRow>
                              <TableCell colSpan={4} className={cls.tC} scope="row" padding="none">
                                No data available
                              </TableCell>
                            </TableRow>
                          )
                        }
                      </TableBody>
                    </Table>
                  </Grid>
                  : null}
              </Grid>
              {/*********************************************************** FOOTER *****************************************************************/}
            </Grid>
          </TableContainer>
          {/* FOOTER SECTION - Pagination */}
          {page.content.length > 0 ?
            <Grid container height={"39px"} alignItems="center" justifyContent='center'
              className={cls.bb} rowSpacing={0} columnSpacing={0}
            >
              <Grid item  >
                <Pagination sx={{
                  color: 'white',
                  "& .Mui-selected": {
                    backgroundColor: "#fff"
                  }
                }}
                  count={defaultPageCount}
                  onChange={(e, value) => pagechange(e, value)}
                  onKeyUp={(e) => pagechange(e)}
                  page={methods.getValues('pageNb') + 1}
                  renderItem={(item) => (
                    <PaginationItem sx={{ fontSize: '1.2em', }}
                      {...item}
                    />
                  )}
                />
              </Grid>
            </Grid>
            : null}</Paper>
      </form>
    </FormProvider >
  );
};
// Exporting the NV03VALFFormHook component
export default NV03VALFFormHook;