import U from '../../felles/Utils';
import FirmNavnId from '../../models/FirmNavnId';
import TalenesTale, { TaleFavorittListe } from '../../models/TalenesTale';

import TTReportItem from '../../models/payload/TTReportItem';
import * as fromApp from '../rootReducer';
import * as actionsSS11 from '../../store/actions/actionTypesSS11';
import * as actionsSS10 from '../../store/actions/actionTypesSS10';

export interface FeatureState extends fromApp.AppState {
  state: STALE;
}

export interface STALE {
  selectedCriteriaItemList: number[];
  criteriaItemList: TalenesTale[];
  redirectPath: string;
  error: any;
  isFetchingCriteriaListItem: boolean;
  isFetchingCriteriaItem: boolean;
  isFetchingTaleCriteriaExcelList: boolean;
  isUpdatingCriteriaItem: boolean;
  isDeletingCriteriaItem: boolean;
  isRegisteringCriteriaItem: boolean;
  criteriaItemEditState: number;
  criteriaItemRegistrationState: number;
  criteriaItemDeletionState: number;
  isCriteriaDeletionDialogueOpen: boolean;
  criteriaItemId: any;
  isFetchingFavorittListe: boolean;
  isSavingFavorittListe: boolean;
  isDeletingFavorittListe: boolean;
  favorittListeState: number;
  taleFavorittListe: TaleFavorittListe[];
  visningType: string;
  isFetchingSalesReport: boolean;
  tallenesGetSalgsstatiskkState: number;
  salesReportKunde: TTReportItem[];
  salesReportVGruppe: TTReportItem[];
  salesReportHGruppe: TTReportItem[];
  salesReportGruppe: TTReportItem[];
  salesReportSGruppe: TTReportItem[];
  taleSearchKunde: any;
  taleSearchVGruppe: any;
  taleSearchHGruppe: any;
  taleSearchGruppe: any;
  taleSearchSGruppe: any;
  excelImportState: number;
  selectedSubKunde: FirmNavnId;
  selectedKunde: FirmNavnId;
}

const initialState = {
  selectedCriteriaItemList: [],
  criteriaItemList: [],
  redirectPath: '/',
  error: undefined,
  isFetchingCriteriaListItem: false,
  isFetchingCriteriaItem: false,
  isFetchingTaleCriteriaExcelList: false,
  isUpdatingCriteriaItem: false,
  isDeletingCriteriaItem: false,
  isRegisteringCriteriaItem: false,
  criteriaItemEditState: 0,
  criteriaItemRegistrationState: 0,
  criteriaItemDeletionState: 0,
  isCriteriaDeletionDialogueOpen: false,
  criteriaItemId: undefined,
  isFetchingFavorittListe: false,
  isSavingFavorittListe: false,
  isDeletingFavorittListe: false,
  favorittListeState: 0,
  taleFavorittListe: [],
  visningType: '',
  isFetchingSalesReport: false,
  tallenesGetSalgsstatiskkState: 0,
  salesReportKunde: [],
  salesReportVGruppe: [],
  salesReportHGruppe: [],
  salesReportGruppe: [],
  salesReportSGruppe: [],

  taleSearchKunde: undefined,

  taleSearchVGruppe: undefined,
  taleSearchHGruppe: undefined,
  taleSearchGruppe: undefined,
  taleSearchSGruppe: undefined,
  excelImportState: 0,
  selectedSubKunde: new FirmNavnId(0, '', 0,  ''),
  selectedKunde: new FirmNavnId(0, '', 0,  '')

};


const setFullResetSalesReport = (state: STALE) => {
  return U.updateStore(state, { salesReportKunde: [], salesReportVGruppe: [], salesReportHGruppe: [], salesReportGruppe: [], salesReportSGruppe: [], });
};

const setResetSalesReport = (state: STALE) => {
  return U.updateStore(state, { salesReportVGruppe: [], salesReportHGruppe: [], salesReportGruppe: [], salesReportSGruppe: [], });
};


const setSelectedSubKunde = (state: STALE, action: any) => { return U.updateStore(state, { selectedSubKunde: action.payload }); };
const setSelectedKunde = (state: STALE, action: any) => { return U.updateStore(state, { selectedKunde: action.payload }); };


const setExcelImportState = (state: STALE, action: any) => { return U.updateStore(state, { excelImportState: action.payload }); };

//= (payload: any) => { return { type: actionsSS11.EXCEL_IMPORT_STATE, payload }; };

const setTaleSearchKundeParams = (state: STALE, action: any) => { return U.updateStore(state, { taleSearchKunde: action.taleSearch }); };
const setTaleSearchVGruppeParams = (state: STALE, action: any) => { return U.updateStore(state, { taleSearchVGruppe: action.taleSearch }); };
const setTaleSearchHGruppeParams = (state: STALE, action: any) => { return U.updateStore(state, { taleSearchHGruppe: action.taleSearch }); };
const setTaleSearchGruppeParams = (state: STALE, action: any) => { return U.updateStore(state, { taleSearchGruppe: action.taleSearch }); };
const setTaleSearchSGruppeParams = (state: STALE, action: any) => { return U.updateStore(state, { taleSearchSGruppe: action.taleSearch }); };

const favorittListeGetStart = (state: STALE) => { return U.updateStore(state, { isFetchingFavorittListe: true }); };
const favorittListeGetSuccess = (state: STALE, action: any) => { return U.updateStore(state, { taleFavorittListe: action.taleFavorittListe, isFetchingFavorittListe: false }); };
const favorittListeGetFailed = (state: STALE, action: any) => { return U.updateStore(state, { isFetchingFavorittListe: false, error: action }); };

const tallenesGetSalgsstatiskkStart = (state: STALE) => { return U.updateStore(state, { isFetchingSalesReport: true }); };


const tallenesGetSalgsstatiskkKundeSuccess = (state: STALE, action: any) => { return U.updateStore(state, { salesReportKunde: action.salesReport, isFetchingSalesReport: false }); };

const tallenesGetSalgsstatiskkVGruppeSuccess = (state: STALE, action: any) => { return U.updateStore(state, { salesReportVGruppe: action.salesReport, isFetchingSalesReport: false }); };
const tallenesGetSalgsstatiskkHGruppeSuccess = (state: STALE, action: any) => { return U.updateStore(state, { salesReportHGruppe: action.salesReport, isFetchingSalesReport: false }); };
const tallenesGetSalgsstatiskkGruppeSuccess = (state: STALE, action: any) => { return U.updateStore(state, { salesReportGruppe: action.salesReport, isFetchingSalesReport: false }); };
const tallenesGetSalgsstatiskkSGruppeSuccess = (state: STALE, action: any) => { return U.updateStore(state, { salesReportSGruppe: action.salesReport, isFetchingSalesReport: false }); };

const tallenesGetSalgsstatiskkPDFSuccess = (state: STALE) => { return U.updateStore(state, { isFetchingSalesReport: false }); };
const tallenesGetSalgsstatiskkExcelSuccess = (state: STALE) => { return U.updateStore(state, { isFetchingSalesReport: false }); };

const tallenesGetSalgsstatiskkFailed = (state: STALE, action: any) => { return U.updateStore(state, { isFetchingSalesReport: false, error: action }); };

const setTallenesGetSalgsstatiskkState = (state: STALE, action: any) => { return U.updateStore(state, { tallenesGetSalgsstatiskkState: action.tallenesGetSalgsstatiskkState }); };

const setfavorittListeVisningType = (state: STALE, action: any) => { return U.updateStore(state, { visningType: action.visningType }); };

const favorittListeUpdateStarted = (state: STALE) => { return U.updateStore(state, { isSavingFavorittListe: true }); };
const favorittListeUpdateSuccess = (state: STALE) => { return U.updateStore(state, { isSavingFavorittListe: false, favorittListeState: 2 }); };
const favorittListeUpdateFailed = (state: STALE, action: any) => { return U.updateStore(state, { isSavingFavorittListe: false, favorittListeState: 3, error: action }); };

const favorittListeListSelectedDeletionStarted = (state: STALE) => { return U.updateStore(state, { isDeletingFavorittListe: true }); };
const favorittListeListSelectedDeletionSuccess = (state: STALE) => { return U.updateStore(state, { isDeletingFavorittListe: false, favorittListeState: 2 }); };
const favorittListeListSelectedDeletionFailed = (state: STALE, action: any) => { return U.updateStore(state, { isDeletingFavorittListe: false, favorittListeState: 3, error: action }); };

const setFavorittListeState = (state: STALE, action: any) => { return U.updateStore(state, { favorittListeState: action.favorittListeState }); };

const criteriaItemListGetStart = (state: STALE) => { return U.updateStore(state, { isFetchingCriteriaListItem: true }); };
const criteriaItemListGetSuccess = (state: STALE, action: any) => { return U.updateStore(state, { criteriaItemList: action.criteriaItemList, isFetchingCriteriaListItem: false }); };
const criteriaItemListGetFailed = (state: STALE, action: any) => { return U.updateStore(state, { isFetchingCriteriaListItem: false, error: action }); };
//
const criteriaItemListGetAsExcelStart = (state: STALE) => { return U.updateStore(state, { isFetchingTaleCriteriaExcelList: true }); };
const criteriaItemListGetAsExcelSuccess = (state: STALE) => { return U.updateStore(state, { isFetchingTaleCriteriaExcelList: false }); };
const criteriaItemListGetAsExcelFailed = (state: STALE) => { return U.updateStore(state, { isFetchingTaleCriteriaExcelList: false }); };
//
const setCriteriaEditState = (state: STALE, action: any) => { return U.updateStore(state, { criteriaItemEditState: action.criteriaItemEditState }); };
const setCriteriaDeletionState = (state: STALE, action: any) => { return U.updateStore(state, { criteriaItemDeletionState: action.criteriaItemDeletionState }); };
const setCriteriaRegistrationState = (state: STALE, action: any) => { return U.updateStore(state, { criteriaItemRegistrationState: action.criteriaItemRegistrationState }); };

//
const setCriteriaItemId = (state: STALE, action: any) => { return U.updateStore(state, { criteriaItemId: action.criteriaItemId }); };


const criteriaItemRegistrationStarted = (state: STALE) => { return U.updateStore(state, { isRegisteringCriteriaItem: true, criteriaItemRegistrationState: 1 }); };
const criteriaItemRegistrationSuccess = (state: STALE) => { return U.updateStore(state, { isRegisteringCriteriaItem: false, criteriaItemRegistrationState: 2 }); };
const criteriaItemRegistrationFailed = (state: STALE, action: any) => { return U.updateStore(state, { isRegisteringCriteriaItem: false, criteriaItemRegistrationState: 3, error: action.error }); };

const setCriteriaItemSelectedList = (state: STALE, action: any) => { return U.updateStore(state, { selectedCriteriaItemList: action.selectedCriteriaItemList }); };

const criteriaItemUpdateStarted = (state: STALE) => { return U.updateStore(state, { isUpdatingCriteriaItem: true }); };
const criteriaItemUpdateSuccess = (state: STALE) => { return U.updateStore(state, { isUpdatingCriteriaItem: false, criteriaItemEditState: 2 }); };
const criteriaItemUpdateFailed = (state: STALE, action: any) => { return U.updateStore(state, { isUpdatingCriteriaItem: false, criteriaItemEditState: 3, error: action }); };

const criteriaItemListSelectedItemDeletionStarted = (state: STALE) => { return U.updateStore(state, { isDeletingCriteriaItem: true }); };
const criteriaItemListSelectedItemDeletionSuccess = (state: STALE) => { return U.updateStore(state, { isDeletingCriteriaItem: false, criteriaItemDeletionState: 2 }); };
const criteriaItemListSelectedItemDeletionFailed = (state: STALE, action: any) => { return U.updateStore(state, { isDeletingCriteriaItem: false, criteriaItemDeletionState: 3, error: action }); };

const criteriaItemDeletionDialogUpdated = (state: STALE, action: any) => { return U.updateStore(state, { isCriteriaDeletionDialogueOpen: action.payload }); };

const voidCriteriaItemList = (state: STALE) => { 
  state.criteriaItemList = [];
  U.updateStore(state, { criteriaItemList: [] });
  return initialState;
};

const tallenesTaleReducer = (state = initialState, action: any) => {
  switch (action.type) {

    case actionsSS11.VOID_TCRIT_LIST: return voidCriteriaItemList(state);
    case actionsSS11.TCRIT_LIST_GET_INIT: return criteriaItemListGetStart(state);
    case actionsSS11.TCRIT_LIST_GET_SUCCESS: return criteriaItemListGetSuccess(state, action);
    case actionsSS11.TCRIT_LIST_GET_FAILED: return criteriaItemListGetFailed(state, action);
    case actionsSS11.TCRIT_UPDATED_STATE: return setCriteriaEditState(state, action);
    case actionsSS11.TCRIT_DELETION_STATE: return setCriteriaDeletionState(state, action);
    case actionsSS11.TCRIT_REGISTRATION_STATE: return setCriteriaRegistrationState(state, action);
    case actionsSS11.TCRIT_ITEM_REGISTRATION_POST_INIT: return criteriaItemRegistrationStarted(state);
    case actionsSS11.TCRIT_ITEM_REGISTRATION_POST_SUCCESS: return criteriaItemRegistrationSuccess(state);
    case actionsSS11.TCRIT_ITEM_REGISTRATION_POST_FAILED: return criteriaItemRegistrationFailed(state, action);
    case actionsSS11.TCRIT_DELETION_DIALOGUE_UPDATED: return criteriaItemDeletionDialogUpdated(state, action);
    case actionsSS11.TCRIT_LIST_SELECTED_DELETION_POST_INIT: return criteriaItemListSelectedItemDeletionStarted(state);
    case actionsSS11.TCRIT_LIST_SELECTED_DELETION_POST_SUCCESS: return criteriaItemListSelectedItemDeletionSuccess(state);
    case actionsSS11.TCRIT_LIST_SELECTED_DELETION_POST_FAILED: return criteriaItemListSelectedItemDeletionFailed(state, action);
    case actionsSS11.TCRIT_ITEM_UPDATED_POST_INIT: return criteriaItemUpdateStarted(state);
    case actionsSS11.TCRIT_ITEM_UPDATED_POST_SUCCESS: return criteriaItemUpdateSuccess(state);
    case actionsSS11.TCRIT_ITEM_UPDATED_POST_FAILED: return criteriaItemUpdateFailed(state, action);
    case actionsSS11.TCRIT_SET_SELETED_LIST: return setCriteriaItemSelectedList(state, action);
    case actionsSS11.TCRIT_LIST_GET_AS_EXCEL_INIT: return criteriaItemListGetAsExcelStart(state)
    case actionsSS11.TCRIT_LIST_GET_AS_EXCEL_SUCCESS: return criteriaItemListGetAsExcelSuccess(state)
    case actionsSS11.TCRIT_LIST_GET_AS_EXCEL_FAILED: return criteriaItemListGetAsExcelFailed(state)
    case actionsSS11.TCRIT_SET_CRITERIA_ITEM_ID: return setCriteriaItemId(state, action);
    case actionsSS10.TFAVORITTLISTE_LIST_GET_INIT: return favorittListeGetStart(state);
    case actionsSS10.TFAVORITTLISTE_LIST_GET_SUCCESS: return favorittListeGetSuccess(state, action);
    case actionsSS10.TFAVORITTLISTE_LIST_GET_FAILED: return favorittListeGetFailed(state, action);
    case actionsSS10.TFAVORITTLISTE_LIST_SAVE_POST_INIT: return favorittListeUpdateStarted(state)
    case actionsSS10.TFAVORITTLISTE_LIST_SAVE_POST_SUCCESS: return favorittListeUpdateSuccess(state)
    case actionsSS10.TFAVORITTLISTE_LIST_SAVE_POST_FAILED: return favorittListeUpdateFailed(state, action);
    case actionsSS10.TFAVORITTLISTE_LIST_DELETE_POST_INIT: return favorittListeListSelectedDeletionStarted(state)
    case actionsSS10.TFAVORITTLISTE_LIST_DELETE_POST_SUCCESS: return favorittListeListSelectedDeletionSuccess(state)
    case actionsSS10.TFAVORITTLISTE_LIST_DELETE_POST_FAILED: return favorittListeListSelectedDeletionFailed(state, action);
    case actionsSS10.TFAVORITTLISTE_SET_STATE: return setFavorittListeState(state, action);
    case actionsSS10.TFAVORITTLISTE_LIST_GET_REPORT_INIT: return tallenesGetSalgsstatiskkStart(state);
    case actionsSS10.TFAVORITTLISTE_LIST_GET_REPORT_K_SUCCESS: return tallenesGetSalgsstatiskkKundeSuccess(state, action);
    case actionsSS10.TFAVORITTLISTE_LIST_GET_REPORT_V_SUCCESS: return tallenesGetSalgsstatiskkVGruppeSuccess(state, action);
    case actionsSS10.TFAVORITTLISTE_LIST_GET_REPORT_H_SUCCESS: return tallenesGetSalgsstatiskkHGruppeSuccess(state, action);
    case actionsSS10.TFAVORITTLISTE_LIST_GET_REPORT_G_SUCCESS: return tallenesGetSalgsstatiskkGruppeSuccess(state, action);
    case actionsSS10.TFAVORITTLISTE_LIST_GET_REPORT_S_SUCCESS: return tallenesGetSalgsstatiskkSGruppeSuccess(state, action);
    case actionsSS10.TFAVORITTLISTE_LIST_GET_REPORT_PDF_SUCCESS: return tallenesGetSalgsstatiskkPDFSuccess(state);
    case actionsSS10.TFAVORITTLISTE_LIST_GET_REPORT_EXCEL_SUCCESS: return tallenesGetSalgsstatiskkExcelSuccess(state);
    case actionsSS10.TFAVORITTLISTE_LIST_GET_REPORT_FAILED: return tallenesGetSalgsstatiskkFailed(state, action);
    case actionsSS10.TFAVORITTLISTE_LIST_GET_REPORT_STATE: return setTallenesGetSalgsstatiskkState(state, action);
    case actionsSS10.TFAVORITTLISTE_SET_VISNING_TYPE: return setfavorittListeVisningType(state, action);
    case actionsSS10.T_SET_SEARCH_PARAM_KUNDE: return setTaleSearchKundeParams(state, action);
    case actionsSS10.T_SET_SEARCH_PARAM_HGRUPPE: return setTaleSearchHGruppeParams(state, action);
    case actionsSS10.T_SET_SEARCH_PARAM_GRUPPE: return setTaleSearchGruppeParams(state, action);
    case actionsSS10.T_SET_SEARCH_PARAM_VGGRUPPE: return setTaleSearchVGruppeParams(state, action);
    case actionsSS10.T_SET_SEARCH_PARAM_SGRUPPE: return setTaleSearchSGruppeParams(state, action);
    case actionsSS10.EXCEL_IMPORT_STATE: return setExcelImportState(state, action);
    case actionsSS10.SET_SELECTED_SUB_KUNDE: return setSelectedSubKunde(state, action);
    case actionsSS10.SET_SELECTED_KUNDE: return setSelectedKunde(state, action);
    case actionsSS10.SET_FULL_RESET_SALESREPORT: return  setFullResetSalesReport(state);
    case actionsSS10.SET_RESET_SALESREPORT: return  setResetSalesReport(state);
    default: return state; 
  }
};

export default tallenesTaleReducer;


