export default class PageImpl<T> {
    private _content: T[] = [];
    private _pageable: any = {};
    private _totalPages: number;

    constructor(content: T[] = [], pageable: any = {}, totalPages = 0) {
        this._content = content;
        this._pageable = pageable;
        this._totalPages = totalPages;
    }

    get content(): T[] {
        return this._content;
    }

    get pageable(): any {
        return this._pageable;
    }

    get totalPages(): number {
        return this._totalPages;
    }
}