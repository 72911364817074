
import NV03Varettt from "./NV03Varettt";

export default class NV03Vare {
    private _id: number;
    private _firmId: string;
    private _edbNr: string;
    private _alfa: string;
    private _artikkelNummer: string;
    private _artikkelRaw: string;
    private _vareSpes: string;
    private _vareGruppe: string;
    private _utSalgPris: string;
    private _veilPris: string;
    private _lokasjon: string;
    private _slett: string;
    private _beholdning: string;
    private _lk10: string;
    private _lk13: string;
    private _lk15: string;
    private _lk17: string;
    private _lk18: string;
    private _lk92: string;
    private _lk93: string;
    private _auto: string;
    private _merknad: string;
    private _rebestill: string;
    private _varettt: NV03Varettt;
    private _nV03Headers: NV03Headers[] = [];

    constructor(id = 0, firmId = '', edbNr = '', alfa = '', 
        artikkelNummer = '', artikkelRaw = '', vareGruppe = '', vareSpes = '', utSalgPris = '', veilPris = '',
        lokasjon = '',
        slett = '',
        beholdning = '',
        lk10 = '',
        lk13 = '',
        lk15 = '',
        lk17 = '',
        lk18 = '',
        lk92 = '',
        lk93 = '',
        auto = '',
        merknad = '',
        rebestill = '',
        varettt = new NV03Varettt(),
        nV03Headers: NV03Headers[] = []) {
        this._id = id;
        this._firmId = firmId;
        this._edbNr = edbNr;
        this._alfa = alfa;
        this._artikkelNummer = artikkelNummer;
        this._artikkelRaw = artikkelRaw;
        this._vareSpes = vareSpes;
        this._vareGruppe = vareGruppe;
        this._utSalgPris = utSalgPris;
        this._veilPris = veilPris;
        this._slett = slett;
        this._lokasjon = lokasjon;
        this._beholdning = beholdning;
        this._lk10 = lk10;
        this._lk13 = lk13;
        this._lk15 = lk15;
        this._lk17 = lk17;
        this._lk18 = lk18;
        this._lk92 = lk92;
        this._lk93 = lk93;
        this._auto = auto;
        this._merknad = merknad;
        this._rebestill = rebestill;
        this._varettt = varettt;
        this._nV03Headers = nV03Headers;
    }

    get id(): number {
        return this._id;
    }

    get firmId(): string {
        return this._firmId.toUpperCase();
    }

    get edbNr(): string {
        return this._edbNr;
    }

    get alfa(): string {
        return this._alfa;
    }

    get artikkelNummer(): string {
        return this._artikkelNummer;
    }

    get artikkelRaw(): string {
        return this._artikkelRaw;
    }   

    get vareGruppe(): string {
        return this._vareGruppe;
    }

    get vareSpes(): string {
        return this._vareSpes;
    }

    get utSalgPris(): string {
        return this._utSalgPris;
    }


    get veilPris(): string {
        return this._veilPris;
    }

    get lokasjon(): string {
        return this._lokasjon;
    }

    get slett(): string {
        return this._slett;
    }  

    get beholdning(): string {
        return this._beholdning;
    }

    get lk10(): string {
        return this._lk10;
    }

    get lk13(): string {
        return this._lk13;
    }

    get lk15(): string {
        return this._lk15;
    }
    get lk17(): string {
        return this._lk17;
    }

    get lk18(): string {
        return this._lk18;
    }

    get lk92(): string {
        return this._lk92;
    }

    get lk93(): string {
        return this._lk93;
    }

    get auto(): string {
        return this._auto;
    }

    get merknad(): string {
        return this._merknad;
    }

    get rebestill(): string {
        return this._rebestill;
    }

    get varettt(): NV03Varettt {
        return this._varettt;
    }

    get nV03Headers(): NV03Headers[] {
        return this._nV03Headers;
    }
}

export interface NV03Headers {
    foc: 0 | 1;
    id: string;
    //numeric: boolean;
    lb: string;
    width: string;
    isSort: 0 | 1;
    isActive: 0 | 1;
    sortDirection: 'desc' | 'asc';
    isFilter: 0 | 1;
    filter: '';
    vis: 0 | 1;
}


export class NV03Firma {

    public _id: number;
    private _firmId: string;
    private _lk10: string;
    private _lk13: string;
    private _lk93: string;
    private _lk15: string;
    private _lk17: string;
    private _lk92: string;
    private _lk18: string;

    constructor(id = 0, firmId = '', lk10 = '', lk13 = '', lk93 = '', lk15 = '', lk17 = '', lk92 = '', lk18 = '') {
        this._id = id;
        this._firmId = firmId;
        this._lk10 = lk10;
        this._lk13 = lk13;
        this._lk93 = lk93;
        this._lk15 = lk15;
        this._lk17 = lk17;
        this._lk92 = lk92;
        this._lk18 = lk18;
    }

    get id(): number {
        return this._id;
    }

    get firmId(): string {
        return this._firmId.toUpperCase();
    }

    get lk10(): string {
        return this._lk10;
    }

    get lk13(): string {
        return this._lk13;
    }

    get lk93(): string {
        return this._lk93;
    }

    get lk15(): string {
        return this._lk15;
    }

    get lk17(): string {
        return this._lk17;
    }

    get lk92(): string {
        return this._lk92;
    }

    get lk18(): string {
        return this._lk18;
    }

}
