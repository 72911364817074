import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, lighten, Theme, Toolbar, Tooltip, Typography } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import * as actions from '../../../store/actions/index';
import { AppState } from '../../../store/rootReducer';

const useStyles = makeStyles()((theme: Theme) => ({
    root: {
        paddingRight: theme.spacing(1),
        '& .MuiDatePicker': {
            marginTop: '5px',
            marginBottom: '15px',
            width: '80%',
            backgroundColor: '#d30535',

            '&:hover': {
                borderColor: '#fff',
                backgroundColor: '#d30535',
                boxShadow: 'none',
            },
        },
        backgroundColor: '#2E2D2C', borderTopLeftRadius: '5px', borderTopRightRadius: '5px'


    },
    highlight:
        theme.palette.primary.light === "light"
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85)
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark
            },
    spacer: {
        flex: "1 1 100%"
    },
    actions: {
        color: theme.palette.text.secondary
    },
    title: {
        flex: "0 0 auto"
    },
}));

const ACC1FormToolbar = (props) => {
    const dispatch = useDispatch();
    const { classes } = useStyles();
    const date = useSelector(state => (state as AppState).accounting.date);
    const open = useSelector(state => (state as AppState).accounting.isAccountingDialogOpen);
    const selected = useSelector(state => (state as AppState).accounting.selectedJobList);
    const getUpdatedListFromDate = () => dispatch(actions.accountingJobListGet());
    const markSelectedDeleted = () => dispatch(actions.accountingDeletionDialogUpdated(true));
    const handleDiagClickDelete = () => dispatch(actions.accountingJobListSelectedDeletionPost());
    const handleDiagClose = () => dispatch(actions.accountingDeletionDialogUpdated(false));

    const handleDateChange = (val: any) => {
        const _tmpDate = new Date();
        _tmpDate.setTime(val);
        dispatch(actions.accountingJobListSetDate(_tmpDate));
    }

    return (
        <Toolbar sx={{ color: 'red', backgroundColor: '#2E2D2C', border: 'none' }}
            className={classes.root}>
            <Dialog
                open={open}
                onClose={handleDiagClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Slett valgte ?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Valgte poster fra databasen vil bli merket som skjult.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDiagClose} color="primary">
                        Avbryt
                    </Button>
                    <Button onClick={handleDiagClickDelete} color="primary" autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
            <div className={classes.title}>
                {selected.length > 0 ? (
                    <Typography variant="subtitle1" sx={{ color: 'white', backgroundColor: '#2E2D2C' }}>
                        {selected.length} selected
                    </Typography>
                ) : null}
            </div>
            <div className={classes.spacer} />
            <div className={classes.actions}>
                {selected.length > 0 ? (
                    <Tooltip title="Delete">
                        <IconButton aria-label="Delete" onClick={() => markSelectedDeleted()}>
                            <DeleteIcon sx={{ color: 'white' }} />
                        </IconButton>
                    </Tooltip>
                ) : (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            openTo="year"
                            views={["year", "month"]}
                            onChange={newDate => { handleDateChange(newDate) }}
                            onClose={() => getUpdatedListFromDate()}
                            defaultValue={dayjs(date)}
                            sx={{ color: 'white', backgroundColor: '#2E2D2C', '& .MuiSvgIcon-root': { color: 'white' }, '& .MuiOutlinedInput-root': { color: 'white', '& fieldset': { border: 'none', color: 'white' }, }, }}
                        />
                    </LocalizationProvider>
                )}
            </div>
        </Toolbar>
    );
};

export default ACC1FormToolbar;
