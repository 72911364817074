import TalenesTale, { TaleFavorittListe, TaleSearchParams } from '../../models/TalenesTale';



import * as actionsSS11 from '../../store/actions/actionTypesSS11';
import * as actionsSS10 from '../../store/actions/actionTypesSS10';
//
export const criteriaItemListGetStart = () => { return { type: actionsSS11.TCRIT_LIST_GET_INIT }; };
export const criteriaItemListGet = () => { return { type: actionsSS11.TCRIT_LIST_GET }; };
export const criteriaItemListGetSuccess = (criteriaItemList: any) => {
  return { type: actionsSS11.TCRIT_LIST_GET_SUCCESS, criteriaItemList };
};

export const voidCriteriaItemList = () => { return { type: actionsSS11.VOID_TCRIT_LIST }; };
export const criteriaItemListGetFailed = () => { return { type: actionsSS11.TCRIT_LIST_GET_SUCCESS }; };

export const favorittListeGetStart = () => { return { type: actionsSS10.TFAVORITTLISTE_LIST_GET_INIT }; };
export const favorittListeGet = () => { return { type: actionsSS10.TFAVORITTLISTE_LIST_GET }; };
export const favorittListeGetSuccess = (taleFavorittListe: TaleFavorittListe) => { return { type: actionsSS10.TFAVORITTLISTE_LIST_GET_SUCCESS, taleFavorittListe }; };
export const favorittListeGetFailed = (error: any) => { return { type: actionsSS10.TFAVORITTLISTE_LIST_GET_FAILED, error: error }; };

// UPDATE
export const favorittListeUpdateStarted = () => { return { type: actionsSS10.TFAVORITTLISTE_LIST_SAVE_POST_INIT }; };
export const favorittListeUpdated = (taleFavorittListe: TaleFavorittListe) => { return { type: actionsSS10.TFAVORITTLISTE_LIST_SAVE_POST, taleFavorittListe }; };
export const favorittListeUpdateSuccess = () => { return { type: actionsSS10.TFAVORITTLISTE_LIST_SAVE_POST_SUCCESS }; };
export const favorittListeUpdateFailed = (error: any) => { return { type: actionsSS10.TFAVORITTLISTE_LIST_SAVE_POST_FAILED, error: error }; };

//DELETION
export const favorittListeListSelectedDeletionStarted = () => { return { type: actionsSS10.TFAVORITTLISTE_LIST_DELETE_POST_INIT }; };
export const favorittListeListSelectedDeletion = (payload: any) => { return { type: actionsSS10.TFAVORITTLISTE_LIST_DELETE_POST, payload: payload }; };
export const favorittListeListSelectedDeletionSuccess = () => { return { type: actionsSS10.TFAVORITTLISTE_LIST_DELETE_POST_SUCCESS }; };
export const favorittListeListSelectedDeletionFailed = (error: any) => { return { type: actionsSS10.TFAVORITTLISTE_LIST_DELETE_POST_FAILED, error: error }; };

//REPORT
export const tallenesGetSalgsstatiskkStart = () => { return { type: actionsSS10.TFAVORITTLISTE_LIST_GET_REPORT_INIT }; };
export const tallenesGetSalgsstatiskk = (taleSearchParams: TaleSearchParams) => { return { type: actionsSS10.TFAVORITTLISTE_LIST_GET_REPORT, taleSearchParams }; };

export const tallenesGetSalgsstatiskkKundeSuccess = (salesReport: any) => { return { type: actionsSS10.TFAVORITTLISTE_LIST_GET_REPORT_K_SUCCESS, salesReport }; };
export const tallenesGetSalgsstatiskkVGruppeSuccess = (salesReport: any) => { return { type: actionsSS10.TFAVORITTLISTE_LIST_GET_REPORT_V_SUCCESS, salesReport }; };
export const tallenesGetSalgsstatiskkHGruppeSuccess = (salesReport: any) => { return { type: actionsSS10.TFAVORITTLISTE_LIST_GET_REPORT_H_SUCCESS, salesReport }; };
export const tallenesGetSalgsstatiskkGruppeSuccess = (salesReport: any) => { return { type: actionsSS10.TFAVORITTLISTE_LIST_GET_REPORT_G_SUCCESS, salesReport }; };
export const tallenesGetSalgsstatiskkSGruppeSuccess = (salesReport: any) => { return { type: actionsSS10.TFAVORITTLISTE_LIST_GET_REPORT_S_SUCCESS, salesReport }; };

export const tallenesGetSalgsstatiskkPDFSuccess = () => { return { type: actionsSS10.TFAVORITTLISTE_LIST_GET_REPORT_PDF_SUCCESS }; };
export const tallenesGetSalgsstatiskkExcelSuccess = () => { return { type: actionsSS10.TFAVORITTLISTE_LIST_GET_REPORT_EXCEL_SUCCESS }; };

export const tallenesGetSalgsstatiskkFailed = (error: any) => { return { type: actionsSS10.TFAVORITTLISTE_LIST_GET_REPORT_FAILED, error: error }; };
export const setTallenesGetSalgsstatiskkState = (tallenesGetSalgsstatiskkState: any) => { return { type: actionsSS10.TFAVORITTLISTE_SET_STATE, tallenesGetSalgsstatiskkState }; };
export const setfavorittListeVisningType = (visningType: any) => { return { type: actionsSS10.TFAVORITTLISTE_SET_VISNING_TYPE, visningType }; };

// EXCEL
export const criteriaItemListGetAsExcelStart = () => { return { type: actionsSS11.TCRIT_LIST_GET_AS_EXCEL_INIT }; };
export const criteriaItemListGetAsExcel = () => { return { type: actionsSS11.TCRIT_LIST_GET_AS_EXCEL }; };
export const criteriaItemListGetAsExcelSuccess = (criteriaItemList: any) => { return { type: actionsSS11.TCRIT_LIST_GET_AS_EXCEL_SUCCESS, criteriaItemList }; };
export const criteriaItemListGetAsExcelFailed = () => { return { type: actionsSS11.TCRIT_LIST_GET_AS_EXCEL_FAILED }; };

export const setTaleSearchKundeParams = (taleSearch: any) => { return { type: actionsSS10.T_SET_SEARCH_PARAM_KUNDE, taleSearch }; };
export const setTaleSearchHGruppeParams = (taleSearch: any) => { return { type: actionsSS10.T_SET_SEARCH_PARAM_HGRUPPE, taleSearch }; };
export const setTaleSearchGruppeParams = (taleSearch: any) => { return { type: actionsSS10.T_SET_SEARCH_PARAM_GRUPPE, taleSearch }; };
export const setTaleSearchVGruppeParams = (taleSearch: any) => { return { type: actionsSS10.T_SET_SEARCH_PARAM_VGGRUPPE, taleSearch }; };
export const setTaleSearchSGruppeParams = (taleSearch: any) => { return { type: actionsSS10.T_SET_SEARCH_PARAM_SGRUPPE, taleSearch }; };

export const setCriteriaEditState = (criteriaItemEditState: any) => {
  return { type: actionsSS11.TCRIT_UPDATED_STATE, criteriaItemEditState };
};
//
export const setCriteriaDeletionState = (criteriaItemDeletionState: any) => {
  return { type: actionsSS11.TCRIT_DELETION_STATE, criteriaItemDeletionState };
};

export const setCriteriaRegistrationState = (criteriaItemRegistrationState: any) => {
  return { type: actionsSS11.TCRIT_REGISTRATION_STATE, criteriaItemRegistrationState };
};


export const criteriaListSelectedUpdated = (selectedCriteriaItemList: any) => {
  return { type: actionsSS11.TCRIT_LIST_SELECTED_UPDATED, selectedCriteriaItemList };
};

export const criteriaItemListExportToExcelInit = () => { return { type: actionsSS11.TCRIT_LIST_EXPORT_TO_EXCELL_INIT }; };
export const criteriaItemListExportToExcel = () => { return { type: actionsSS11.TCRIT_LIST_EXPORT_TO_EXCELL }; };
export const criteriaItemListExportToExcelSuccess = () => { return { type: actionsSS11.TCRIT_LIST_EXPORT_TO_EXCELL_SUCCESS }; };
export const criteriaItemListExportToExcelFailed = () => { return { type: actionsSS11.TCRIT_LIST_EXPORT_TO_EXCELL_FAILED }; };

// REGISTRTATION
export const criteriaItemRegistrationStarted = () => { return { type: actionsSS11.TCRIT_ITEM_REGISTRATION_POST_INIT }; };
export const criteriaItemRegistration = (taleCriterianItem: TalenesTale) => { return { type: actionsSS11.TCRIT_ITEM_REGISTRATION_POST, taleCriterianItem }; };
export const criteriaItemRegistrationSuccess = (msg: string) => { return { type: actionsSS11.TCRIT_ITEM_REGISTRATION_POST_SUCCESS, msg: msg }; };
export const criteriaItemRegistrationFailed = (error: any) => { return { type: actionsSS11.TCRIT_ITEM_REGISTRATION_POST_FAILED, error }; };

// UPDATE
export const criteriaItemUpdateStarted = () => { return { type: actionsSS11.TCRIT_ITEM_UPDATED_POST_INIT }; };
export const criteriaItemUpdated = (talenesTale: TalenesTale) => { return { type: actionsSS11.TCRIT_ITEM_UPDATED_POST, talenesTale }; };
export const criteriaItemUpdateSuccess = () => { return { type: actionsSS11.TCRIT_ITEM_UPDATED_POST_SUCCESS }; };
export const criteriaItemUpdateFailed = (error: any) => { return { type: actionsSS11.TCRIT_ITEM_UPDATED_POST_FAILED, error: error }; };

//DELETION
export const criteriaItemListSelectedDeletionStarted = () => { return { type: actionsSS11.TCRIT_LIST_SELECTED_DELETION_POST_INIT }; };
export const criteriaItemListSelectedDeletion = (selectedCriteriaItemList: number[]) => { return { type: actionsSS11.TCRIT_LIST_SELECTED_DELETION_POST, selectedCriteriaItemList: selectedCriteriaItemList }; };
export const criteriaItemListSelectedDeletionSuccess = () => { return { type: actionsSS11.TCRIT_LIST_SELECTED_DELETION_POST_SUCCESS }; };
export const criteriaItemListSelectedDeletionFailed = (error: any) => { return { type: actionsSS11.TCRIT_LIST_SELECTED_DELETION_POST_FAILED, error: error }; };


export const criteriaItemDeletionDialogUpdated = (payload: boolean) => { return { type: actionsSS11.TCRIT_DELETION_DIALOGUE_UPDATED, payload }; };

export const setCriteriaItemSelectedList = (selectedCriteriaItemList: any) => { return { type: actionsSS11.TCRIT_SET_SELETED_LIST, selectedCriteriaItemList }; };


export const setCriteriaItemId = (criteriaItemId: any) => { return { type: actionsSS11.TCRIT_SET_CRITERIA_ITEM_ID, criteriaItemId }; };





export const excelPostInit = () => { return { type: actionsSS10.EXCEL_POST_UPLOAD_DOC_CRITERIA_INIT }; };
export const excelPost = (payload: any) => { return { type: actionsSS10.EXCEL_POST_UPLOAD_DOC_CRITERIA, payload }; };
export const excelPostSuccess = () => { return { type: actionsSS10.EXCEL_POST_UPLOAD_DOC_CRITERIA_SUCCESS }; };
export const excelPostFailed = (error: any) => { return { type: actionsSS10.EXCEL_POST_UPLOAD_DOC_CRITERIA_FAILED, error }; };





export const setExcelImportState = (payload: any) => {
  return { type: actionsSS10.EXCEL_IMPORT_STATE, payload };
};

export const setSelectedSubKunde = (payload: any) => {
  return { type: actionsSS10.SET_SELECTED_SUB_KUNDE, payload };
};
export const setSelectedKunde = (payload: any) => {
  return { type: actionsSS10.SET_SELECTED_KUNDE, payload };
};


export const setFullResetSalesReported = () => {
  return { type: actionsSS10.SET_FULL_RESET_SALESREPORT };
};

export const setResetSalesReport = () => {
  return { type: actionsSS10.SET_RESET_SALESREPORT };
};