
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


import { Theme, Typography } from '@mui/material';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { makeStyles, withStyles } from 'tss-react/mui';
import Utils from '../felles/Utils';
import C from '../felles/UtilsCSS';
import MenuEl from '../models/MenuEl';
import MenuItemTarget from '../models/MenuItemTarget';
import * as actions from '../store/actions/index';
import { AppState } from '../store/rootReducer';

const useStyles: any = makeStyles()((theme: Theme) => ({
  root: {
    width: '100%', color: '#fff', bottom: 0, left: 0,
    '&.Mui-selected': {
      color: '#171b3d',
      borderRadius: 4,
      backgroundColor: '#f6f6f6 !important',
      marginRight: 12,
    },
    '&.Mui-expanded': {
      '.MuiTreeItem-iconContainer': {
        color: '#d30535',
      },
    },
    [C.r(theme, C.XXS, C.XXX)]: { "& .MuiTreeItem-label": { fontSize: '1em', } },
    [C.r(theme, C.XS, C.XSX)]: { "& .MuiTreeItem-label": { fontSize: '1.1em', } },
    [C.r(theme, C.SM, C.SMX)]: { "& .MuiTreeItem-label": { fontSize: '1.2em', } },
    [C.r(theme, C.MD, C.MDX)]: { "& .MuiTreeItem-label": { fontSize: '1.3em', } },
    [C.r(theme, C.LG, C.LGX)]: { "& .MuiTreeItem-label": { fontSize: '1.3em', } },
    [C.r(theme, C.XL, C.XLX)]: { "& .MuiTreeItem-label": { fontSize: '1.4em', } },
    [C.r(theme, C.XXL, C.YLX)]: { "& .MuiTreeItem-label": { fontSize: '1.4em', } },

    [C.IPAD_10]: { "& .MuiTreeItem-label": { fontSize: '1em', paddingTop: 3, } },
    [C.SAMSUNG]: { "& .MuiTreeItem-label": { fontSize: '1em', paddingTop: 3, } },
    //, lineHeight: 20, paddingTop:20 } },

  },
  labelRoot: { display: 'flex', alignItems: 'center', padding: theme.spacing(0.5, 0) },
  labelIcon: { height: 35, marginRight: theme.spacing(1), width: 25 },
  labelText: { height: 35, flexGrow: 1 },
}));
//
const TreItem = withStyles(
  TreeItem,
  (theme/*, props*/): any => ({
    //
    content: { flexDirection: 'row-reverse', height: '40px', },
    //
    label: { paddingLeft: 12, fontSize: '1em' },
    iconContainer: { transform: 'scale(2)', },
    [C.r(theme, C.XXS, C.XXX)]: { content: { height: '30px', } },
    [C.r(theme, C.XS, C.XSX)]: { content: { height: '30px', } },
    [C.r(theme, C.SM, C.SMX)]: { content: { height: '35px', } },
    [C.r(theme, C.MD, C.MDX)]: { content: { height: '40px', } },
    [C.r(theme, C.LG, C.LGX)]: { content: { height: '43px' } },
    [C.r(theme, C.XL, C.XLX)]: { content: { height: '50px' } },
    [C.r(theme, C.XXL, C.YLX)]: { content: { height: '55px' } },

    [C.IPAD_10]: { content: { height: '27px', }, },
    [C.SAMSUNG]: { content: { height: '27px', }, },
  })
);

/*
 *
 *
 */
export default function LPNavMenuTree() {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Selectors to get state from the Redux store
  const menuItems = useSelector((state: AppState) => state.menuItem.menuItems);
  const expanded = useSelector((state: AppState) => state.menuItem.expanded);
  const selected = useSelector((state: AppState) => state.menuItem.selected);
  const JSXItem = useSelector((state: AppState) => state.menuItem.JSXItemElemments);

  // Fetch menu items on mount if not already fetched
  useEffect(() => {
    if (menuItems.length === 0) {
      dispatch(actions.menuItemGet());
    }
  }, [dispatch, menuItems]);

  // Set JSX items if not already set
  useEffect(() => {
    if (JSXItem.length === 0 && menuItems.length > 0) {
      dispatch(actions.menuItemSetJSXItemElements(renderRootMenuItems()));
    }
  }, [dispatch, JSXItem, menuItems]);


  // Render menu items subnodes
  const renderMenuItems = (item: MenuEl) => {
    return item.nodes
      .filter(subItem => subItem.heading !== '')
      .map(subItem => (
        <StyledTreeItem
          key={`mIt${subItem.name}`}
          nodeId={`${subItem.name}${subItem.rootName}`}
          onClick={(event) => hChange(event, subItem)}
          label={subItem.name}
        />
      ));
  };

  // Render menu items parent nodes
  const renderRootMenuItems = () => {
    return menuItems.map((item, i) => (
      <StyledTreeItem
        key={`root${i}`}
        nodeId={item.rootName}
        onClick={(event) => hRootClicked(event, item)}
        label={item.name}
      >
        {item.heading !== '' ? renderMenuItems(item) : null}
      </StyledTreeItem>
    ));
  };

  // 
  function StyledTreeItem(props) {
    const { labelText } = props;
    //
    return (
      <TreItem
        label={
          <div className={classes.labelRoot}>
            <div className={classes.labelIcon} />
            <Typography variant="h1" className={classes.labelText}>
              {labelText}
            </Typography>
          </div>
        }
        classes={{ content: classes.root, }}
        {...props}
      />
    );
  }

  /*
   *
   */
  // Handle root menu item click
  const hRootClicked = (event, item: MenuEl) => {
    event.stopPropagation();
    event.preventDefault();
    const isExpanded = expanded.includes(item.rootName);
    dispatch(actions.menuItemSetExpanded(isExpanded ? [] : [item.rootName]));

    const target = item.hasChildren && item.nodes[0]
      ? new MenuItemTarget(item.rootName, item.nodes[0].name, '', '', item.nodes[0].tilpasset, item.nodes[0].tilpassetBeta, item.nodes[0].menuSynlig, item.nodes[0].kodeSynlig, item.hasChildren, item.description)
      : new MenuItemTarget(item.rootName, item.rootName, '', '', item.tilpasset, item.tilpassetBeta, item.kodeSynlig, item.labelSynlig, item.hasChildren, item.description);

    dispatch(actions.menuItemSetTarget(target));
    dispatch(actions.menuItemSetSelected([item.rootName, item.nodes[0]?.name + item.rootName || item.rootName]));
    // Reset predefined redux transaction store
    dispatch(actions.resetPredefinedReduxTransactionStore());
    navigate(`${Utils.HOMEPAGE_CONTEXT}/menu`);
  };

  // Handle sub menu item click
  const hChange = (event, subItem: MenuEl) => {
    event.stopPropagation();
    event.preventDefault();
    const target = new MenuItemTarget(subItem.rootName, subItem.name, '', '', subItem.tilpasset, subItem.tilpassetBeta, subItem.labelSynlig, subItem.kodeSynlig, subItem.hasChildren, subItem.description);
    dispatch(actions.menuItemSetTarget(target));
    dispatch(actions.menuItemSetSelected([subItem.rootName, subItem.name + subItem.rootName]));
    // Reset predefined redux transaction store
    dispatch(actions.resetPredefinedReduxTransactionStore());
    navigate(`${Utils.HOMEPAGE_CONTEXT}/menu`);
  };
  /*
   *
   */
  return (
    <>
      <TreeView
        expanded={expanded}
        selected={selected}
        key="thTr"
        className={classes.root}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
      >
        {JSXItem && JSXItem.length > 0 ? JSXItem : <div>Loading menu ...</div>}
      </TreeView>
    </>

  );
}
