import { NV03Firma, NV03Headers } from "../../models/NV03Vare";
import pageImpl from "../../models/PageImpl";
import * as actionTypesNV03 from "./actionTypesNV03";

//
export const NV03VALFListeGetStart = () => {
  return { type: actionTypesNV03.NV03_VALF_LIST_GET_INIT };
};

//
export const NV03VALFListeGet = (formData: FormData) => {
  return { type: actionTypesNV03.NV03_VALF_LIST_GET, formData };
};

//
export const NV03VALFListeGetSuccess = (payload: pageImpl<any>) => {
  return { type: actionTypesNV03.NV03_VALF_LIST_GET_SUCCESS, payload };
};

//
export const NV03VALFListeGetFailed = (error: any) => {
  return { type: actionTypesNV03.NV03_VALF_LIST_GET_FAILED, error };
};

//
export const NV03VALFLabelGetSuccess = (payload: NV03Firma) => {
  return { type: actionTypesNV03.NV03_VALF_LABEL_GET_SUCCESS, payload };
};

//
export const NV03VALFLAbelGetFailed = (error: any) => {
  return { type: actionTypesNV03.NV03_VALF_LABEL_GET_FAILED, error };
};

export const NV03ResetForm = (payload: any) => {
  return { type: actionTypesNV03.NV03_RESET_FORM, payload };
};

//  Set Reset state enabled
export const NV03SetResetFormEnabled = (payload: any) => {
  return { type: actionTypesNV03.NV03_SET_RESET_FORM_ENABLED, payload };
};

//  Reset form
export const NV03ClearComponentStore = () => {
  return { type: actionTypesNV03.NV03_RESET_COMPONENT_STORE };
};

//  Update Column Header List
export const NV03UpdateColumnHeaderList = (list: NV03Headers[]) => {
  return { type: actionTypesNV03.NV03_SET_COLUMN_HEADER_LIST, list };
};

//  Set Column Header List
export const setIsVeilPrisCheckboxState = (payload: any) => {
  return { type: actionTypesNV03.NV03_SET_VEILPRIS_CHECKBOX_STATE, payload };
};

//  Set Column Header List
export const setIsLocationCheckboxState = (payload: any) => {
  return { type: actionTypesNV03.NV03_SET_LOCATION_CHECKBOX_STATE, payload };
};

//  Set Column Header List
export const setIsVisAlleLagerCheckboxState = (payload: any) => {
  return { type: actionTypesNV03.NV03_SET_VISALLELAGER_CHECKBOX_STATE, payload };
};

//  Set Column Header List
export const setIsBeholdningCheckboxState = (payload: any) => {
  return { type: actionTypesNV03.NV03_SET_BEHOLDNING_CHECKBOX_STATE, payload };
};

// 
export const setKeyValuePairs4NV03 = (payload: any) => {
  return { type: actionTypesNV03.SET_KEY_VALUE_PAIRS_4_NV03, payload };
};


export const setCurrentRowFocused = (payload: any) => {
  return { type: actionTypesNV03.SET_CURRENT_ROW_FOCUSED , payload };
};

export const setScrollTopPosition = (payload: any) => {
  return { type: actionTypesNV03.SET_SCROLL_TOP_POSITION , payload };
}

