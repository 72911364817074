import { Checkbox, TableCell, TableHead, TableRow, TableSortLabel, Tooltip } from "@mui/material";
import { Component } from "react";
import { connect } from 'react-redux';
import { withStyles } from "tss-react/mui";
import { AppState } from "../../../store/rootReducer";

const rows = [
  { id: "id", numeric: false, disablePadding: true, label: "Id." },
  { id: "created", numeric: true, disablePadding: false, label: "Created" },
  { id: "job", numeric: true, disablePadding: false, label: "Job" },
  { id: "userid", numeric: true, disablePadding: false, label: "User Id." },
  { id: "returncode", numeric: true, disablePadding: false, label: "returncode" },
  { id: "customer", numeric: true, disablePadding: false, label: "Customer" },
  { id: "cost", numeric: true, disablePadding: false, label: "Cost" },
  { id: "reference", numeric: true, disablePadding: false, label: "reference" },
];

class ACC1FormHeader extends Component<Props> {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };
  //
  render() {
    const { classes } = this.props;
    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox" className={classes.tableCell}>
            <Checkbox sx={{ color: 'white', '&.Mui-checked': { color: "red" } }}
              indeterminate={this.props.selectedJobList && this.props.selectedJobList.length > 0 && this.props.selectedJobList.length < this.props.rowCount}
              checked={this.props.selectedJobList?.length === this.props.rowCount}
              onChange={this.props.onSelectAllClick}
            />
          </TableCell>
          {rows.map(
            row => (
              <TableCell className={classes.tableCell}
                key={row.id}
                align={row.numeric ? "center" : "center"}
                padding={row.disablePadding ? "normal" : "normal"}
                sortDirection={(this.props.orderBy === row.id ? (this.props.order === "asc" ? "asc" : "desc") : undefined)/**/}
              >
                <Tooltip
                  title="Sort"
                  placement={row.numeric ? "bottom-end" : "bottom-start"}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={this.props.orderBy === row.id}
                    direction={(this.props.order === "asc" ? "asc" : "desc")}
                    onClick={this.createSortHandler(row.id)}
                  >{row.label}</TableSortLabel>
                </Tooltip>
              </TableCell>
            ),
            this
          )}
        </TableRow>
      </TableHead>
    );
  }
}

const ACC1FormHeaderStyled = withStyles(
  ACC1FormHeader,
  (/*theme, props*/) => ({
    tableCell: {  /*border:'4px solid red',*/ marginLeft: -5, padding: 3, textAlign: 'left',
      backgroundColor: '#4b4948', color: 'white', border: 'none',
      // '&:hover': { color: 'white', cursor: 'pointer', backgroundColor: '#f6f6f6' },
      // '&:active': { color: 'white', cursor: 'pointer', backgroundColor: alpha('#d30535', 0.2), },
      '& .MuiTableSortLabel-root': {
        color: 'white',
        '& .MuiSvgIcon-fontSizeMedium ': { color: 'white', },
      },
    },
  }));

interface PassedPropsFromParent {
  numSelected: number;
  order?: string;
  orderBy?: string;
  onSelectAllClick: any;
  rowCount: number;

}

interface StateProps {
  selectedJobList?: number[];
  classes?: any;

}

interface DispatchProps {
  onRequestSort: (event: any, property: any) => void;
}

export type Props = PassedPropsFromParent & StateProps & DispatchProps;

const mapStateToProps = (state: AppState): StateProps | PassedPropsFromParent => {
  return {
    selectedJobList: state.accounting.selectedJobList,
  }
}

export default connect<StateProps | PassedPropsFromParent, DispatchProps, Props, AppState>(mapStateToProps/*, mapDispatchToProps*/)(ACC1FormHeaderStyled);