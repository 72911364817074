import { Box, InputAdornment, TableCell, TableHead, TableRow, TableSortLabel, TextField, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import SVGCloseSquare from '../../../components/SVGCloseSquare';
import NV03Vare, { NV03Firma, NV03Headers } from '../../../models/NV03Vare';
import * as actionsNV03 from '../../../store/actions/indexNV03';
import { AppState } from '../../../store/rootReducer';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

// Styles
const useStyles = makeStyles()(() => ({
  tableCell: {
    width: 'auto',
    textTransform: 'capitalize',
    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
    marginLeft: 0,
    padding: '6px 20px',
    paddingRight: '10px',
    textAlign: 'left',
    fontSize: 16,
    fontWeight: 500,
    backgroundColor: '#fff',
    color: '#575655',
    borderBottom: '1px solid #c4c4c4',
    // Make sure the icon is visible
    '& .MuiTableSortLabel-icon': {
      opacity: '1 !important',  // Force visibility of the icon
      color: '#c4c4c4',         // Optional: set a color for the icon
    },
    "& .MuiTableSortLabel-root:hover, .MuiTableSortLabel-root:hover .MuiTableSortLabel-icon": {
      color: "#000"
    },
    "& .MuiTableSortLabel-root.Mui-active:hover, .MuiTableSortLabel-root.Mui-active:hover .MuiTableSortLabel-icon": {
      color: "#000"
    },
    "& .MuiTableSortLabel-root.Mui-active, .MuiTableSortLabel-root.Mui-active .MuiTableSortLabel-icon": {
      fontWeight: 600,
      color: "#d30535",
    }
  },
}));

// Initial state for the column headers
// headerListInitialState
export const hInit: NV03Headers[] = [
  { foc: 0, isSort: 0, isActive: 0, isFilter: 1, filter: '', vis: 1, width: '1.5%', sortDirection: 'asc', id: "alfa", lb: "Alfa" },
  { foc: 0, isSort: 0, isActive: 0, isFilter: 1, filter: '', vis: 1, width: '4%', sortDirection: 'desc', id: "artikkelNummer", lb: "Artikkel" },
  { foc: 0, isSort: 0, isActive: 0, isFilter: 1, filter: '', vis: 1, width: '10%', sortDirection: 'asc', id: "vareSpes", lb: "Varebetegnelse" },
  { foc: 0, isSort: 0, isActive: 0, isFilter: 1, filter: '', vis: 1, width: '5%', sortDirection: 'asc', id: "vareGruppe", lb: "Varegruppe" },
  { foc: 0, isSort: 0, isActive: 0, isFilter: 1, filter: '', vis: 1, width: '1%', sortDirection: 'asc', id: "utSalgPris", lb: "Pris" },
  { foc: 0, isSort: 0, isActive: 0, isFilter: 1, filter: '', vis: 1, width: '4%', sortDirection: 'asc', id: "veilPris", lb: "Veil Pris" },
  { foc: 0, isSort: 0, isActive: 0, isFilter: 1, filter: '', vis: 1, width: '3.5%', sortDirection: 'asc', id: "lokasjon", lb: "Lokasjon" },
  { foc: 0, isSort: 0, isActive: 0, isFilter: 1, filter: '', vis: 1, width: '4%', sortDirection: 'asc', id: "beholdning", lb: "Beholdning" },
  { foc: 0, isSort: 0, isActive: 0, isFilter: 1, filter: '', vis: 0, width: '3%', sortDirection: 'asc', id: "lk10", lb: 'lk10', },
  { foc: 0, isSort: 1, isActive: 0, isFilter: 1, filter: '', vis: 0, width: '3%', sortDirection: 'asc', id: "lk18", lb: 'lk18', },
  { foc: 0, isSort: 1, isActive: 0, isFilter: 1, filter: '', vis: 0, width: '3%', sortDirection: 'asc', id: "lk13", lb: 'lk13', },
  { foc: 0, isSort: 1, isActive: 0, isFilter: 1, filter: '', vis: 0, width: '3%', sortDirection: 'asc', id: "lk15", lb: 'lk15', },
  { foc: 0, isSort: 1, isActive: 0, isFilter: 1, filter: '', vis: 0, width: '3%', sortDirection: 'asc', id: "lk17", lb: 'lk17', },
  { foc: 0, isSort: 1, isActive: 0, isFilter: 1, filter: '', vis: 0, width: '3%', sortDirection: 'asc', id: "lk92", lb: 'lk92', },
  { foc: 0, isSort: 1, isActive: 0, isFilter: 1, filter: '', vis: 0, width: '3%', sortDirection: 'asc', id: "lk93", lb: 'lk93', },
  { foc: 0, isSort: 0, isActive: 0, isFilter: 1, filter: '', vis: 1, width: '3%', sortDirection: 'asc', id: "auto", lb: "Auto", },
  { foc: 0, isSort: 0, isActive: 0, isFilter: 1, filter: '', vis: 1, width: '3%', sortDirection: 'asc', id: "merknad", lb: "Merknad", },
];

// Props interface
interface NV03TableHeadProps { onRequestSort: (property: NV03Headers[]) => void; }

// Define the form input types
type FormInputs = {
  artikkelNummer: any,// string | null | undefined,
  vareSpes: any,
  vareGruppe: any,
  [key: string]: string // Add a catch-all key of type string
};

// Component
const NV03TableHead: React.FC<NV03TableHeadProps> = ({ onRequestSort }) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const nV03Headers: NV03Headers[] = useSelector((state) => (state as AppState).NV03.nV03Headers);
  const lagerNavnFraAPI: NV03Firma = useSelector((state) => (state as AppState).NV03.nV03Firma);
  const isBeholdningChecked: boolean = useSelector((state) => (state as AppState).NV03.isBeholdningChecked);
  const isVeilPrisChecked: boolean = useSelector((state) => (state as AppState).NV03.isVeilPrisChecked);
  const isVisAlleLagerChecked: boolean = useSelector((state) => (state as AppState).NV03.isVisAlleLagerChecked);
  const isReset: boolean = useSelector((state) => (state as AppState).NV03.isReset);
  const page: NV03Vare[] = useSelector((state) => (state as AppState).NV03.page.content);
  const keys: string[] = ['lk10', 'lk18', 'lk13', 'lk15', 'lk17', 'lk92', 'lk93'];

  // React Hook Form configuration with default values
  const methods = useForm<FormInputs>({
    defaultValues: {
      artikkelNummer: '',
      vareSpes: '',
      vareGruppe: '',
    },
  });
  //
  useEffect(() => {
    // If no Label is available for the lager, or no data in payload then hide the column
    if (lagerNavnFraAPI) {
      hInit.forEach(element => {
        for (const keyL in lagerNavnFraAPI) {
          if (element.id === keyL && lagerNavnFraAPI[element.id]?.length > 0) {
            element.vis = 1;
          }
        }
      });
      // Check if the column is visible or not
      for (const keyL in lagerNavnFraAPI) {
        if (keys.includes(keyL)) {
          let visible = 0;
          page.forEach(row => {
            for (const keyRow in row) {
              if (keys.includes(keyRow) && keyL === keyRow) {
                if (row[keyRow] || row[keyRow] === '0') {
                  visible = 1;
                }
              }
            }
          });
          //
          hInit.forEach(element => {
            if (element.id === keyL) {
              element.vis = visible === 0 ? 0 : 1;
            }
          });
        }
      }
      // Set the filter values if they exist
      nV03Headers.forEach(element => {
        if (element.isFilter && element.filter) {
          methods.setValue(element.id, element.filter);
        }
      });

      // Reset the reset flag
      if (isReset) {
        dispatch(actionsNV03.NV03ResetForm(false));
      }
    }
    //
  }, [dispatch, isBeholdningChecked, isVeilPrisChecked, isVisAlleLagerChecked, lagerNavnFraAPI, isReset]);

  // Handle the click event on the column header
  const handleClick = (property: NV03Headers) => {
    const filteredColumnHeaderList: NV03Headers[] = nV03Headers.map((row: NV03Headers) => {
      if (row.id === property.id) {
        return {
          ...row,
          isActive: 1,
          sortDirection: row.sortDirection === 'asc' ? 'desc' : 'asc',
        };
      }
      return {
        ...row,
        isActive: 0,
        sortDirection: 'desc',
      };
    });
    // Update the redux store with the new column header list
    dispatch(actionsNV03.NV03UpdateColumnHeaderList(filteredColumnHeaderList));

    // Call the parent component to sort the data
    if (onRequestSort) {
      onRequestSort(filteredColumnHeaderList);
    }
  };

  // Handle the click event on the column header
  const handleFieldBlur = (e, property: NV03Headers) => {
    const filteredColumnHeaderList: NV03Headers[] = nV03Headers.map((row: NV03Headers) => {
      if (row.id === property.id && e.target.value === '') {
        return {
          ...row,
          foc: 0,
        };
      }
      return row;
    });
    // Update the redux store with the new column header list
    dispatch(actionsNV03.NV03UpdateColumnHeaderList(filteredColumnHeaderList));
  };

  // Handle the click event on the column header
  const handleFieldFocus = (e, property: NV03Headers) => {
    const filteredColumnHeaderList: NV03Headers[] = nV03Headers.map((row: NV03Headers) => {
      if (row.id === property.id) {
        return {
          ...row,
          foc: 1,
        };
      }
      return row;
    });
    // Set the focus on the input field
    const inputField: HTMLInputElement | null = document.querySelector('input[name="' + property.id + '"]');
    if (inputField) {
      inputField.focus();
    }
    // Update the redux store with the new column header list
    dispatch(actionsNV03.NV03UpdateColumnHeaderList(filteredColumnHeaderList));
  };

  // Handle the click event on the column header
  const handleChange = (e, property: NV03Headers) => {
    const { key } = e; // Get the key pressed
    const actionSource = key || e.target.value; // Get the key pressed or the value entered in the input field
    methods.setValue(property.id as string, e.target.value);
    e.stopPropagation(); // Prevent the event from bubbling up
    e.preventDefault(); // Prevent the default action of the event from happening
    //
    const filteredColumnHeaderList: NV03Headers[] = nV03Headers.map((row: NV03Headers) => {
      if (row.id === property.id) {
        return {
          ...row,
          filter: e.target.value,
        };
      }
      return row;
    });
    dispatch(actionsNV03.NV03UpdateColumnHeaderList(filteredColumnHeaderList));
    // Update the redux store with the new column header list and submit the request to sort the data
    if (onRequestSort && actionSource === 'Enter') {
      onRequestSort(filteredColumnHeaderList);
    }
  };

  // Handle the click event on the column header
  const handleLabelClicked = (e, property: NV03Headers) => {
    methods.setValue(property.id as string, '');
    // Update the label state to remove the [X] prefix if it exists
    const updatedHeaders: NV03Headers[] = nV03Headers.map((row: NV03Headers) => {
      if (row.id === property.id) {
        return {
          ...row,
          filter: '',
          foc: 0,
        };
      }
      return row;
    });
    // Dispatch the updated headers to the redux store
    dispatch(actionsNV03.NV03UpdateColumnHeaderList(updatedHeaders));
    onRequestSort(updatedHeaders);
  };

  // Handle the click event on the column header
  const handleLabelFilterClicked = (e, property: NV03Headers) => {
    //
    const isFocused = property.foc === 1;

    //
    const updatedHeaders: NV03Headers[] = nV03Headers.map((row: NV03Headers) => {
      if (row.id === property.id) {
        return {
          ...row,
          foc: isFocused ? 0 : 1,
          filter: isFocused ? '' : row.filter, // Ensure the `filter` property matches the expected type
        };
      }
      return row;
    });

    if (!isFocused) {
      // Set focus on the input field
      const inputField = document.querySelector<HTMLInputElement>(`input[name="${property.id}"]`);
      inputField?.focus();
    } else {
      methods.setValue(property.id as string, '');
    }

    // Dispatch the updated headers to the redux store
    dispatch(actionsNV03.NV03UpdateColumnHeaderList(updatedHeaders));

    // Call the parent component to sort the data
    if (isFocused) {
      onRequestSort(updatedHeaders);
    }
  };

  /*
  * Build the menu items parent nodes
  */
  const renderColumnHeader = () => {
    const JSXColumnHeader: JSX.Element[] = [];
    nV03Headers.forEach((row: NV03Headers) => {
      let label = row.lb;
      switch (true) {
        case row.id == 'lk10':
          label = lagerNavnFraAPI.lk10?.trim().toLowerCase();
          break;
        case row.id == 'lk18':
          label = lagerNavnFraAPI.lk18?.trim().toLowerCase();
          break;
        case row.id == 'lk13':
          label = lagerNavnFraAPI.lk13?.trim().toLowerCase();
          break;
        case row.id == 'lk15':
          label = lagerNavnFraAPI.lk15?.trim().toLowerCase();
          break;
        case row.id == 'lk17':
          label = lagerNavnFraAPI.lk17?.trim().toLowerCase();
          break;
        case row.id == 'lk92':
          label = lagerNavnFraAPI.lk92?.trim().toLowerCase();
          break;
        case row.id == 'lk93':
          label = lagerNavnFraAPI.lk93?.trim().toLowerCase();
          break;
        default:
          break;
      }
      switch (true) {
        // case !isBeholdningChecked && row.id === 'beholdning':
        // break;
        case !isVeilPrisChecked && row.id === 'veilPris':
          break;
        case !isVisAlleLagerChecked
          && (row.id === 'lk10'
            || row.id === 'lk18'
            || row.id === 'lk13'
            || row.id === 'lk15'
            || row.id === 'lk17'
            || row.id === 'lk92'
            || row.id === 'lk93'):
          break
        default: {
          row.vis === 1 ? JSXColumnHeader.push(
            // Your TableCell component
            <TableCell
              className={classes.tableCell}
              key={row.id}
              align="left"
              padding="none"
            >
              <Tooltip
                title={'Skriv inn og trykk ENTER for å filtrere etter ' + label}
                //placement={row.numeric ? "bottom-end" : "bottom-start"}
                enterDelay={300}
                componentsProps={{
                  tooltip: {
                    sx: {
                      fontSize: 15,
                      fontWeight: 100,
                      color: '#fff', // Text color inside the tooltip
                    },
                  },
                }}
              >
                {row.isSort || row.isFilter ? (
                  <Box display="flex" alignItems="center">
                    <TextField
                      id={row.id}
                      label={label}
                      autoComplete="off"
                      variant="standard"
                      autoSave='off'
                      placeholder={methods.getValues(row.id as string)}
                      {...methods.register(row.id as string, { required: false })}
                      onFocus={(e) => handleFieldFocus(e, row)}
                      onBlur={(e) => handleFieldBlur(e, row)}
                      onKeyUp={(e) => { handleChange(e, row); }}
                      InputProps={{
                        disableUnderline: /*row.foc === 1 ? true : */true,
                        sx: {
                          height: '15px',
                          padding: '0px 0',
                          color: '#000',
                          //color: row.filter !== '' ? '#000' : '#000', // black color for the label when focused
                          '&:before': {
                            borderBottom: '1px solid lightgray', // Soft gray underline when unfocused
                          },
                          '&:after': {
                            borderBottom: '1px solid lightgray', // Soft gray underline when focused
                          },
                          '&:hover:not(.Mui-disabled):before': {
                            borderBottom: '1px solid gray', // Slightly darker gray on hover, if desired
                          },
                        },
                        //startAdornment:
                        //row.filter !== '' ? <InputAdornment position="start">
                        // <Box onClick={(e) => handleLabelClicked(e, row)}><SVGCloseSquare /></Box>
                        //</InputAdornment> : null,
                      }}
                      sx={{
                        width: '95%',
                        '& .MuiInputLabel-root': {
                          top: '-16px',
                          fontWeight: 500,
                        },
                        '& .MuiInputLabel-shrink': {
                          color: '#000',
                          top: '-3px',
                          fontWeight: 400,
                        },
                        '& .MuiInputBase-root': {
                          marginBottom: '0px',
                        },
                      }}
                      InputLabelProps={{
                        shrink: row.foc === 1 ? true : false,
                        onClick: (e) => {
                          handleLabelClicked(e, row);
                        },
                        sx: {
                          '&.Mui-focused': {
                            cursor: 'pointer',
                            color: '#000',
                            // color: row.filter !== '' ? '#000' : '#000', // Pink color for the label when focused
                            fontSize: 17,
                          },
                        },
                      }}
                    />
                    <TableSortLabel
                      active={row.isActive === 1 ? true : false}
                      direction={row.sortDirection}
                      onClick={() => handleClick(row)}
                      hideSortIcon={true}

                    /> <FilterAltIcon onClick={(e) => handleLabelFilterClicked(e, row)}
                      sx={{
                        cursor: 'pointer',
                        color: '#darkgray',
                        //color: row.filter !== '' ? '#000' : '#darkgray',
                        transform: row.foc === 1 ? 'rotate(180deg)' : 'rotate(0deg)', // Rotate icon when active
                        transition: 'transform 0.3s ease', // Smooth transition for rotation
                        '&:before': {
                          borderBottom: '1px solid lightgray',
                        },
                        '&:after': {
                          borderBottom: '1px solid lightgray',
                        },
                        '&:hover:not(.Mui-disabled):before': {
                          borderBottom: '1px solid gray',
                        },
                      }}
                    />
                  </Box>
                ) : (
                  row.isSort && !row.isFilter ? <TableSortLabel
                    active={row.isActive === 1 ? true : false}
                    direction={row.sortDirection}
                    onClick={() => handleClick(row)}
                    hideSortIcon={false}
                  >{label}</TableSortLabel> : <>{label}</>
                )}
              </Tooltip>
            </TableCell>
          ) : null
        }
          break;
      }
    });
    //
    return JSXColumnHeader;
  };
  //
  return (
    <TableHead><TableRow>{renderColumnHeader()}</TableRow></TableHead>
  );
};

export default NV03TableHead;