import axios, { AxiosRequestConfig } from 'axios';
import HjelpFormData from '../models/HjelpFormData';
import HjelpPostFormData from '../models/HjelpPostFormData';
import MenuItemTarget from '../models/MenuItemTarget';
import HeirloomFormData from '../models/payload/HeirloomFormData';
import UserCred from '../models/UserCred';
import U from './Utils';

const _timeOut = 300000;

//        _  _ ___ ___ ___ _    ___   ___  __  __   _           _   ___ ___
//       | || | __|_ _| _ \ |  / _ \ / _ \|  \/  | ( )  ___    /_\ | _ \_ _|
//       | __ | _| | ||   / |_| (_) | (_) | |\/| | |/  (_-<   / _ \|  _/| |
//       |_||_|___|___|_|_\____\___/ \___/|_|  |_|     /__/  /_/ \_\_| |___|

export const loginToHeirloomAPI = (userCred: UserCred, tabId: string) => {
  const config = {
    method: 'get',
    baseURL: `${`${U.URL_ADDRESS}${U.HEIRLOOM_SERVICE}`}/menu.html`,
    maxContentLength: 1000000,
    timeout: 20000,
    auth: {
      username: userCred.username,
      password: userCred.password,
    },
    headers: {
      'content-type': 'application/json',
    },
    params: { user: userCred.username, password: userCred.password, btsg: tabId },
    withCredentials: false,
    secure: false,
    changeOrigin: true,
    mode: 'no-cors',
  } as AxiosRequestConfig;
  return axios.request(config);
};



export const fetchTransById = (menuItemTarget: MenuItemTarget, tabId: string) => {
  const _baseUrl = `${`${U.URL_ADDRESS}${U.HEIRLOOM_SERVICE}`}/servlet`;
  // U.log('fetchTransById aaa: ' + _baseUrl + '?transid=' + menuItemTarget.code.trim() + '&btsg=' + tabId + '   ');
  const config = {
    method: 'get', //GET
    baseURL: _baseUrl,
    maxContentLength: 1000000,
    timeout: _timeOut,
    headers: {
      'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
      Authorization: 'Basic ' + U.T_hauth,
    },
    params: { transid: menuItemTarget.code.trim(), btsg: tabId },
    responseType: 'document',
    responseEncoding: 'utf8',
    withCredentials: true,
    secure: false,
    changeOrigin: true,
    mode: 'no-cors',
  } as AxiosRequestConfig;
  return axios.request(config);
};

export const gettHelpTrans = () => {
  const _baseUrl = `${`${U.URL_ADDRESS}${U.HEIRLOOM_SERVICE}`}/servlet`;
  U.log('fetchTransById: ' + _baseUrl + '?transid=HJEP&btsg=' + U.T_tabId);
  const config = {
    method: 'get', //GET
    baseURL: _baseUrl,
    maxContentLength: 1000000,
    timeout: _timeOut,
    headers: {
      'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
      Authorization: 'Basic ' + U.T_hauth,
    },
    params: { transid: 'HJEP', btsg: U.T_tabId },
    responseType: 'document',
    responseEncoding: 'utf8',
    withCredentials: true,
    secure: false,
    changeOrigin: true,
    mode: 'no-cors',
  } as AxiosRequestConfig;
  return axios.request(config);
};

export const postHelpTrans = (hD: HjelpFormData) => {
  const _baseUrl = `${`${U.URL_ADDRESS}${U.HEIRLOOM_SERVICE}`}/servlet`;
  const config = {
    method: 'post',
    baseURL: _baseUrl,
    maxContentLength: 1000000,
    timeout: _timeOut,
    data:
      'DFH_CURSOR=' +
      hD.DFH_CURSOR +
      '&DFH0003=' +
      'ORRE' +
      '&DFH0004=' +
      hD.DFH0004 +
      '&DFH0005=' +
      hD.DFH0005 +
      '&DFH0007=' +
      hD.DFH0007 +
      '&DFH_ENTER=' +
      hD.DFH_ENTER,
    headers: {
      'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
      Authorization: 'Basic ' + U.T_hauth,
    },
    params: { transid: 'HJEP', btsg: U.T_tabId },
    responseType: 'document',
    responseEncoding: 'utf8',
    withCredentials: true,
    secure: false,
    changeOrigin: true,
    mode: 'no-cors',
  } as AxiosRequestConfig;
  return axios.request(config);
};

// Gets Help payload from AutoData API
export const getHelpTransVitec = (hD: HjelpFormData) => {
  const _baseUrl = `${`${U.URL_ADDRESS}${U.HEIRLOOM_SERVICE}`}/servlet`;
  const _date = new Date().getTime().toString();

  const config = {
    method: 'post',
    baseURL: _baseUrl,
    maxContentLength: 1000000,
    timeout: _timeOut,
    data: {
      exci: 'yes',
      progId: 'WWWHELP',
      btsg: _date,
      TRANS: hD.DFH0003,
      FELTNAVN: hD.DFH0004,
      INNHOLD: hD.DFH0007,
      TERMID: hD.termid,
      P_BTSG: hD.btsg,
      keepsession: true,
    },
    headers: {
      'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
      Authorization: 'Basic ' + U.T_hauth,
    },
    params: {
      exci: 'yes',
      progId: 'WWWHELP',
      btsg: _date,
      TRANS: hD.DFH0003,
      FELTNAVN: hD.DFH0004,
      INNHOLD: hD.DFH0007,
      TERMID: hD.termid,
      P_BTSG: hD.btsg,
      keepsession: true,
    },
    // options are: 'arraybuffer', 'document', 'json', 'text', 'stream'
    // browser only: 'blob'
    responseType: 'arraybuffer',
    responseEncoding: 'binary',
    withCredentials: true,
    secure: false,
    changeOrigin: true,
    mode: 'no-cors',
  } as AxiosRequestConfig;
  return axios.request(config);
};

/****************************************************** */
/****************************************************** */
/****************************************************** */
/****************************************************** */
/****************************************************** */
/****************************************************** */
/****************************************************** */

// SAVE Help payload to AutoData API
export const saveHelpTrans = (hD: HjelpPostFormData) => {
  const config = {
    method: 'post',
    baseURL: `${`${U.URL_ADDRESS}${U.VITEC_SERVICE}`}/help/updateHelp`,
    maxContentLength: 1000000,
    timeout: 20000,
    headers: {
      'content-type': 'application/json',
      Authorization: 'Bearer ' + sessionStorage.getItem('token'),
      Accept: 'application/json;charset=utf-8',
    },
    data: hD,
    withCredentials: false,
    secure: false,
    changeOrigin: true,
    mode: 'no-cors',
  } as AxiosRequestConfig;
  return axios.request(config);
};

/****************************************************** */
/****************************************************** */
/****************************************************** */
/****************************************************** */
/****************************************************** */
/****************************************************** */
/****************************************************** */
/****************************************************** */
/****************************************************** */
/****************************************************** */
/****************************************************** */
/****************************************************** */
/****************************************************** */
/****************************************************** */
// timeout: 240000 previous

export const postTransFormDataAxios = (navFormData: HeirloomFormData, tabId: string) => {
  const config = {
    method: 'post',
    baseURL: `${`${U.URL_ADDRESS}${U.HEIRLOOM_SERVICE}`}/servlet`,
    maxContentLength: 1000000,
    timeout: 720000,
    data: navFormData.formData.replaceAll('%20', '+'),
    headers: {
      'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
      Authorization: 'Basic ' + U.T_hauth,
    },
    params: { transid: navFormData.transId.trim(), btsg: tabId },
    responseType: 'document',
    responseEncoding: 'utf8',
    withCredentials: true,
    secure: false,
    changeOrigin: true,
    mode: 'no-cors',
  } as AxiosRequestConfig;
  return axios.request(config);
};

export const getWSDataFromAxios = (navFormData: HeirloomFormData, tabId: string) => {
  const _baseURL = `${`${U.URL_ADDRESS}${U.HEIRLOOM_SERVICE}`}/servlet`;
  U.log('getWSDataFromAxios: ' + _baseURL);
  const config = {
    method: 'get',
    baseURL: _baseURL,
    maxContentLength: 1000000,
    timeout: 20000,
    headers: {
      'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
      Authorization: 'Basic ' + U.T_hauth,
    },
    params: { transid: navFormData.transId.trim(), scode: navFormData.scode.trim(), btsg: tabId },
    responseType: 'document',
    responseEncoding: 'utf8',
    withCredentials: true,
    secure: false,
    changeOrigin: true,
    mode: 'no-cors',
  } as AxiosRequestConfig;
  return axios.request(config);
};


export const postHeirloomsFormDataToJavaAPI = (formData: HeirloomFormData, _progId: string, _productId: number) => {
  const config = {
    method: 'post',
    baseURL: `${`${U.URL_ADDRESS}${U.VITEC_SERVICE}`}/heirlooms/postHeirloomsFormData`,
    maxContentLength: 1000000,
    timeout: _timeOut,
    headers: {
      'content-type': 'application/json',
      Authorization: 'Bearer ' + sessionStorage.getItem('token'),
    },
    params: { _progId, _productId },
    data: formData,
    responseType: 'json',
    responseEncoding: 'utf8',
    withCredentials: false,
    secure: false,
    changeOrigin: true,
    mode: 'no-cors',
  } as AxiosRequestConfig;
  return axios.request(config);
};