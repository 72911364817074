import { LogMsg } from '../../models/payload/LogMsg';
import * as actionTypes from './actionTypes';

export const setIsloading = (payload: any) => {
  return { type: actionTypes.UI_SET_LOADING, payload };
};

export const setSimpledialogOpeningState = (payload: any) => {
  return { type: actionTypes.UI_SET_SIMPLE_DIALOG, payload };
};

export const setMsg = (payload: any) => {
  return { type: actionTypes.UI_SET_MSG, payload };
};

export const setTitle = (payload: any) => {
  return { type: actionTypes.UI_SET_TITLE, payload };
};

export const switchDebugMode = () => {
  return { type: actionTypes.SWITCH_DEBUG_MODE };
};

export const driftMeldingStarted = () => {
  return { type: actionTypes.DRIFT_MELDING_GET_INIT };
};

export const driftMeldingFetchStarted = () => {
  return { type: actionTypes.DRIFT_MELDING_GET };
};

export const driftMeldingFetchSuccess = (payload: any) => {
  return { type: actionTypes.DRIFT_MELDING_GET_SUCCESS, payload };
};

export const driftMeldingFetchFailed = (error: any) => {
  return { type: actionTypes.DRIFT_MELDING_GET_FAILED, error };
};

export const nyheterMeldingStarted = () => {
  return { type: actionTypes.NYHETER_MELDING_GET_INIT };
};

export const nyheterMeldingFetchStarted = () => {
  return { type: actionTypes.NYHETER_MELDING_GET };
};

export const nyheterMeldingFetchSuccess = (payload: any) => {
  return { type: actionTypes.NYHETER_MELDING_GET_SUCCESS, payload };
};

export const nyheterMeldingFetchFailed = (error: any) => {
  return { type: actionTypes.NYHETER_MELDING_GET_FAILED, error };
};

export const setCharEnc = (payload: string) => {
  return { type: actionTypes.SET_CHAR_ENC, payload };
};

export const logToApi = (payload: LogMsg) => {
  return { type: actionTypes.LOG_TO_API, payload };
};

export const setRecordingState = (payload: number) => {
  return { type: actionTypes.SET_RECORDING_STATE, payload };
};

export const updateRecordingLog = (payload:any) => {
  return { type: actionTypes.UPDATE_BUG_RECORDING, payload };
};

export const BugReportingPost = (payload:any) => {
  return { type: actionTypes.BUG_RECORDING_POST, payload };
};

export const resetPredefinedReduxTransactionStore = () => {
  return { type: actionTypes.RESET_PREDEFINED_REDUX_TRANSACTION_STORE };
};

export const resetStore = () => {
  return { type: actionTypes.RESET_STORE };
};