import { takeLatest } from 'redux-saga/effects';
import * as actionTypes from '../actions/actionTypes';
import { accountingJobCSVListFetchSaga, accountingJobListFetchSaga, accoutingJobListDeleteSelected, accoutingJobListExportSelected } from './accounting.sagas';
import { autoLoginformLoginSent, formLoginSent, formLogoutSent, formPasswordLinkRecovery, formPasswordReset } from './auth.sagas';
import { getMenuItemSaga } from './menuItem.sagas';
import { fecthPDFDocument } from './pdf.saga';
import { userProfileDataFetchSaga, userProfileDataUpdate } from './profile.sagas';
import { sagaSendFeedbackToSupport } from './support.saga';
import { criteriaItemListGetSaga, criteriaItemRegistrationSaga, criteriaItemUpdateSaga, deleteCriteriaFromListSaga, deleteFavorittListeSaga, favorittListeListGetSaga, favorittListeSaveSaga, getCriterialListToExcelDocumentSaga, postUploadExcelDocumentCriteria, tallenesGetSalgsstatistikkSaga } from './tallenesTale.saga';
import { fecthHelpTransaction, fecthTransactionById, postToWebSocket, postTransFormdata, userLogoutFromTerminalSaga } from './trans.sagas';
import { adminGetFirmListFromDBSaga, fetchUserLDAPDataSaga /*, userDataFetchSaga*/, userDataUpdateSaga, userGetGroupListSaga, userListDeleteSelectedSaga, userListExportDataToExcelDocument, userListFetchSaga, userRegistrationSaga } from './userAdmin.sagas';
import { bugReportingPostSaga, getDriftPost, getNyheterPost, logToApiSaga, resetPredefinedReduxTransactionStoreSaga } from './utils.sagas';


import * as actionsSS10 from '../actions/actionTypesSS10';
import * as actionsSS11 from '../actions/actionTypesSS11';
import * as actionsVSE from '../actions/actionTypesVSE';


// AUTH & USER

export function* watchAuthLoginFormSubmit() {
  yield takeLatest(actionTypes.USER_LOGIN_TO_AUTODATA_GET_AUTH, formLoginSent);
}

export function* watchAuthAutoLoginSubmit() {
  yield takeLatest(actionTypes.USER_AUTOLOGIN_TO_AUTODATA_AUTH_GET, autoLoginformLoginSent);
}


export function* watchAuthLogoutFormSubmit() {
  yield takeLatest(actionTypes.USER_LOGOUT_POST, formLogoutSent);
}

export function* watchUserLogouFromTerminal() {
  yield takeLatest(actionTypes.USER_LOGOUT_FROM_TERMINAL, userLogoutFromTerminalSaga);
}

export function* watchAuthPassRecoveryFormSubmit() {
  yield takeLatest(actionTypes.USER_PASSWORD_LINK_RECOVERY_POST, formPasswordLinkRecovery);
}

export function* watchAuthPassResetFormSubmit() {
  yield takeLatest(actionTypes.USER_PASSWORD_RESET_POST, formPasswordReset);
}

// ACCOUNTING
export function* watchACC1ListFetch() {
  yield takeLatest(actionTypes.ACCOUNTING_JOB_LIST_GET, accountingJobListFetchSaga);
}

export function* watchAccoutingJobListDeleteSelected() {
  yield takeLatest(actionTypes.ACCOUNTING_JOB_LIST_SELECTED_DELETION_POST, accoutingJobListDeleteSelected);
}

export function* watchAccoutingJobListExportSelected() {
  yield takeLatest(actionTypes.ACCOUNTING_JOB_LIST_SELECTED_EXPORT_GET, accoutingJobListExportSelected);
}

export function* watchACC1CSVListFetch() {
  yield takeLatest(actionTypes.ACCOUNTING_JOB_CSV_LIST_GET, accountingJobCSVListFetchSaga);
}

export function* watchFetchMenuItems() {
  yield takeLatest(actionTypes.MENU_ITEM_GET, getMenuItemSaga);
}

export function* watchGetTransById() {
  yield takeLatest(actionsVSE.TRANS_GET, fecthTransactionById);
}

export function* watchHelpGet() {
  yield takeLatest(actionsVSE.TRANS_HELP_GET, fecthHelpTransaction);
}
export function* watchPostFormData() {
  yield takeLatest(actionsVSE.TRANS_FORMDATA_POST, postTransFormdata);
}

export function* watchPostToWebSocket() {
  yield takeLatest(actionsVSE.TRANS_FORMDATA_TO_WEBSOCKET, postToWebSocket);
}

export function* watchGetPDFdata() {
  yield takeLatest(actionTypes.PDF_GET, fecthPDFDocument);
}

export function* watchUserProfileDataGet() {
  yield takeLatest(actionTypes.USER_PROFILE_DATA_GET, userProfileDataFetchSaga);
}

export function* watchUserProfileUpdateData() {
  yield takeLatest(actionTypes.USER_PROFILE_DATA_UPDATE_POST, userProfileDataUpdate);
}

export function* watchPostFeedbackdata() {
  yield takeLatest(actionTypes.SUPPORT_GET, sagaSendFeedbackToSupport);
}

export function* watchGetNyheterPost() {
  yield takeLatest(actionTypes.NYHETER_MELDING_GET, getNyheterPost);
}

export function* watchGetDriftPost() {
  yield takeLatest(actionTypes.DRIFT_MELDING_GET, getDriftPost);
}

export function* watchLogToApiSaga() {
  yield takeLatest(actionTypes.LOG_TO_API, logToApiSaga);
}

export function* watchGetUserList() {
  yield takeLatest(actionTypes.USER_LIST_GET, userListFetchSaga);
}

export function* watchUserDataFetchSaga() {
  yield takeLatest(actionTypes.USER_DATA_GET, fetchUserLDAPDataSaga);
}
//
export function* watchUserDataUpdateSaga() {
  yield takeLatest(actionTypes.USER_DATA_UPDATE_POST, userDataUpdateSaga);
}

export function* watchUserRegistrationSaga() {
  yield takeLatest(actionTypes.USER_REGISTRATION_POST, userRegistrationSaga);
}

export function* watchUserListDeletionSaga() {
  yield takeLatest(actionTypes.USER_LIST_SELECTED_DELETION_POST, userListDeleteSelectedSaga);
}

export function* watchGetGroupListSaga() {
  yield takeLatest(actionTypes.USER_GROUP_LIST_FROM_LDAP_GET, userGetGroupListSaga);
}

export function* watchGetUserListExportToData() {
  yield takeLatest(actionTypes.USER_LIST_EXPORT_TO_CVS, userListExportDataToExcelDocument);
}

export function* watchGetAdminFirmListSaga() {
  yield takeLatest(actionTypes.ADMIN_FIRM_LIST_FROM_DB_GET, adminGetFirmListFromDBSaga);
}

export function* watchcriteriaItemListGetSaga() {
  yield takeLatest(actionsSS11.TCRIT_LIST_GET, criteriaItemListGetSaga);
}

/****************************************************************************************** */
export function* watchCriteriaItemRegistrationSaga() {
  yield takeLatest(actionsSS11.TCRIT_ITEM_REGISTRATION_POST, criteriaItemRegistrationSaga);
}

export function* watchCriteriaItemUpdateSaga() {
  yield takeLatest(actionsSS11.TCRIT_ITEM_UPDATED_POST, criteriaItemUpdateSaga);
}

export function* watchCriteriaItemDeletionSaga() {
  yield takeLatest(actionsSS11.TCRIT_LIST_SELECTED_DELETION_POST, deleteCriteriaFromListSaga);
}

export function* WatchCriterialListToExcelSaga() {
  yield takeLatest(actionsSS11.TCRIT_LIST_EXPORT_TO_EXCELL, getCriterialListToExcelDocumentSaga);
}

export function* watchFavorittListeListGetSaga() {
  yield takeLatest(actionsSS10.TFAVORITTLISTE_LIST_GET, favorittListeListGetSaga);
}


export function* watchFavorittListeUpdateSaga() {
  yield takeLatest(actionsSS10.TFAVORITTLISTE_LIST_SAVE_POST, favorittListeSaveSaga);
}

export function* watchFavorittListeDeletionSaga() {
  yield takeLatest(actionsSS10.TFAVORITTLISTE_LIST_DELETE_POST, deleteFavorittListeSaga);
}

export function* watchFavorittListeGetReportSaga() {
  yield takeLatest(actionsSS10.TFAVORITTLISTE_LIST_GET_REPORT, tallenesGetSalgsstatistikkSaga);
}

export function* watchPostUploadExcelDocumentCriteria() {
  yield takeLatest(actionsSS10.EXCEL_POST_UPLOAD_DOC_CRITERIA, postUploadExcelDocumentCriteria);
}

export function* watchBugReportPostSaga() {
  yield takeLatest(actionTypes.BUG_RECORDING_POST, bugReportingPostSaga);
}

export function* watchResetPredefinedReduxTransactionStoreSaga() {
  yield takeLatest(actionTypes.RESET_PREDEFINED_REDUX_TRANSACTION_STORE, resetPredefinedReduxTransactionStoreSaga);
}
