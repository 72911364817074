import React, { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import getRoutes from './felles/______________getRoutes';
import { history } from './store/history';
import { ReduxRouter } from '@lagunovsky/redux-react-router';
import { useDispatch, useSelector } from 'react-redux';
import Layout from './components/Layout';
import ErrorHandler from './app/error/ErrorHandler';
import U from './felles/Utils';
import * as actions from './store/actions/index';
import qs from 'qs';
import { AppState } from './store/rootReducer';

const ErrorFallback = ({ error, resetErrorBoundary }) => <ErrorHandler error={error} resetErrorBoundary={resetErrorBoundary} />;

/*
 *******************************************************************************************************************************
 */
const App: React.FC = (/*{ size: { width, height } }*/) => {
  //
  const dispatch = useDispatch();
  const transId: string = qs.parse(history.location.search, { ignoreQueryPrefix: true }).transid as string;
  const _isLogged: number = useSelector((state) => (state as AppState).auth.isLogged);
  const isAutodataLoginInProgress: boolean = useSelector((state) => (state as AppState).auth.isAutodataLoginInProgress);
  const showHelpContent: boolean = useSelector((state) => (state as AppState).trans.showHelpContent);
  const tabIdList: Map<string, number> = new Map();
  let activeTab = '';
  const tabId: string = useSelector((state) => (state as AppState).auth.timeStamp);
  const isDebugEnabled: boolean = useSelector((state) => (state as AppState).utils.isDebugEnabled);


  useEffect(() => {
    dispatch(actions.setTabId(tabId));
    window.addEventListener('keydown', handlePressedKey);
    return () => {
      window.removeEventListener('keydown', handlePressedKey);
    };
  }, [dispatch, tabId]);

  //
  function handlePressedKey(event): void {
    const { key } = event;
    if (key === 's' || key === 'd' || key === 'Home' || key === 'F7' || key === 'F1') {
      switch (true) {
        case key === 'F7' || key === 'F1':
          break;
        case event.ctrlKey && !event.shiftKey && key === 's' && !showHelpContent:
          event.preventDefault();
          if (document.getElementById('code-select') !== document.activeElement)
            document.getElementById('code-select')?.focus();
          break;
        case event.ctrlKey && !event.shiftKey && (key === 'd' || key === 'D'):
          event.preventDefault();
          dispatch(actions.switchDebugMode());
          dispatch(actions.setQTRState(1));
          if (isDebugEnabled) {
            dispatch(actions.setRecordingState(0));
            dispatch(actions.updateRecordingLog([]));
          }
          break;
        case key === 'Home':
          event.preventDefault();
          dispatch(actions.setSearchInputFocused(true));
          break;
        default:
          break;
      }
    }
  }

  //
  function errorHandler(error: Error, info: { componentStack: string }) {
    if (error && error.name) {
      dispatch(actions.setError({ name: error.name, message: error.message }));
    }
    if (info && info.componentStack) {
      dispatch(actions.setErrorInfo(info.componentStack.toString()));
    }
  }

  //
  function sessionStorage_transfer(event, isLogged) {
    const token = sessionStorage.getItem('token') || '';

    if (!event) {
      event = window.event;
    } // ie suq
    if (!event.newValue) return; // do nothing if no value to work with
    //
    if (event?.key === 'message') {
      const message = JSON.parse(event.newValue);
      if (!message) return;
      if (message.command === 'deactivate_all_tabs') {
        activeTab = '';
        dispatch(actions.setTabAsActive(false));
      }
      // Here we act on messages received.
      if (message.command /* RECEIVED */ === 'SEND_THIS_STAMP_TO_OTHER_TAB') {
        tabIdList.set(message.tabId, message.isLogged);
      }
      if (message.command /* RECEIVED */ === 'ASK_OTHER_TAB_FOR_THEIR_TAB_ID') {
        U.message_broadcast({ command /* SENT */: 'SEND_THIS_STAMP_TO_OTHER_TAB', tabId, isLogged, random: new Date().getTime().toString() + Math.random() });
      }
      //
      if (message.command /* RECEIVED */ === 'REMOVE_TAB_FROM_TABLIST') {
        tabIdList.delete(message.tabId);
      }
      //
    } else if (event.key === 'getSessionStorage' && isLogged !== 4) {
      // another tab asked for the sessionStorage -> send it
      localStorage.setItem('sessionStorage', JSON.stringify(sessionStorage));
      // the other tab should now have it, so we're done with it.
      localStorage.removeItem('sessionStorage'); // <- could do short timeout as well.
    } else if (event.key === 'sessionStorage' && !sessionStorage.length && isLogged !== 4) {
      // another tab sent data <- get it
      const data = JSON.parse(event.newValue);
      for (const key in data) {
        sessionStorage.setItem(key, data[key]);
      }
      //
      if (!(data && Object.keys(data).length === 0 && data.constructor === Object)) {
        // 👈 null and undefined check
        dispatch(actions.setIsloading(true));
        dispatch(actions.setSessionUIState(0));
        if (isLogged !== 4 && !isAutodataLoginInProgress) dispatch(actions.userAutologinToAutodataAuthGet(data['token'], transId, false, isLogged));
      } else {
        dispatch(actions.setIsloading(false));
      }
    }
    //  Auto login to autodata
    if (transId && token && isLogged !== 4 && !isAutodataLoginInProgress) {
      dispatch(actions.userAutologinToAutodataAuthGet(token, transId, false, 1));
    }
    // 
    if (!tabIdList.get(tabId)) {
      tabIdList.set(tabId, isLogged);
    }
    //
    let isAlreadyLoggedFromAnotherTab = false;

    // Check if there is another tab with the same tabId and isLogged 1 or 2
    for (const a of tabIdList) {
      if (a[0] !== tabId && (a[1] === 1 || a[1] === 2)) {
        isAlreadyLoggedFromAnotherTab = true;
        break;
      }
    }
    // 
    dispatch(actions.setIsAlreadyLoggedFromAnotherTab(isAlreadyLoggedFromAnotherTab));
    dispatch(actions.setTabListSize(tabIdList.size));
    /**/
    if (activeTab && tabIdList.size > 1 && !isAlreadyLoggedFromAnotherTab && (isLogged === 0 || isLogged === 3)) {
      dispatch(actions.setSessionUIState(-1));
    } else if (!activeTab && tabIdList.size > 1 && !isAlreadyLoggedFromAnotherTab && (isLogged === 0 || isLogged === 3)) {
      dispatch(actions.setSessionUIState(2));
    } else if (isAlreadyLoggedFromAnotherTab && isLogged === 3) {
      dispatch(actions.setSessionUIState(1));
    } else if (isAlreadyLoggedFromAnotherTab && !token && isLogged === 0) {
      dispatch(actions.setSessionUIState(3));
    }
  }

  useEffect(() => {
    // Update local tabIdList logging state
    tabIdList.set(tabId, _isLogged);
    // Updates if they exist other tabs with this tab id logging status
    U.message_broadcast({
      command /* SENT */: 'SEND_THIS_STAMP_TO_OTHER_TAB',
      tabId,
      isLogged: _isLogged,
      random: new Date().getTime().toString() + Math.random(),
    });
    //
    U.message_broadcast({ command: 'deactivate_all_tabs', timeStamp: new Date().getTime().toString() + Math.random() });
    // Attaches focus to this tab
    activeTab = tabId;
    dispatch(actions.setTabAsActive(true));

    //Ask other tabs their id and logging state
    U.message_broadcast({
      command /* SENT */: 'ASK_OTHER_TAB_FOR_THEIR_TAB_ID',
      tabId,
      isLogged: _isLogged,
      random: new Date().getTime().toString() + Math.random(),
    });

    /////////////////////////////////////////////////////////////////////////////////
    // ********************************* STORAGE ************************************
    // We don't trigger a call to other tabs when loggedout manually
    window.addEventListener('storage', (e) => sessionStorage_transfer(e, _isLogged), false);

    // Ask other tabs for session storage (this is ONLY to trigger event)
    if (!sessionStorage.length && _isLogged !== 4) {
      localStorage.setItem('getSessionStorage', 'foobar');
      localStorage.removeItem('getSessionStorage');
    }

    /////////////////////////////////////////////////////////////////////////////////
    // ***************************** BEFOREUNLOAD  **********************************
    window.addEventListener('beforeunload', (event) => {
      event.preventDefault();
      U.message_broadcast({ command /* SEND */: 'REMOVE_TAB_FROM_TABLIST', tabId });
      tabIdList.delete(tabId);
    });

    function handleResize() {
      dispatch(actions.setScreenSize(U.getWindowDimensions()));
      dispatch(actions.setCurrentBreakPoint(U.CURRENT_BREAKPOINT(U.getWindowDimensions().width)));
      // Turning off the drawer
      dispatch(actions.setSandwichButtonStateClicked(false));
      if (U.getWindowDimensions().width > 0 && U.getWindowDimensions().width < 1200) {
        dispatch(actions.setDrawerState(false));
      } else {
        dispatch(actions.setDrawerState(true));
      }
    }



    function handleScroll() {
      dispatch(actions.setScrollTop(document.body.scrollTop));
    }

    // On mount
    handleResize();
    handleScroll();

    /////////////////////////////////////////////////////////////////////////////////
    // ********************************* RESIZE  ************************************
    window.addEventListener(
      'resize',
      U.debounce(() => {
        handleResize();
      })
    );

    document.body.addEventListener(
      'scroll',
      U.debounce(() => {
        handleScroll();
      })
    );



    ////////////////////////////////////////////////////////////////////////////////
    // ********************************** RETURN  **********************************
    return () => {
      window.removeEventListener(
        'resize',
        U.debounce(() => {
          handleResize();
        })
      );
      window.removeEventListener(
        'scroll',
        U.debounce(() => {
          handleScroll();
        })
      );
    };
  }, [dispatch, _isLogged]);

  //
  return (
    <div tabIndex={0} onKeyDown={(e) => handlePressedKey(e)}>
      <ErrorBoundary FallbackComponent={ErrorFallback} onError={errorHandler}>
        <ReduxRouter history={history}>
          <Layout>{getRoutes()}</Layout>
        </ReduxRouter>
      </ErrorBoundary>
    </div>
  );
};

export default App;