import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  Table, 
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { AppState } from '../../store/rootReducer';
import * as actionsNO01 from '../../store/actions/indexNO01'; // Update action imports for NO01
import UISize from '../../models/UISize';
import { TradingPartner } from '../../models/NO01Orku';
import FilterTextField from './FilterTextField';
import ButtonVitec from '../../components/ButtonHighlight';
import CSharpUtils from '../../felles/CSharpUtils';
import { Tooltip, IconButton } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info'; // Or any other icon you want to use


// Define the styles for the component
const useStyles = makeStyles()(() => ({
  root: { margin: 20, fontSize: '1em !important', color: '#fff' },
  container: {
    height: `calc(100vh - 82px)`,
    border: 'none',
  },
}));

const Orku = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();

  // Fetch screen size from Redux
  const screenSize: UISize = useSelector((state: AppState) => state.ui.screenSize);

  // Fetch data from Redux NO01 state
  const orkuData = useSelector((state: AppState) => state.NO01);
  const loading = useSelector((state: AppState) => state.NO01.isFetching);

  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(screenSize.width < 1200 ? 500 : 1000);
  const [filterKundenummer, setFilterKundenummer] = useState<string>('');
  const [filterVAD, setFilterVAD] = useState<string>('');
  const [filterKundenavn, setFilterKundenavn] = useState<string>('');

  const [showFilterKundenummer, setShowFilterKundenummer] = useState<boolean>(false);
  const [showFilterVAD, setShowFilterVAD] = useState<boolean>(false);
  const [showFilterKundenavn, setShowFilterKundenavn] = useState<boolean>(false);

  const tableRef = useRef<HTMLDivElement>(null);

  // Fetch data from Redux on mount
  useEffect(() => {
    dispatch(actionsNO01.fetchTradingPartnersRequest(CSharpUtils.T_companyID));
  }, [dispatch]);

  // Handle outside clicks to close filters
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (tableRef.current && !tableRef.current.contains(event.target as Node)) {
        setShowFilterKundenummer(false);
        setShowFilterVAD(false);
        setShowFilterKundenavn(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  // Handle changes in screen size to adjust rowsPerPage
  useEffect(() => {
    setRowsPerPage(screenSize.width < 1200 ? 500 : 1000);
  }, [screenSize.width]);

  const handleChangePage = (event: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage);
  };

  const formatNumber = (num: number): string => {
    const formattedAmount = Math.floor(num).toLocaleString('no-NO', {});
    return formattedAmount.replace(/\s/g, '.');
  };

  // Filter the data
  const filteredData = (orkuData?.tradingPartners ?? []).filter((row) => {

    const matchKundenummer = row.customerNumber.toString().includes(filterKundenummer);
    const matchVAD = row.companyId.toLowerCase().includes(filterVAD.toLowerCase());
    const matchKundenavn = row.customerName.toLowerCase().includes(filterKundenavn.toLowerCase());

    return matchKundenummer && matchVAD && matchKundenavn;
  });

  // Slice data based on the page and rowsPerPage
  const displayedData = filteredData.slice((page - 1) * rowsPerPage, page * rowsPerPage);

  // Function to export data to CSV
  const exportToCSV = () => {
    if (!filteredData || filteredData.length === 0) {
      alert('No data available to export.');
      return;
    }

    const headers = ['Kundenummer', 'Firma-ID', 'Kundenavn', 'Kjøp hittil i år'];
    const csvContent = [
      headers.join(';'),
      ...filteredData.map((row) => {
        const rowData = [row.customerNumber, row.companyId, row.customerName, formatNumber(row.ytdSales)];
        return rowData.join(';');
      }),
    ].join('\n');

    const fileName = `Kundermeddirektebestilling`;
    const csvWithBOM = `\uFEFF${csvContent}`;

    const element = document.createElement('a');
    element.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(csvWithBOM)}`);
    element.setAttribute('download', `${fileName}.csv`);

    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  return (
    <div className={classes.root} >
      <Paper elevation={0} sx={{ padding:0,maxWidth: '1300px',backgroundColor: 'rgba(255, 255, 255, 0.7)'  }}>
        <TableContainer
          className={classes.container}
          ref={tableRef}
            >
          <Table stickyHeader aria-label="Orku data table">
          <TableHead>
          <TableRow >
    {/* Kundenummer Filter */}
    <TableCell
      align="center"
      sx={{ fontWeight: 'bold', height: '70px', padding: '10px'}} // Set your desired height
    >
      <FilterTextField
        label="Kundenummer"
        value={filterKundenummer}
        onChange={(e) => setFilterKundenummer(e.target.value)}
        showFilter={showFilterKundenummer}
        setShowFilter={setShowFilterKundenummer}
      />
    </TableCell>

    {/* VAD Filter */}
    <TableCell
      align="center"
      sx={{ fontWeight: 'bold', height: '50px', padding: '10px'}} // Adjust height and padding
    >
      <FilterTextField
        label="AD"
        value={filterVAD}
        onChange={(e) => setFilterVAD(e.target.value)}
        showFilter={showFilterVAD}
        setShowFilter={setShowFilterVAD}
      />
    </TableCell>

    {/* Kundenavn Filter */}
    <TableCell
      align="left"
      sx={{ fontWeight: 'bold', height: '50px', padding: '10px'}} // Customize the height
    >
      <FilterTextField
        label="Kundenavn"
        value={filterKundenavn}
        onChange={(e) => setFilterKundenavn(e.target.value)}
        showFilter={showFilterKundenavn}
        setShowFilter={setShowFilterKundenavn}
      />
    </TableCell>

    <TableCell align="right" >
  {/* Tooltip with green icon */}
  <Tooltip title="Programmet viser alle kunder med direktebestilling. Kjøp hittil i år er ordrer totalt og vil bli nullstilt ved overgang til nytt år.
Kunder som ikke lenger er aktive må meldes til Autodata for sletting av direktbestilling.
" arrow>
    <IconButton size="small" sx={{ color: 'green', marginRight: '8px' }}>
      <InfoIcon />
    </IconButton>
  </Tooltip>
  Kjøp hittil i år
</TableCell>


    {/* Empty Cell */}
    <TableCell align="right" sx={{ fontWeight: 'bold', height: '50px', padding: '10px'}} />
  </TableRow>
</TableHead>


            {/* Table Body */}
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={4} align="center">
                    <CircularProgress size={24} />
                  </TableCell>
                </TableRow>
              ) : displayedData.length > 0 ? (
                displayedData.map((row) => (
                  <TableRow
                  key={row.customerNumber + row.companyId}
                  sx={{
                    '&:hover': {
                      backgroundColor: '#f1f1f1', // Table Row Hover Effect
                    },
                  }}
                >
                    <TableCell align="left">{row.customerNumber}</TableCell>
                    <TableCell align="left">{row.companyId}</TableCell>
                    <TableCell align="left">{row.customerName}</TableCell>
                    <TableCell align="right">{formatNumber(row.ytdSales)}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4} align="center">
                    No data available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <Box display="flex" justifyContent="flex-end" height="50px">
          <ButtonVitec
            _type="button"
            _label="Excel"
            _onclick={exportToCSV}
            _fontsize="1em"
            _width={150}
            _height={35}
            _padding={5}
            _margin={0}
            _backgroundColor="#1d6f42"
          />
        </Box>
        </TableContainer>

        {/* Button aligned to the right */}
    
      </Paper>
    </div>
  );
};

export default Orku;
