import { TableCell, TableHead, TableRow, TableSortLabel, Tooltip } from '@mui/material';
import { Component } from "react";
import { connect } from 'react-redux';
import { AppState } from "../../../store/rootReducer";
import { TaleSearchParams } from '../../../models/TalenesTale';

class TallenesTaleTableHeadKunde extends Component<Props> {

  rows = [
    { id: "id", numeric: false, disablePadding: false, label: "Kunde Id." },
    { id: "navn", numeric: false, disablePadding: false, label: "Kundenavn" },
    { id: "nettA", numeric: false, disablePadding: false, label: "i år" },
    { id: "nettB", numeric: false, disablePadding: false, label: "i fjor" },
    { id: "diff", numeric: false, disablePadding: false, label: "diff." },
    { id: "percent", numeric: false, disablePadding: false, label: "%" },
  ];

  componentDidUpdate() { if (this.props.taleSearch !== undefined) { this.updateRowLabels(); } }

  updateRowLabels = () => {
    if (this.props.taleSearch)
      this.rows = [
        { id: "id", numeric: false, disablePadding: false, label: "Kunde Id." },
        { id: "navn", numeric: false, disablePadding: false, label: "Kundenavn" },
        {
          id: "nettA", numeric: false, disablePadding: false, label: (this.props.taleSearch?.brutto ? 'Brutto' : 'Netto') + ' i år '
            + this.props.taleSearch?.displayDate.substring(0, 17)
        },
        {
          id: "nettF", numeric: false, disablePadding: false, label: (this.props.taleSearch?.brutto ? 'Brutto' : 'Netto') + ' i fjor '
            + this.props.taleSearch?.displayDate.substring(18, this.props.taleSearch?.displayDate.length)
        },
        { id: "diff", numeric: false, disablePadding: false, label: (this.props.taleSearch?.brutto ? 'Brutto' : 'Netto') + ' diff.' },
        { id: "percent", numeric: false, disablePadding: false, label: "%" },
      ];
    return this.rows;
  };

  createSortHandler = property => event => { this.props.onRequestSort(event, property); };

  render() {
    //
    return (
      <TableHead>
        <TableRow>
          {this.updateRowLabels().map(
            row => {
              return (
                <TableCell
                  key={row.id}
                  // align={row.id === 'navn' ? "left" : "right"}
                  sortDirection={(this.props.orderBy === row.id ? (this.props.order === "desc" ? "desc" : "asc") : "desc")/**/}
                  sx={{
                    textTransform: 'capitalize', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
                    marginLeft: 0, padding: '6px 12px', textAlign: 'left', fontSize: 16, fontWeight: 400,
                    backgroundColor: '#fff', color: '#575655', borderBottom: '1px solid #c4c4c4', //
                    "& .MuiTableSortLabel-root:hover, .MuiTableSortLabel-root:hover .MuiTableSortLabel-icon": {
                      color: "#000"
                    },
                    // for active case hover color
                    "& .MuiTableSortLabel-root.Mui-active:hover, .MuiTableSortLabel-root.Mui-active:hover .MuiTableSortLabel-icon": {
                      color: "#000"
                    },
                    "& .MuiTableSortLabel-root.Mui-active, .MuiTableSortLabel-root.Mui-active .MuiTableSortLabel-icon": {
                      fontWeight: 600,
                      color: "#d30535"
                    }
                  }}
                >
                  <Tooltip
                    title="Sort"
                    placement={row.numeric ? "bottom-end" : "bottom-start"}
                    enterDelay={300}
                  >
                    <TableSortLabel
                      active={this.props.orderBy === row.id}
                      direction={(this.props.order === "desc" ? "desc" : "asc")}
                      onClick={this.createSortHandler(row.id)}
                    >{row.label}</TableSortLabel>
                  </Tooltip>
                </TableCell>
              )
            })}
        </TableRow>
      </TableHead>
    );
  }
}

interface sentFromParentComponent {
  order?: string;
  orderBy?: string;
  onRequestSort: (event: any, property: any) => void;
  rowCount: number;
  classes?: any, //place Holder
}

interface StateProps {
  selectedCriteriaItemList: number[];
  taleSearch: TaleSearchParams;
  classes?: Partial<Record<"tableCell" | "spacer", string>>;
}

type Props = sentFromParentComponent & StateProps/* & DispatchProps*/;

export function mapStateToProps(state: AppState): StateProps {
  return {
    selectedCriteriaItemList: state.tallenesTale.selectedCriteriaItemList,
    taleSearch: state.tallenesTale.taleSearchKunde,
  };
}

export default connect<StateProps, undefined, sentFromParentComponent, AppState>(mapStateToProps, null)(TallenesTaleTableHeadKunde);