//                                                                                
export const NV03_VALF_LIST_GET_INIT = 'NV03_VALF_LIST_GET_INIT';
export const NV03_VALF_LIST_GET = 'NV03_VALF_LIST_GET';
export const NV03_VALF_LIST_GET_SUCCESS = 'NV03_VALF_LIST_GET_SUCCESS';
export const NV03_VALF_LIST_GET_FAILED = 'NV03_VALF_LIST_GET_FAILED';

export const NV03_RESET_FORM = 'NV03_RESET_FORM';
export const NV03_RESET_COMPONENT_STORE = 'NV03_RESET_COMPONENT_STORE';


export const NV03_SET_RESET_FORM_ENABLED = 'NV03_SET_RESET_FORM_ENABLED';

export const NV03_SET_COLUMN_HEADER_LIST = 'NV03_SET_COLUMN_HEADER_LIST';

export const NV03_SET_VEILPRIS_CHECKBOX_STATE = 'NV03_SET_VEILPRIS_CHECKBOX_STATE';
export const NV03_SET_VISALLELAGER_CHECKBOX_STATE = 'NV03_SET_VISALLELAGER_CHECKBOX_STATE';
export const NV03_SET_BEHOLDNING_CHECKBOX_STATE = 'NV03_SET_BEHOLDNING_CHECKBOX_STATE';
export const NV03_SET_LOCATION_CHECKBOX_STATE = 'NV03_SET_LOCATION_CHECKBOX_STATE';
//
export const NV03_LIST_EXPORT_TO_EXCELL = 'NV03_LIST_EXPORT_TO_EXCELL';
export const NV03_LIST_EXPORT_TO_EXCELL_FAILED = 'NV03_LIST_EXPORT_TO_EXCELL_SUCCESS';
//
export const NV03_VALF_LABEL_GET_SUCCESS = 'NV03_VALF_LABEL_GET_SUCCESS';
export const NV03_VALF_LABEL_GET_FAILED = 'NV03_VALF_LABEL_GET_FAILED';
//
export const SET_KEY_VALUE_PAIRS_4_NV03 = 'SET_KEY_VALUE_PAIRS_4_NV03';
// 
export const SET_CURRENT_ROW_FOCUSED = 'SET_CURRENT_ROW_FOCUSED';
// 
export const SET_SCROLL_TOP_POSITION = 'SET_SCROLL_TOP_POSITION';