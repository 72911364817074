import { Card, CardContent, CardHeader, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import SVGLinks from '../components/SVGLinks';
import C from '../felles/UtilsCSS';
import CDriftStatus from '../app/landing-page/components/CDriftStatus';
import CAutodataWeb from '../app/landing-page/components/CAutodataWeb';
import CNyheter from '../app/landing-page/components/CNyheter';
import CSupport from '../app/landing-page/components/CSupport';
import CLenker from '../app/landing-page/components/CLenker';


const useStyles = makeStyles()((t: Theme) =>
({
  root: { height: '100%',
  flex: '1',
  overflowY: 'auto',   
  scrollbarColor: '#2E2D2C #fff',
  scrollbarWidth: 'thin',
},
  cardHeader: {
    backgroundColor: '#2E2D2C', color: '#fff', paddingTop: 12, paddingBottom: 12, paddingLeft: 20,
    [C.IPAD_10]: { height: 40 },
    [C.SAMSUNG]: { height: 40 },
  },
  cardContent: {
    marginTop: 0, paddingTop: 0, paddingBottom: 0, paddingLeft: 20,
 

    [C.IPAD_10]: { paddingLeft: 15, },
  },
  title: {
    display: 'flex', width: '100%', fontSize: '1.6em',
    [C.r(t, C.XXS, C.XXX)]: { fontSize: '.7em' },
    [C.r(t, C.XS, C.XSX)]: { fontSize: '.7em' },
    [C.r(t, C.SM, C.SMX)]: { fontSize: '.7em' },
    [C.r(t, C.MD, C.MDX)]: { fontSize: '.9em' },
    [C.r(t, C.LG, C.LGX)]: { fontSize: '1.2em' },
    [C.r(t, C.XL, C.XLX)]: { fontSize: '1.4em' },
    [C.r(t, C.XXL, C.YLX)]: { fontSize: '1.7em' },
    [C.IPAD_10]: { fontSize: '.8em', },
    [C.SAMSUNG]: { fontSize: '.8em', },
  },
  logo: { lineHeight: 1.5, marginLeft: '10px', color: '#fff', width: '15%', cursor: 'pointer', },
})

);

export default function LPContentCard(props) {
  const { classes } = useStyles();
  const { link, title } = props;
  //
  function getCardContentByType(type: number) {
    switch (type) {
      case 0:
        //return <CDriftStatus numitems={3} />;
        return <CDriftStatus />;
      case 1:
        return <CAutodataWeb />;
      case 2:
        //return <CNyheter numitems={3} />;
        return <CNyheter />;
      case 3:
        return <CSupport />;
      case 4:
        return <CLenker />;
      default:
        return [];
    }
  }
  //
  function handleSubmit(event, url) {
    event.preventDefault();
    if (link.length > 2) {
      const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
      if (newWindow) newWindow.opener = null;
    }
  }
  //
  return (
    <Card elevation={10} className={classes.root}>
      <CardHeader
        onClick={(e) => handleSubmit(e, link)}
        className={classes.cardHeader}
        title={
          <div className={classes.title}>
            <div>{title}</div>
            {link !== '' ? (
              <div className={classes.logo}>
                <SVGLinks width="20" />
              </div>
            ) : null}
          </div>
        }
      />
      <CardContent className={classes.cardContent}>
        
        {getCardContentByType(props.cardType)}
        
        </CardContent>
    </Card>
  );
}