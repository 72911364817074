import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "tss-react/mui";
import ButtonVitec from "../components/ButtonVitec";
import HjelpFormData from "../models/HjelpFormData";
import * as actions from "../store/actions/index";
import { AppState } from "../store/rootReducer";

const useStyles = makeStyles()((/*theme*/) => ({

  dialogCustomizedWidth: { 'max-width': '950px' },
  divbackground: {
    position: 'absolute',
    width: '100vw',
    height: '100vh',
    backgroundColor: '#000',
    display: 'inline-block',
    zIndex: 1800,
    opacity: 0.5
  },
  contentHelpOuter: {
    borderRadius: '10px',
    backgroundColor: '#fff',
    display: 'inline-block',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1810,
    padding: '10px 10px 20px 15px',
    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
    maxHeight: '82vh',
    minWidth: '40vw',
    minHeight: '50vh',
  },
  contentHelpInner: {
    overflowY: 'scroll',
    paddingRight: 10,
    paddingbottom: 90,
    marginbottom: 20,
    maxHeight: `calc(80vh - 64px)`,
    height: '100%',
    verticalAlign: 'top',
    fontFamily: "'PT Mono', 'Courier New'",
    fontSize: '1.2em',
    minWidth: '50vw',
  },
  contentLoading: { minWidth: '40vw', minHeight: '40vh', height: '100%', },
  btWrapper: { textAlign: 'right', },
}));

export default function HelpDialog() {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const helpContent: any = useSelector((state) => (state as AppState).trans.helpContent);
  const currentFieldwithFocus: any = useSelector((state) => (state as AppState).trans.currentFieldwithFocus);
  const helpParams: HjelpFormData = useSelector((state) => (state as AppState).trans.helpParams);
  //
  const handleClose = () => {
    dispatch(actions.setShowHelpContent(false));
  };
  //
  const handleHelpHTMLResponseUpdate = (helpParamsm, helpContent) => {
    const JSXItemEl: any = [];
    const textHelpContent = helpContent.replace('&TEKST=', '');
    //
    for (let i = 0, charsLength = textHelpContent.length; i < charsLength; i += 79) {
      if (textHelpContent.substring(i, i + 79) !== ' '.repeat(79).toString()) {
        JSXItemEl.push(<span key={'hlp' + i}>{textHelpContent.substring(i, i + 79).replaceAll(' ', '\u00a0')}<br /></span>);
      }
    }
    //
    JSXItemEl.unshift(<span key={'hlp_fir'}>{helpParams ? ' > ' + helpParams.transid + ' > ' + helpParams?.DFH0003 + ' > ' + helpParams?.DFH0004 : null}<br /><br /></span>);
    return JSXItemEl;
  }
  //
  return (
    <>
      <div className={classes.divbackground} onClick={() => handleClose()}></div>
      <div className={classes.contentHelpOuter}>
        <div className={classes.contentHelpInner}>
          {helpContent ?
            helpContent === 'loading' ? <div className={classes.contentLoading}>Loading...    {helpParams ? ' > ' + helpParams.transid + ' > ' + helpParams?.DFH0003 + ' > ' + helpParams?.DFH0004 : null}
              {(currentFieldwithFocus?.value !== undefined && currentFieldwithFocus?.value !== '') ? ' > ' + currentFieldwithFocus?.value.toUpperCase() : ''}</div> : handleHelpHTMLResponseUpdate(helpParams, helpContent)
            : null}
        </div>
        <div className={classes.btWrapper}> <ButtonVitec _width={80} _height={30} _label={'Lukk'} _onclick={handleClose} />
        </div>
      </div>
    </>
  );
}
