import {YearlySalesData , SalgVarerData, KundeSalgData, KundeData,TransactionData,ProductSales, OrderData} from '../../models/NV01SALG';
import * as actionTypesNV01SALG from '../actions/actionTypesNV01';
import * as fromApp from "../rootReducer";

export interface FeatureState extends fromApp.AppState {
  state: NV01SALG
}


export interface NV01SALG {
  Salg: ProductSales[];
  SalgVarer: SalgVarerData[];
  KundeSalg: KundeSalgData[];
  KundeData: KundeData | null;
  Transaction: TransactionData[];
  ExportTransactions: TransactionData[];
  OrderDetail: OrderData | null;
  error: any;
  isFetching: boolean;
}

const initialStateSalg: NV01SALG = {
  Salg: [],
  SalgVarer: [],
  KundeSalg: [],
  Transaction: [],
  ExportTransactions:[],
  KundeData: null,
  OrderDetail: null,
  error: undefined,
  isFetching: false,
};

const NV01SALGReducer = (state = initialStateSalg, action: any) => {
  switch (action.type) {
    case actionTypesNV01SALG.SALG_SISTE_4_AAR_GET:
      return {
        ...state,
        isFetching: true,
        error: undefined,
      };
    case actionTypesNV01SALG.SALG_SISTE_4_AAR_SUCCESS:
      return {
        ...state,
        Salg: action.payload,
        isFetching: false,
        error: undefined,
      };
    case actionTypesNV01SALG.SALG_SISTE_4_AAR_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case actionTypesNV01SALG.SALGVAREDATA_GET:
      return {
        ...state,
        isFetching: true,
        error: undefined,
      };
    case actionTypesNV01SALG.SALGVAREDATA_SUCCESS:
      return {
        ...state,
        SalgVarer: action.payload,
          isFetching: false,
          error: undefined,
      };
    case actionTypesNV01SALG.SALGVAREDATA_FAILURE:
        return {
          ...state,
          isFetching: false,
          error: action.payload,
        };
     // New cases for KUNDE_SALG_DATA
     case actionTypesNV01SALG.KUNDE_SALG_DATA_GET:
      return {
        ...state,
        isFetching: true,
        error: undefined,
      };
    case actionTypesNV01SALG.KUNDE_SALG_DATA_SUCCESS:
      return {
        ...state,
        KundeSalg: action.payload,
        isFetching: false,
        error: undefined,
      };
    case actionTypesNV01SALG.KUNDE_SALG_DATA_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
       // New cases for KUNDE_DATA
    case actionTypesNV01SALG.KUNDE_DATA_GET:
      return {
        ...state,
        isFetching: true,
        error: undefined,
      };
    case actionTypesNV01SALG.KUNDE_DATA_SUCCESS:
      return {
        ...state,
        KundeData: action.payload,  // Set KundeData on success
        isFetching: false,
        error: undefined,
      };
    case actionTypesNV01SALG.KUNDE_DATA_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };

       // New cases for TransactionDATA
       case actionTypesNV01SALG.ALL_TRANSAKSJONER_GET:
      return {
        ...state,
        isFetching: true,
        error: undefined,
      };
    
    case actionTypesNV01SALG.ALL_TRANSAKSJONER_SUCCESS:
      return {
        ...state,
        Transaction: action.payload,
        isFetching: false,
        error: undefined,
      };
    case actionTypesNV01SALG.ALL_TRANSAKSJONER_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
       // New cases for exporting all transaction data 
    case actionTypesNV01SALG.EXPORT_ALL_TRANSAKSJONER:
      return {
        ...state,
        isFetching: true,  // Set isFetching to true while exporting transactions
        error: undefined,
      };
    case actionTypesNV01SALG.EXPORT_ALL_TRANSAKSJONER_SUCCESS:
      return {
        ...state,
        ExportTransactions: action.payload,  // Store exported transactions 
        isFetching: false,  // Set isFetching to false once export is complete
        error: undefined,
      };
    case actionTypesNV01SALG.EXPORT_ALL_TRANSAKSJONER_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,  // Handle export failure
      };
      case actionTypesNV01SALG.ORDERDETAIL_GET:  
      return {
        ...state,
        isFetching: true,
        error: undefined,
      };
    case actionTypesNV01SALG.ORDERDETAIL_SUCCESS:
      return {
        ...state,
        OrderDetail: action.payload,
        isFetching: false,
        error: undefined,
      };
    case actionTypesNV01SALG.ORDERDETAIL_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
      case actionTypesNV01SALG.CLEAR_DATA:
      return {
        ...state,
        Salg: [],
        SalgVarer: [],
        KundeSalg: [],
        Transaction: [],
        OrderDetail: [],
        KundeData: null,
        error: undefined,
      };
      case actionTypesNV01SALG.CLEAR_ORDERDETAILDATA:
        return {
          ...state,         
          OrderDetail: [],       
          error: undefined,
        };
    default:
      return state;
  }
};
export default NV01SALGReducer;
