import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import PageAddUser from "../app/admin-user/PageAddUser";
import PageProfil from "../app/admin-user/PageProfil";
import PageUserList from "../app/admin-user/PageUserList";
import Home from '../app/home/Home';
import LandingPage from '../app/landing-page/LandingPage';
import Logout from '../app/logout/Logout';
import PasswordLinkRecovery from '../app/password/PasswordLinkRecovery';
import PasswordUpdate from '../app/password/PasswordUpdate';
import PDFForm from '../temp/pdf/PDFForm';
import LPSupport from '../app/support/_LPSupport';

import ACC1Page from "../pages/ACC1/ACC1Page";
import CriteriaItemPage from "../pages/SS11/CriteriaItemPage";
import TallenesTalePage from "../pages/SS10/TallenesTalePage";
import LPVSE from '../heirloom/VSEPage';
import { AppState } from "../store/rootReducer";
import U from './Utils';
import requireAuth from "./requireAuth";
import NO01Page from '../pages/NO01/NO01Page';
import NV01Page from '../pages/NV01/NV01VARSPage';
import NV03Page from "../pages/NV03/NV03Page";
import NF01Page from '../pages/NF01/NF01Page';
import NF02Page from '../pages/NF02/NF02Page';
import NF03Page from '../pages/NF03/NF03Page';
import NF04Page from '../pages/NF04/NF04Page';
import NF05Page from '../pages/NF05/NF05Page';
import NF06Page from '../pages/NF06/NF06Page';
import NF07Page from '../pages/NF07/NF07Page';
import NF08Page from '../pages/NF08/NF08Page';
import NF09Page from '../pages/NF09/NF09Page';
import NF10Page from '../pages/NF10/NF10Page';
import NF11Page from '../pages/NF11/NF11Page';
import NF12Page from '../pages/NF12/NF12Page';

/**
 * Routes configuration for the application.
 * Author: fabrice.miras@vitecsoftware.com
 * Date: 29.07.2024
 */

// Common routes available to all users
const commonRoutes = [
  { path: U.HOMEPAGE_CONTEXT, element: <Home /> },
  { path: `${U.HOMEPAGE_CONTEXT}/home`, element: <Home /> },
  { path: `${U.HOMEPAGE_CONTEXT}/logout`, element: <Logout /> },
  { path: `${U.HOMEPAGE_CONTEXT}/login.html`, element: <Home /> },
  { path: `${U.HOMEPAGE_CONTEXT}/login`, element: <Home /> },
  { path: `${U.HOMEPAGE_CONTEXT}/menu`, element: React.createElement(requireAuth(LandingPage)) },
  { path: `${U.HOMEPAGE_CONTEXT}/vse`, element: React.createElement(requireAuth(LPVSE)) },
  { path: `${U.HOMEPAGE_CONTEXT}/support`, element: React.createElement(requireAuth(LPSupport)) },
  { path: `${U.HOMEPAGE_CONTEXT}/passwordlinkrecovery`, element: <PasswordLinkRecovery /> },
  { path: `${U.HOMEPAGE_CONTEXT}/passwordupdate`, element: <PasswordUpdate /> },
  { path: `${U.HOMEPAGE_CONTEXT}/BR04`, element: React.createElement(requireAuth(PageProfil)) },
  { path: `${U.HOMEPAGE_CONTEXT}/NV01`, element: React.createElement(requireAuth(NV01Page)) },
  { path: `${U.HOMEPAGE_CONTEXT}/NO01`, element: React.createElement(requireAuth(NO01Page)) },
  { path: `${U.HOMEPAGE_CONTEXT}/NV03`, element: React.createElement(requireAuth(NV03Page)) },
  // Filopplasting
  { path: `${U.HOMEPAGE_CONTEXT}/NF01`, element: React.createElement(requireAuth(NF01Page)) },
  { path: `${U.HOMEPAGE_CONTEXT}/NF02`, element: React.createElement(requireAuth(NF02Page)) },
  { path: `${U.HOMEPAGE_CONTEXT}/NF03`, element: React.createElement(requireAuth(NF03Page)) },
  { path: `${U.HOMEPAGE_CONTEXT}/NF04`, element: React.createElement(requireAuth(NF04Page)) },
  { path: `${U.HOMEPAGE_CONTEXT}/NF05`, element: React.createElement(requireAuth(NF05Page)) },
  { path: `${U.HOMEPAGE_CONTEXT}/NF06`, element: React.createElement(requireAuth(NF06Page)) },
  { path: `${U.HOMEPAGE_CONTEXT}/NF07`, element: React.createElement(requireAuth(NF07Page)) },
  { path: `${U.HOMEPAGE_CONTEXT}/NF08`, element: React.createElement(requireAuth(NF08Page)) },
  { path: `${U.HOMEPAGE_CONTEXT}/NF09`, element: React.createElement(requireAuth(NF09Page)) },
  { path: `${U.HOMEPAGE_CONTEXT}/NF10`, element: React.createElement(requireAuth(NF10Page)) },
  { path: `${U.HOMEPAGE_CONTEXT}/NF11`, element: React.createElement(requireAuth(NF11Page)) },
  { path: `${U.HOMEPAGE_CONTEXT}/NF12`, element: React.createElement(requireAuth(NF12Page)) },
];

/**
 * Generates role-based routes based on user roles.
 * @param {Object} userJWT - The JWT token containing user roles.
 * @returns {Array} - Array of route objects.
 */
const roleBasedRoutes = (userJWT) => {
  const routes: any = [];

  if (userJWT?.roles?.includes('ROLE_31') || userJWT?.roles?.includes('ROLE_32')) {
    routes.push({ path: `${U.HOMEPAGE_CONTEXT}/SS10`, element: React.createElement(requireAuth(TallenesTalePage)) });
  }
  if (userJWT?.roles?.includes('ROLE_32')) {
    routes.push({ path: `${U.HOMEPAGE_CONTEXT}/SS11`, element: React.createElement(requireAuth(CriteriaItemPage)) });
  }
  if (userJWT?.roles?.includes('ROLE_ADMIN')) {
    routes.push({ path: `${U.HOMEPAGE_CONTEXT}/BR00`, element: React.createElement(requireAuth(PageUserList)) });
    routes.push({ path: `${U.HOMEPAGE_CONTEXT}/BR01`, element: React.createElement(requireAuth(PageAddUser)) });
  }
  if (userJWT?.roles?.includes('ROLE_MINIADMIN')) {
    routes.push({ path: `${U.HOMEPAGE_CONTEXT}/BR02`, element: React.createElement(requireAuth(PageUserList)) });
    routes.push({ path: `${U.HOMEPAGE_CONTEXT}/BR03`, element: React.createElement(requireAuth(PageAddUser)) });
  }
  if (userJWT?.roles?.includes('ROLE_22')) {
    routes.push({ path: `${U.HOMEPAGE_CONTEXT}/ACC1`, element: React.createElement(requireAuth(ACC1Page, 'ACC1')) });
    routes.push({ path: `${U.HOMEPAGE_CONTEXT}/pdf`, element: <PDFForm /> });
  }

  return routes;
};

/**
 * Combines common routes and role-based routes into a single route configuration.
 * @param {Object} userJWT - The JWT token containing user roles.
 * @returns {Array} - Array of JSX Route elements.
 */
const generateRoutes = (userJWT) => {
  const routes = [
    ...commonRoutes,
    ...roleBasedRoutes(userJWT),
    { path: `${U.HOMEPAGE_CONTEXT}/robots.txt`, element: <Navigate to={`${U.HOMEPAGE_CONTEXT}/robots.txt`} replace={true} /> },
    { path: '*', element: <Navigate to={`${U.HOMEPAGE_CONTEXT}/menu`} replace={true} /> },
  ];

  return routes.map((route, index) => <Route key={index} path={route.path} element={route.element} />);
};

/**
 * Main function to get the route configuration based on user authentication.
 * @returns {JSX.Element} - Routes JSX element containing all route configurations.
 */
export default function getRoutes() {
  const userJWT = useSelector((state: AppState) => state.auth.userJWT);
  const routes = generateRoutes(userJWT);
  return <Routes>{routes}</Routes>;
}
