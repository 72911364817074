import * as actionTypes from './actionTypes';

export const setScale = (payload: any) => {
  return { type: actionTypes.SCREEN_ZOOM_SCALE, payload };
};

export const setScreenSize = (payload: any) => {
  return { type: actionTypes.SCREEN_SIZE, payload };
};

export const setGridScale = (payload: any) => {
  return { type: actionTypes.GRID_SCALE, payload };
};

export const setGridSize = (payload: any) => {
  return { type: actionTypes.GRID_WIDTH, payload };
};

export const setGridLayouts = (layout: any, layouts: any) => {
  return { type: actionTypes.SET_GRID_LAYOUTS, layout, layouts };
};

export const setGridLayout = (layout: any) => {
  return { type: actionTypes.SET_GRID_LAYOUTS, layout };
};

export const setCurrentBreakPoint = (payload: any) => {
  return { type: actionTypes.SET_CURRENT_BREAK_POINT, payload };
};

export const setMouted = (payload: any) => {
  return { type: actionTypes.SET_MOUNTED, payload };
};

export const setCompactType = (payload: any) => {
  return { type: actionTypes.SET_COMPACT_TYPE, payload };
};

export const setIsMobileEnabled = (payload: any) => {
  return { type: actionTypes.SET_IS_MOBILE_ENABLED, payload };
};

export const setDrawerState = (payload: any) => {
  return { type: actionTypes.SET_DRAWER_STATE, payload };
};

export const setSandwichButtonStateClicked = (payload: any) => {
  return { type: actionTypes.SET_BUTTON_SANDWICH_STATE_CLICKED, payload };
};

export const setContextMenuVisible = (payload: any) => {
  return { type: actionTypes.SET_CONTEXTMENU_VISIBLE, payload };
};

export const setContexMenuX = (payload: any) => {
  return { type: actionTypes.SET_CONTEXTMENU_X, payload };
};

export const setContexMenuY = (payload: any) => {
  return { type: actionTypes.SET_CONTEXTMENU_Y, payload };
};

export const setTransidRedirect = (payload: any) => {
  return { type: actionTypes.SET_TRANSID_REDIRECT, payload };
};

export const setTilpasset = (payload: any) => {
  return { type: actionTypes.SET_TILPASSET, payload };
};

export const setScrollTop = (payload: any) => {
  return { type: actionTypes.SET_SCROLLTOP, payload };
};

export const setLocationPath = (payload: any) => {
  return { type: actionTypes.LOCATION_PATH, payload };
};

export const updateTooltipList = (payload: any) => {
  return { type: actionTypes.UPDATE_TOOLTIPS_LIST, payload };
};

export const setURLCallBack = (payload: any) => {
  return { type: actionTypes.SET_URL_CALLBACK, payload };
};

export const setKeyValuePairs4VSE = (payload: any) => {
  return { type: actionTypes.SET_KEY_VALUE_PAIRS_4_VSE, payload };
};
