import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import C from '../../../felles/UtilsCSS';

const useStyles = makeStyles()((theme: Theme) =>
({
  root: { padding: 3, },
  gridItem: {    display: 'flex',    padding: 'px 0',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f6f6f6',
    },
    [C.r(theme, C.XXS, C.XXX)]: { padding: '10px 0', fontSize: '.8em' },
    [C.r(theme, C.XS, C.XSX)]: { padding: '10px 0', fontSize: '.85em' },
    [C.r(theme, C.SM, C.SMX)]: { padding: '10px 0', fontSize: '.9em' },
    [C.r(theme, C.MD, C.MDX)]: { padding: '10px 0', fontSize: '1em' },
    [C.r(theme, C.LG, C.LGX)]: { padding: '10px 0', fontSize: '1em' },
    [C.r(theme, C.XL, C.XLX)]: { padding: '10px 0', fontSize: '.1.1em' },
    [C.r(theme, C.XXL, C.YLX)]: { padding: '28px 0', fontSize: '.1.2em' },
    [C.IPAD_10]: { fontSize: '.8em', paddingBottom: 0, },
    [C.SAMSUNG]: { fontSize: '.8em', paddingTop: 6, margin: 0, },
  },
  gridItemTitle: { paddingLeft: '0px', color: '#333', },
})
);
export default function CLenker() {
  const { classes } = useStyles();
  const handleClick = (event: any, l: any) => {
    event.preventDefault();
    const newWindow = window.open(l.alink, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };
  //
  function generateDOM() {
    return getLenker().map((l, i) => {
      return (
        <div key={i} className={classes.gridItem} onClick={(e) => handleClick(e, l)}>
          <div className={classes.gridItemTitle}>{l.title}</div>
        </div>
      );
    });
  }
  return <div className={classes.root}>{generateDOM()}</div>;
}

//
function getLenker() {
  const lenker = [
    { title: 'Autosystemer hjemmeside', alink: 'https://www.vitec-autosystemer.com' },
    { title: 'Avslutningsdatoer fakturering', alink: 'https://www.vitec-autosystemer.com/informasjon-autodata/avslutningsdatoer/' },
    { title: 'adRegnskap / adLønn', alink: 'https://terminal.autodata.no/RDWeb/Pages/en-US/login.aspx?ReturnUrl=/RDWeb/Pages/en-US/Default.aspx' },
    { title: 'Eye-share', alink: 'https://gw2.autodata.no/tab_adeyeshare_meny.php' },
  ];
  return lenker;
}
