// FAVORITTLISTE REPORT
export const TFAVORITTLISTE_LIST_GET_REPORT_INIT = 'TFAVORITTLISTE_LIST_GET_REPORT_INIT';
export const TFAVORITTLISTE_LIST_GET_REPORT = 'TFAVORITTLISTE_LIST_GET_REPORT';
export const TFAVORITTLISTE_LIST_GET_REPORT_K_SUCCESS = 'TFAVORITTLISTE_LIST_GET_REPORT_K_SUCCESS';
export const TFAVORITTLISTE_LIST_GET_REPORT_V_SUCCESS = 'TFAVORITTLISTE_LIST_GET_REPORT_V_SUCCESS';
export const TFAVORITTLISTE_LIST_GET_REPORT_H_SUCCESS = 'TFAVORITTLISTE_LIST_GET_REPORT_H_SUCCESS';
export const TFAVORITTLISTE_LIST_GET_REPORT_G_SUCCESS = 'TFAVORITTLISTE_LIST_GET_REPORT_G_SUCCESS';
export const TFAVORITTLISTE_LIST_GET_REPORT_S_SUCCESS = 'TFAVORITTLISTE_LIST_GET_REPORT_S_SUCCESS';
//
export const TFAVORITTLISTE_LIST_GET_REPORT_PDF_SUCCESS = 'TFAVORITTLISTE_LIST_GET_REPORT_PDF_SUCCESS';
export const TFAVORITTLISTE_LIST_GET_REPORT_EXCEL_SUCCESS = 'TFAVORITTLISTE_LIST_GET_REPORT_EXCEL_SUCCESS';
//
export const TFAVORITTLISTE_LIST_GET_REPORT_FAILED = 'TFAVORITTLISTE_LIST_GET_REPORT_FAILED';
export const TFAVORITTLISTE_LIST_GET_REPORT_STATE = 'TFAVORITTLISTE_LIST_GET_REPORT_STATE';
export const TFAVORITTLISTE_SET_VISNING_TYPE = 'TFAVORITTLISTE_SET_VISNING_TYPE';
export const TFAVORITTLISTE_SET_STATE = 'TFAVORITTLISTE_SET_STATE';
//
export const T_SET_SEARCH_PARAM_KUNDE = 'T_SET_SEARCH_PARAM_KUNDE';
export const T_SET_SEARCH_PARAM_HGRUPPE = 'T_SET_SEARCH_PARAM_HGRUPPE';
export const T_SET_SEARCH_PARAM_GRUPPE = 'T_SET_SEARCH_PARAM_GRUPPE';
export const T_SET_SEARCH_PARAM_VGGRUPPE = 'T_SET_SEARCH_PARAM_VGGRUPPE';
export const T_SET_SEARCH_PARAM_SGRUPPE = 'T_SET_SEARCH_PARAM_SGRUPPE';
//
export const TFAVORITTLISTE_LIST_GET_INIT = 'TFAVORITTLISTE_LIST_GET_INIT';
export const TFAVORITTLISTE_LIST_GET = 'TFAVORITTLISTE_LIST_GET';
export const TFAVORITTLISTE_LIST_GET_SUCCESS = 'TFAVORITTLISTE_LIST_GET_SUCCESS';
export const TFAVORITTLISTE_LIST_GET_FAILED = 'TFAVORITTLISTE_LIST_GET_FAILED';
//
export const TFAVORITTLISTE_LIST_SAVE_POST_INIT = 'TFAVORITTLISTE_LIST_SAVE_POST_INIT';
export const TFAVORITTLISTE_LIST_SAVE_POST = 'TFAVORITTLISTE_LIST_SAVE_POST';
export const TFAVORITTLISTE_LIST_SAVE_POST_SUCCESS = 'TFAVORITTLISTE_LIST_SAVE_POST_SUCCESS';
export const TFAVORITTLISTE_LIST_SAVE_POST_FAILED = 'TFAVORITTLISTE_LIST_SAVE_POST_FAILED';
//
export const TFAVORITTLISTE_LIST_DELETE_POST_INIT = 'TFAVORITTLISTE_LIST_DELETE_POST_INIT';
export const TFAVORITTLISTE_LIST_DELETE_POST = 'TFAVORITTLISTE_LIST_DELETE_POST';
export const TFAVORITTLISTE_LIST_DELETE_POST_SUCCESS = 'TFAVORITTLISTE_LIST_DELETE_POST_SUCCESS';
export const TFAVORITTLISTE_LIST_DELETE_POST_FAILED = 'TFAVORITTLISTE_LIST_DELETE_POST_FAILED';

// 
export const TTALE_STATISTIC_REPORT_GET_INIT = 'TTALE_STATISTIC_REPORT_GET_INIT';
export const TTALE_STATISTIC_REPORT_GET = 'TTALE_STATISTIC_REPORT_GET';
export const TTALE_STATISTIC_REPORT_GET_SUCCESS = 'TTALE_STATISTIC_REPORT_GET_SUCCESS';
export const TTALE_STATISTIC_REPORT_GET_FAILED = 'TTALE_STATISTIC_REPORT_GET_FAILED';

// 
export const TTALE_STATISTIC_REPORT_EXPORT_EXCEL_GET_INIT = 'TTALE_STATISTIC_REPORT_EXPORT_EXCEL_GET_INIT';
export const TTALE_STATISTIC_REPORT_EXPORT_EXCEL_GET = 'TTALE_STATISTIC_REPORT_EXPORT_EXCEL_GET';
export const TTALE_STATISTIC_REPORT_EXPORT_EXCEL_GET_SUCCESS = 'TTALE_STATISTIC_REPORT_EXPORT_EXCEL_GET_SUCCESS';
export const TTALE_STATISTIC_REPORT_EXPORT_EXCEL_GET_FAILED = 'TTALE_STATISTIC_REPORT_EXPORT_EXCEL_GET_FAILED';

// 
export const TTALE_STATISTIC_REPORT_EXPORT_PDF_GET_INIT = 'TTALE_STATISTIC_REPORT_EXPORT_PDF_GET_INIT';
export const TTALE_STATISTIC_REPORT_EXPORT_PDF_GET = 'TTALE_STATISTIC_REPORT_EXPORT_PDF_GET';
export const TTALE_STATISTIC_REPORT_EXPORT_PDF_GET_SUCCESS = 'TTALE_STATISTIC_REPORT_EXPORT_PDF_GET_SUCCESS';
export const TTALE_STATISTIC_REPORT_EXPORT_PDF_GET_FAILED = 'TTALE_STATISTIC_REPORT_EXPORT_PDF_GET_FAILED';


// PDF/EMAIL
export const EXCEL_POST_UPLOAD_DOC_CRITERIA_INIT = 'EXCEL_POST_UPLOAD_DOC_CRITERIA_INIT';
export const EXCEL_POST_UPLOAD_DOC_CRITERIA = 'EXCEL_POST_UPLOAD_DOC_CRITERIA';
export const EXCEL_POST_UPLOAD_DOC_CRITERIA_SUCCESS = 'EXCEL_POST_UPLOAD_DOC_CRITERIA_SUCCESS';
export const EXCEL_POST_UPLOAD_DOC_CRITERIA_FAILED = 'EXCEL_POST_UPLOAD_DOC_CRITERIA_FAILED';

export const EXCEL_IMPORT_STATE = 'EXCEL_IMPORT_STATE';


export const SET_SELECTED_KUNDE = 'SET_SELECTED_KUNDE';
export const SET_SELECTED_SUB_KUNDE = 'SET_SELECTED_SUB_KUNDE';

export const SET_FULL_RESET_SALESREPORT = 'SET_FULL_RESET_SALESREPORT';

export const SET_RESET_SALESREPORT = 'SET_RESET_SALESREPORT';