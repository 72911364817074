import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Container,
  TableContainer,
  Paper,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { AppState } from '../../store/rootReducer';
import Heading from '../NO01/Heading';
import SearchFields from './components/InputFields';
import Header from './components/Header';
import YearlySales from './YearlySales';
import GridView from './GridView';
import { YearOptions } from './components/YearOptions';
import VarerDetails from './components/VarerDetail';
import { TransactionData,KundeSalgData } from '../../models/NV01SALG';
import { PaginationControls } from './components/Pagination';
import {
  fetchSalgDataRequest,
  fetchSalgVareDataRequest,
  fetchKundeSalgDataRequest,
  fetchKundeDataRequest,
  fetchTRANSAKSJONERRequest,
  fetchOrderDetailRequest,
  clearData,
  clearOrderDetailData,
  exportTRANSAKSJONERRequest
} from '../../store/actions/NV01.actions';
import CSharpUtils, { validateKundenummer, validateAlfa } from '../../felles/CSharpUtils';
import { KundeData } from '../../models/NV01SALG';

// Define the styles for the component
const useStyles = makeStyles()(() => ({
  root: { margin: 20, fontSize: '1em !important', color: '#fff' },
  container: {
    height: `calc(100vh - 130px)`,
    border: 'none',
    overflowX: 'hidden', 
  },
}));

const Vars = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(clearData());
    // Disable scroll on mount
    document.body.style.overflow = 'hidden';
    
    // Re-enable scroll on unmount
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  // Redux states
  const { Salg, SalgVarer, KundeData, KundeSalg, Transaction,ExportTransactions,OrderDetail, error: reduxError } = useSelector((state: AppState) => {
    
    return state.NV01SALG;
  });
 
  
  

  // Local states
  const [error, setError] = useState<string | null>(null);
  const [kundenummerInput, setKundenummerInput] = useState<string>('');
  const [isExportDisabled, setIsExportDisabled] = useState(true);
  const [searchedKundenummer, setSearchedKundenummer] = useState<string>('');
  const [alfa, setAlfa] = useState<string>('');
  const [artikkel, setArtikkel] = useState<string>('');
  const [varegruppe, setVaregruppe] = useState<string>('');
  const [salgDataType, setSalgDataType] = useState<string>('customerData');
  const [showYearOptions, setShowYearOptions] = useState(false);
  const [page, setPage] = useState<number>(5);
  const [pageSize, setPageSize] = useState<number>(100);
  const [sisteSalgData, setSisteSalgData] = useState('');
  const [selectedYears, setSelectedYears] = useState<{ [key: number]: boolean }>({
    2023: false,
    2024: true,
  });
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [suggestedCustomers, setSuggestedCustomers] = useState<KundeData[]>([]);
  const [isFirstSearch, setIsFirstSearch] = useState(true); // New state to track the first search

  const kundenummerRef = useRef<HTMLInputElement>(null);
  const [isPopupLoading, setIsPopupLoading] = useState(false);
  const [popupData, setPopupData] = useState<any | null>(null);

  


  useEffect(() => {
    // Enable/disable export button based on data availability
    if ((KundeSalg && KundeSalg.length > 0) || (Transaction && Transaction.length > 0)) {
      setIsExportDisabled(false);
    } else {
      setIsExportDisabled(true);
    }
  }, [KundeSalg, Transaction]);

  useEffect(() => {
    // Fetch suggested customers based on input
    if (kundenummerInput !== '') {
      fetchSuggestedCustomers();
    } else {
      setSuggestedCustomers([]);
    }
  }, [kundenummerInput]);

  const fetchSuggestedCustomers = useCallback(() => {
    setError('');

    if (!kundenummerInput) {
      setError('Please enter a valid customer number.');
      return;
    }

    const isAlphabetic = /^[A-Za-z]+$/.test(kundenummerInput);
    const kundenavn = isAlphabetic ? kundenummerInput : '';

    if (isAlphabetic) {
      dispatch(fetchKundeDataRequest(CSharpUtils.T_companyID, '', kundenavn));
    } else {
      dispatch(fetchKundeDataRequest(CSharpUtils.T_companyID, kundenummerInput, kundenavn));
    }

    setSalgDataType('customerData');
  }, [kundenummerInput, dispatch]);

  const handleError = (err: unknown) => {
    if (err instanceof Error) {
      setError(err.message);
    } else {
      setError('An unknown error occurred.');
    }
  };

  const fetchTransactions = useCallback((page = 1, pageSize = 500) => {
    if (isNaN(page) || page <= 0) {
      page = 1;
    }

    if (!validateAlfa(alfa)) {
      setError('Tast inn alfakode, tre karakterer');
      return;
    }
    setShowYearOptions(false);

    dispatch(fetchTRANSAKSJONERRequest(CSharpUtils.T_companyID, alfa, artikkel, page, pageSize));
   
    dispatch(fetchSalgVareDataRequest(CSharpUtils.T_companyID, alfa, artikkel));
   
    setSalgDataType('transactionData');
  }, [alfa, artikkel, dispatch]);

  const handleLoadMoreTransactions = useCallback(() => {
    const nextPage = page + 1;
    fetchTransactions(nextPage, pageSize);
    setPage(nextPage);
    setIsFirstSearch(false);
    
  }, [page, pageSize, fetchTransactions]);
  const handleLoadPreviousTransactions = useCallback(() => {
    const previousPage = page - 1;
    if (previousPage > 0) {
      fetchTransactions(previousPage, pageSize);
      setPage(previousPage);
    }
  }, [page, pageSize, fetchTransactions]);
  
  const handleKundeTopp50Click = useCallback(() => {
    dispatch(clearData());
    const currentKundenummer = kundenummerInput.trim();
    const currentVaregruppe = varegruppe.trim();
    if (!validateKundenummer(searchedKundenummer)) {
      setError('Enter 6 siffer Kundenummer');
      return;
    }

    try {
      setShowYearOptions(true);

      const years = Object.keys(selectedYears)
        .filter((year) => selectedYears[parseInt(year, 10)])
        .map((year) => year.slice(-2))
        .join(',');
       
     
      dispatch(fetchKundeSalgDataRequest(currentKundenummer, years, CSharpUtils.T_companyID, currentVaregruppe));
      setSalgDataType('customerData');
    } catch (err) {
      handleError(err);
    }
  }, [searchedKundenummer,varegruppe, selectedYears, dispatch]);

  const handleHentSalgClick = useCallback(() => {
    dispatch(clearData());

    if (!validateAlfa(alfa)) {
      setError('Tast inn alfakode, tre karakterer');
      return;
    }
    setShowYearOptions(false);
    setIsFirstSearch(false);

    dispatch(fetchSalgDataRequest(CSharpUtils.T_companyID, alfa,artikkel));
    dispatch(fetchSalgVareDataRequest(CSharpUtils.T_companyID, alfa, artikkel));
   
    setSalgDataType('salgData');
     }, [alfa, artikkel, dispatch]);
     useEffect(() => {
      setSisteSalgData(Salg?.[0]?.last_sale || 'NA');
    }, [Salg]);
    
  useEffect(() => {
    if (kundenummerInput && kundenummerInput.length === 6) {
      setSearchedKundenummer(kundenummerInput);
    }
  }, [kundenummerInput]);

  // Fetch popup data when a ref number is clicked
  const fetchPopupData = useCallback(
    (refNumber: string) => {
      setIsPopupLoading(true); // Start loading spinner
      dispatch(fetchOrderDetailRequest(CSharpUtils.T_companyID, refNumber)); // Dispatch Redux action
    },
    [dispatch]
  );
  // Monitor changes in OrderDetail using useEffect
  useEffect(() => {
    if (OrderDetail && Object.keys(OrderDetail).length > 0) {
      setPopupData(OrderDetail); // Update popupData
      setIsPopupLoading(false); // Stop loading spinner
    }
  }, [OrderDetail]); // Trigger whenever OrderDetail changes
    // Clear popup data
  const clearPopupData = useCallback(() => {
    setPopupData(null);
    dispatch(clearOrderDetailData()); // Clear Redux state
  }, [dispatch]);
  const exportToCSV = (transactionsData: TransactionData[]) => {
    if (!transactionsData || transactionsData.length === 0) {
      setError('No data available to export.');
      return;
    }

    const headers = ['Dato', 'Tid', 'Tekst', 'Ref.nr', 'Kundenr', 'Antall', 'Ident', 'Sign', 'Lager', 'Lokasjon'];

    const csvContent = [
      headers.join(';'),
      ...transactionsData.map((row) => {
        if (row.count !== undefined && row.sign !== undefined) {
          row.count = row.sign === '-' ? -Math.abs(row.count) : Math.abs(row.count);
        }

        const rowData = [
          row.date || '',
          row.time || '',
          row.routine || '',
          row.ref_number || '',
          row.customer || '',
          row.count || '',
          row.init || '',
          row.sign || '',
          row.lk_code || '',
          row.location || ''
        ];

        return rowData.join(';');
      })
    ].join('\n');

    const utf8Bom = '\uFEFF';
    const fullCsvContent = utf8Bom + csvContent;

    const fileName = `Transaksjoner_${alfa}${artikkel}`;

    const element = document.createElement('a');
    element.setAttribute(
      'href',
      `data:text/csv;charset=utf-8,${encodeURIComponent(fullCsvContent)}`
    );
    element.setAttribute('download', `${fileName}.csv`);

    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };
  const exportKundeSalgToCSV = (kundeSalgData: KundeSalgData[]) => {
    if (!kundeSalgData || kundeSalgData.length === 0) {
      setError('No data available to export.');
      return;
    }
    // Define the headers for the CSV
    const headers = [
      'Kundenummer',
      'Antall',
      'Alfa',
      'Artikkelnummer',
      'Beskrivelse',
      'Artikkelgruppe',
    ];
    // Create the CSV content
    const csvContent = [
      headers.join(';'), // Add headers
      ...kundeSalgData.map((row) => {
        // Map each row to the CSV format
        const rowData = [
          searchedKundenummer || '',
          row.count || '',
          row.alpha|| '',
          row.item_number || '',
          row.description || '',
          row.article_group || '',
        ];
        return rowData.join(';'); // Join columns with a semicolon
      }),
    ].join('\n'); // Join rows with a newline
    // Add UTF-8 BOM to ensure correct encoding
    const utf8Bom = '\uFEFF';
    const fullCsvContent = utf8Bom + csvContent;
    // Define the file name
    const fileName = `Kunde_Topp_100`;
    // Trigger download in the browser
    const element = document.createElement('a');
    element.setAttribute(
      'href',
      `data:text/csv;charset=utf-8,${encodeURIComponent(fullCsvContent)}`
    );
    element.setAttribute('download', `${fileName}.csv`);
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };
  const handleExportClick = () => {
    
    if (salgDataType === 'transactionData') {
    dispatch(exportTRANSAKSJONERRequest(CSharpUtils.T_companyID, alfa, artikkel, 1, 50000));
      exportToCSV(Transaction);
    } else if (salgDataType === 'customerData') {
    
      // Dispatch an action specific to exporting customer data
      dispatch(fetchKundeSalgDataRequest(searchedKundenummer, '', CSharpUtils.T_companyID, varegruppe));
      exportKundeSalgToCSV(KundeSalg);
    } else {
      setError('No valid data type selected for export.');
    }
  };

 /* useEffect(() => {
    if (salgDataType === 'transactionData' && ExportTransactions && ExportTransactions.length > 0) {
      exportToCSV(ExportTransactions);
      dispatch(clearData());
    } else if (salgDataType === 'customerData' && KundeSalg && KundeSalg.length > 0) {
      exportKundeSalgToCSV(KundeSalg);
      dispatch(clearData());
    }
  }, [ExportTransactions, KundeSalg, salgDataType, dispatch]);*/
  

  return (
    <div className={classes.root} >
      
       <Paper elevation={0} sx={{ padding: 3,maxWidth: '1300px',backgroundColor: 'rgba(255, 255, 255, 0.7)'  }}>
      
       <TableContainer className={classes.container}
         >
        {/* Search Fields Section */}
        <SearchFields
          alfa={alfa}
          artikkel={artikkel}
          varegruppe={varegruppe}
          kundenummerInput={kundenummerInput}
          setAlfa={setAlfa}
          setArtikkel={setArtikkel}
          setVaregruppe={setVaregruppe}
          setKundenummerInput={setKundenummerInput}
          suggestedCustomers={KundeData || []}
          handleCustomerSelect={(customer) => {
            setKundenummerInput(customer.id);
            setSearchedKundenummer(customer.id);
            setSuggestedCustomers([]);
          }}
          kundenummerRef={kundenummerRef}
        />

        {/* Header with Fetch and Export options */}
        <Header
          onFetchTransactions={fetchTransactions}
          onFetchSalg={handleHentSalgClick}
          onFetchKundeTopp50={handleKundeTopp50Click}
          onhandleExport={handleExportClick}
          isExportDisabled={isExportDisabled}
        />

        {/* Year Options Section */}
        {showYearOptions && (
          <YearOptions
            selectedYears={selectedYears}
            handleYearChange={(year) =>
              setSelectedYears((prev) => ({ ...prev, [year]: !prev[year] }))
            }
          />
        )}

        {/* Error Handling */}
        {(error || reduxError) && <Typography color="error">{error || reduxError}</Typography>}

        {/* VarerDetails Section */}
        {(salgDataType === 'transactionData' || salgDataType === 'salgData') && (
          <VarerDetails varerdata={SalgVarer} sistesalg={sisteSalgData} />
        )}

        {/* Scrollable Content Section */}
      
          {!(salgDataType === 'customerData' || salgDataType === 'transactionData') ? (
            <YearlySales data={Salg} varerdata={SalgVarer} sistesalg={sisteSalgData} />
          ) : (
            (salgDataType === 'customerData' && KundeSalg.length > 0) ||
            (salgDataType === 'transactionData' && Transaction.length > 0) ? (
              <GridView
                data={salgDataType === 'customerData' ? KundeSalg : Transaction}
                kundenummer={searchedKundenummer}
                dataType={salgDataType}
              popupData={popupData}
              fetchPopupData={fetchPopupData}
              clearPopupData={() => dispatch(clearOrderDetailData())}
              isPopupLoading={isPopupLoading} // Pass isPopupLoading
              setIsPopupLoading={setIsPopupLoading} // Pass setIsPopupLoading
              setPopupData={setPopupData} // Pass setPopupData
              />
            ) : null
          )}

          {/* Pagination Controls */}
          {(salgDataType === 'transactionData') && 
          (salgDataType === 'transactionData' ? Transaction.length > 0 : KundeSalg.length < 0) &&
          hasNextPage && (
            <Box ml={2}>
              <PaginationControls
                hasNextPage={hasNextPage}
                page={page}
                handleLoadMoreNext={handleLoadMoreTransactions}
                handleLoadMorePrevious={handleLoadPreviousTransactions}
                isFirstSearch={isFirstSearch}
              />
            </Box>
          )}
        </TableContainer>
       
      </Paper>
    </div>
  );
};

export default Vars;
