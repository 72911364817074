import React, { useState } from "react";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  IconButton,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  CircularProgress,
  Grid,
} from "@mui/material";
import { FilterAlt } from "@mui/icons-material";
import { makeStyles } from "tss-react/mui";
import { Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";

import {
  YearlySalesData,
  KundeSalgData,
  TransactionData,
} from "../../models/NV01SALG";

interface GridViewProps {
  data: (KundeSalgData | TransactionData | YearlySalesData)[];
  kundenummer?: string;
  dataType: string;
  popupData: any | null;
  fetchPopupData: (refNumber: string) => void;
  clearPopupData: () => void;
  isPopupLoading: boolean;
  setIsPopupLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setPopupData: React.Dispatch<React.SetStateAction<any | null>>;
}

const useStyles = makeStyles()(() => ({
  root: { margin: 20, fontSize: '1em !important', color: '#fff' },
  container: {
    height: `calc(100vh - 150px)`,
    border: "none",
    maxHeight: "481px",
    overflowY: "auto",
  },
}));

const GridView: React.FC<GridViewProps> = ({
  data,
  kundenummer,
  dataType,
  popupData,
  fetchPopupData,
  clearPopupData,
}) => {
  const [filters, setFilters] = useState<{ [key: string]: string }>({});
  const [filterColumn, setFilterColumn] = useState<string | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { classes } = useStyles();
  const [PopupData, setPopupData] = useState(false);
  const [IsPopupLoading, setIsPopupLoading] = useState(false);
  const handleFilterChange = (columnName: string, value: string) => {
    setFilters({ ...filters, [columnName]: value });
  };

  const applyFilters = (
    item: KundeSalgData | TransactionData | YearlySalesData
  ) => {
    return Object.keys(filters).every((key) => {
      const value = item[key as keyof (KundeSalgData | TransactionData | YearlySalesData)]
        ?.toString()
        .toLowerCase();

      if (!value) {
        return false;
      }

      if (key === "ref_number") {
        return value.startsWith(filters[key].toLowerCase());
      }

      return value.includes(filters[key].toLowerCase());
    });
  };

  const handleRefNumberClick = async (refNumber: string) => {
    setIsPopupLoading(true); // Start loading spinner
    try {
      await fetchPopupData(refNumber); // Fetch popup data
      setDialogOpen(true); // Open dialog after fetching
    } catch (error) {
      console.error("Error fetching popup data:", error);
    } finally {
      setIsPopupLoading(false); // Stop spinner
    }
  };

  const handleDialogClose = () => {
    clearPopupData(); // Dispatch clearData to Redux
    setDialogOpen(false); // Close the popup
  };
  const columnDefinitions: { [key: string]: { label: string; field: string }[] } = {
    customerData: [
      { label: "Kundenummer", field: "kundenummer" },
      { label: "Antall", field: "count" },
      { label: "Alfa", field: "alpha" },
      { label: "Artikkel", field: "item_number" },
      { label: "Varebetegnelse", field: "description" },
      { label: "Varegruppe", field: "article_group" },
    ],
    transactionData: [
      { label: "Dato", field: "date" },
      { label: "Tid", field: "time" },
      { label: "Tekst", field: "routine" },
      { label: "Ref.nr", field: "ref_number" },
      { label: "Kundenr.", field: "customer" },
      { label: "Antall", field: "count" },
      { label: "Ident", field: "user" },
      { label: "Sign", field: "init" },
      { label: "Lager", field: "lk_code" },
      { label: "Lokasjon", field: "location" },
    ],
  };

  const columns = columnDefinitions[dataType] || [];

  return (
    <>
    <Paper >
      <TableContainer className={classes.container}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((col) => (
                  <TableCell key={col.field}>
                    <Box display="flex" alignItems="center">
             {col.label}
                      <IconButton size="small" onClick={() => setFilterColumn(col.field)}>
               <FilterAlt />
             </IconButton>
             {col.field === "ref_number" && (
                        <Tooltip title="I dette feltet søkes det fra begynnelsen av ref.nummeret." placement="top">
                          <InfoIcon sx={{ color: "Green", fontSize: 22, marginLeft: 1 }} />
               </Tooltip>
             )}
           </Box>
           {filterColumn === col.field && (
             <TextField
               size="small"
               variant="outlined"
               value={filters[col.field] || ""}
               onChange={(e) => handleFilterChange(col.field, e.target.value)}
               sx={{ marginTop: 1 }}
             />
           )}
         </TableCell>
         
         
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.filter(applyFilters).map((item, index) => (
                <TableRow key={index}>
                  
                {columns.map((col) => (
                    <TableCell key={col.field}>
                      {col.field === "kundenummer" 
    ? kundenummer || (item as any)[col.field]?.toString() 
    : col.field === "ref_number" 
        ? (
            <Typography
                component="a"
                onClick={() => handleRefNumberClick((item as any)[col.field])}
                sx={{ color: "blue", textDecoration: "underline", cursor: "pointer" }}
                  >
                {(item as any)[col.field]}
            </Typography>
        ) 
                      : (item as any)[col.field]?.toString()}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
      {dialogOpen && popupData &&(
      <Dialog
        open={dialogOpen }
        onClose={handleDialogClose}
        fullWidth
        maxWidth={popupData?.varer?.length > 3 ? "md" : "sm"}
        sx={{
          '& .MuiDialog-paper': {
            minWidth: popupData?.varer?.length > 0 ? '600px' : '300px', // Adjust minimum width
            width: 'auto', // Allow dialog to shrink/grow based on content
            maxWidth: 'md', // Prevent it from overflowing the viewport
          },
        }}
      >
        <DialogTitle>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h5">
            </Typography>
            <IconButton onClick={handleDialogClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          {IsPopupLoading ? (
            <CircularProgress />
          ) : popupData ? (
            <Box>
              <Box sx={{ p: 3, border: '1px solid #e0e0e0', borderRadius: 2, backgroundColor: '#f0f4fa' }}>
  <Grid container spacing={2}>
    {/* Header Row */}
    <Grid item xs={4}>
      <Typography variant="body2">
        <strong>Ordre:</strong> {popupData.orderNumber}
      </Typography>
    </Grid>
    <Grid item xs={4}>
      <Typography variant="body2">
        <strong>Status:</strong> {popupData.status}
      </Typography>
    </Grid>
    <Grid item xs={4}>
      <Typography variant="body2">
        <strong>Sum U/MVA:</strong> {popupData.total_pris} NOK
      </Typography>
    </Grid>
    {/* Row 2 */}
    <Grid item xs={4}>
      <Typography variant="body2">
        <strong>Knr:</strong> {popupData.kunde}
      </Typography>
    </Grid>
    <Grid item xs={4}>
      <Typography variant="body2">
        <strong>Navn:</strong> {popupData.navn1}
      </Typography>
    </Grid>
    <Grid item xs={4}>
      <Typography variant="body2">
        <strong>Ordredato:</strong> {popupData.dato}
      </Typography>
    </Grid>
    {/* Row 3 */}
    <Grid item xs={4}>
      <Typography variant="body2">
        <strong>Adresse:</strong> {popupData.adr}
      </Typography>
    </Grid>
    <Grid item xs={4}>
      <Typography variant="body2">
        <strong>Post/Sted:</strong> {popupData.postnr}-{popupData.poststed}
      </Typography>
    </Grid>
    <Grid item xs={4}>
      <Typography variant="body2">
      <strong>Bet.kode:</strong> {popupData.bm}
      </Typography>
    </Grid>
    {/* Row 4 */}
    <Grid item xs={4}>
      <Typography variant="body2">
        <strong>V.adr1:</strong> {popupData.vadr1}
      </Typography>
    </Grid>
    <Grid item xs={4}>
      <Typography variant="body2">
        <strong>V.adr2:</strong> {popupData.vadr2}
      </Typography>
    </Grid>
    <Grid item xs={4}>
      <Typography variant="body2">
        <strong>V.adr3:</strong> {popupData.vadr3}
      </Typography>
    </Grid>
    {/* Row 5 */}
    <Grid item xs={4}>
      <Typography variant="body2">
      <strong>V.adr4:</strong> {popupData.vadr4}
      </Typography>
    </Grid>
    <Grid item xs={4}>
      <Typography variant="body2">
        <strong>LK:</strong> {popupData.lager}
      </Typography>
    </Grid>
    <Grid item xs={4}>
      <Typography variant="body2">
        <strong>Kred.type:</strong> {popupData.kredtype}
      </Typography>
    </Grid>
    {/* Row 6 */}
    <Grid item xs={4}>
      <Typography variant="body2">
        <strong>Rekv.nr:</strong> {popupData.rekvnr}
      </Typography>
    </Grid>
    <Grid item xs={4}>
      <Typography variant="body2">
        <strong>OM:</strong> {popupData.om}
      </Typography>
    </Grid>
    <Grid item xs={4}>
      <Typography variant="body2">
        <strong>Avd:</strong> {popupData.avd}
      </Typography>
    </Grid>
  </Grid>
</Box>
              <TableContainer sx={{ maxHeight: 400, overflow: "auto" }}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                    <TableCell sx={{ fontWeight: "bold", textAlign: "center" }}>Alfa</TableCell>
                <TableCell sx={{ fontWeight: "bold", textAlign: "center" }}>Artikkel</TableCell>
                <TableCell sx={{ fontWeight: "bold", textAlign: "center" }}>Bestilt</TableCell>
                <TableCell sx={{ fontWeight: "bold", textAlign: "center" }}>Levert</TableCell>
                <TableCell sx={{ fontWeight: "bold", textAlign: "center" }}>Tekst</TableCell>
                <TableCell sx={{ fontWeight: "bold", textAlign: "center" }}>Npris</TableCell>
                <TableCell sx={{ fontWeight: "bold", textAlign: "center" }}>Rabatt</TableCell>
                <TableCell sx={{ fontWeight: "bold", textAlign: "center" }}>Tillegg</TableCell>
                <TableCell sx={{ fontWeight: "bold", textAlign: "center" }}>Pris Type</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {popupData.varer.map((item: any, index: number) => (
                      <TableRow key={index}>
                             <TableCell sx={{ textAlign: "center" }}>{item.alfa}</TableCell>
                  <TableCell sx={{ textAlign: "center" }}>{item.artikkel}</TableCell>
                  <TableCell sx={{ textAlign: "center" }}>{item.bestilt}</TableCell>
                  <TableCell sx={{ textAlign: "center" }}>{item.levert}</TableCell>
                  <TableCell sx={{ textAlign: "center" }}>{item.tekst}</TableCell>
                  <TableCell sx={{ textAlign: "center" }}>{item.npris}</TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                          {item.rab1}
                          {item.rab2 ? ` | ${item.rab2}${item.rab3 || ''}` : ''}
                        </TableCell>
                        <TableCell>{item.tillegg}</TableCell>
                        <TableCell>{item.pris_type || "N/A"}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          ) : (
            <Typography>No data available for this Ref.nr.</Typography>
          )}
        </DialogContent>
      </Dialog>
      )}
    </>
  );
};

export default GridView;
