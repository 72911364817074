// ████████╗██████╗  █████╗ ███╗   ██╗███████╗ █████╗  ██████╗████████╗██╗ ██████╗ ███╗   ██╗███████╗
// ╚══██╔══╝██╔══██╗██╔══██╗████╗  ██║██╔════╝██╔══██╗██╔════╝╚══██╔══╝██║██╔═══██╗████╗  ██║██╔════╝
//    ██║   ██████╔╝███████║██╔██╗ ██║███████╗███████║██║        ██║   ██║██║   ██║██╔██╗ ██║███████╗
//    ██║   ██╔══██╗██╔══██║██║╚██╗██║╚════██║██╔══██║██║        ██║   ██║██║   ██║██║╚██╗██║╚════██║
//    ██║   ██║  ██║██║  ██║██║ ╚████║███████║██║  ██║╚██████╗   ██║   ██║╚██████╔╝██║ ╚████║███████║
//    ╚═╝   ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═══╝╚══════╝╚═╝  ╚═╝ ╚═════╝   ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝
//                                                                                              
// TRANSACTION TO HEILRLOOM
export const TRANS_GET_INIT = 'TRANS_GET_INIT';
export const TRANS_GET = 'TRANS_GET';
export const TRANS_GET_SUCCESS = 'TRANS_GET_SUCCESS';
export const TRANS_GET_FINISHED = 'TRANS_GET_FINISHED';
export const TRANS_GET_FAILED = 'TRANS_GET_FAILED';

//
export const TRANS_FORMDATA_POST_INIT = 'TRANS_FORMDATA_POST_INIT';
export const TRANS_FORMDATA_POST = 'TRANS_FORMDATA_POST';
export const TRANS_FORMDATA_POST_SUCCESS = 'TRANS_FORMDATA_POST_SUCCESS';
export const TRANS_FORMDATA_POST_FAILED = 'TRANS_FORMDATA_POST_FAILED';

export const TRANS_FORMDATA_TO_WEBSOCKET = 'TRANS_FORMDATA_TO_WEBSOCKET';


//
export const TRANS_OMW_FORMDATA_POST_INIT = 'TRANS_OMW_FORMDATA_POST_INIT';
export const TRANS_OMW_POST_FORMDATA_INIT = 'TRANS_OMW_POST_FORMDATA_INIT';
export const TRANS_OMW_POST_FORMDATA_SUCCESS = 'TRANS_OMW_POST_FORMDATA_SUCCESS';
export const TRANS_OMW_POST_FORMDATA_FAILED = 'TRANS_OMW_POST_FORMDATA_FAILED';
//
export const UPDATE_PRESSED_KEY = 'UPDATE_PRESSED_KEY';
export const UPDATE_DIALOG_STATE = 'UPDATE_DIALOG_STATE';

//
export const TRANS_TARGET_UPDATED = 'TRANS_WS_GET_FAILED';
//
export const TRANS_UPDATE_INPUT_STATE = 'TRANS_UPDATE_INPUT_STATE';
export const TRANS_UPDATE_INPUT_ARRAY_STATE = 'TRANS_UPDATE_INPUT_ARRAY_STATE';
export const TRANS_UPDATE_INPUT_ARRAY_LINE_STATE = 'TRANS_UPDATE_INPUT_ARRAY_LINE_STATE';
//
export const TRANS_SET_INPUT_STATE = 'TRANS_SET_INPUT_STATE';
export const TRANS_SET_FOCUSED_FIELD = 'TRANS_SET_FOCUSED_FIELD';
export const TRANS_SET_CURRENT_FOCUSED_FIELD = 'TRANS_SET_CURRENT_FOCUSED_FIELD';
//
export const TRANS_SET_NO_FOCUS_ON_ANY_FIELD = 'TRANS_SET_NO_FOCUS_ON_ANY_FIELD';
//
export const TRANS_DEFAULT_SET_FOCUSED_FIELD = 'TRANS_DEFAULT_SET_FOCUSED_FIELD';
//
export const TRANS_SET_FOCUSED_ENABLED = 'TRANS_SET_FOCUSED_ENABLED';
//
export const TRANS_SET_VSE_DIALOG = 'TRANS_SET_VSE_DIALOG';
export const TRANS_SET_SHOW_HELP_CONTENT = 'TRANS_SET_SHOW_HELP_CONTENT';
//
export const TRANS_SET_SHOW_OPEN_MODAL_WINDOW = 'TRANS_SET_SHOW_OPEN_MODAL_WINDOW';

//
export const TRANS_RESET_HTML_CONTENT = 'TRANS_RESET_HTML_CONTENT';
export const TRANS_RESET_HTML_HELP_CONTENT = 'TRANS_RESET_HTML_HELP_CONTENT';
//
export const TRANS_RESET_KEY_INPUT_FIELD_BINDING = 'TRANS_RESET_KEY_INPUT_FIELD_BINDING';
export const TRANS_SET_HELP_TEXT = 'TRANS_SET_HELP_TEXT';
export const TRANS_SET_HELP_PARAMS = 'TRANS_SET_HELP_PARAMS';
//
export const TRANS_SET_EDIT_HELP_PARAMS = 'TRANS_SET_EDIT_HELP_PARAMS';
export const TRANS_SET_EDIT_HELP_DIALOG_OPEN_STATE = 'TRANS_SET_EDIT_HELP_DIALOG_OPEN_STATE';
//
export const TRANS_SET_CLICKED_BTN = 'TRANS_SET_CLICKED_BTN';

// TRANSACTION TO JAVA API
export const POST_TRANSACTION_FOR_DATA_TO_JAVA_API = 'POST_TRANSACTION_FOR_DATA_TO_JAVA_API';



// ██╗  ██╗███████╗██╗     ██████╗                ██████╗ ██╗  ██╗███████╗
// ██║  ██║██╔════╝██║     ██╔══██╗              ██╔═══██╗██║  ██║██╔════╝
// ███████║█████╗  ██║     ██████╔╝    █████╗    ██║   ██║███████║█████╗  
// ██╔══██║██╔══╝  ██║     ██╔═══╝     ╚════╝    ██║   ██║██╔══██║██╔══╝  
// ██║  ██║███████╗███████╗██║                   ╚██████╔╝██║  ██║██║     
// ╚═╝  ╚═╝╚══════╝╚══════╝╚═╝                    ╚═════╝ ╚═╝  ╚═╝╚═╝     
//
export const TRANS_HELP_GET_INIT = 'TRANS_HELP_GET_INIT';
export const TRANS_HELP_GET = 'TRANS_HELP_GET';
export const TRANS_HELP_GET_SUCCESS = 'TRANS_HELP_GET_SUCCESS';
export const TRANS_HELP_GET_FAILED = 'TRANS_HELP_GET_FAILED';
//
export const TRANS_API_HELP_GET_INIT = 'TRANS_API_HELP_GET_INIT';
export const TRANS_API_HELP_GET = 'TRANS_API_HELP_GET';
export const TRANS_API_HELP_GET_SUCCESS = 'TRANS_API_HELP_GET_SUCCESS';
export const TRANS_API_HELP_GET_FAILED = 'TRANS_API_HELP_GET_FAILED';
//
export const TRANS_API_EDIT_HELP_INIT = 'TRANS_API_EDIT_HELP_INIT';
export const TRANS_API_EDIT_HELP_POST_INIT = 'TRANS_API_EDIT_HELP_POST_INIT';
export const TRANS_API_EDIT_HELP_POST_SUCCESS = 'TRANS_API_EDIT_HELP_POST_SUCCESS';
export const TRANS_API_EDIT_HELP_POST_FAILED = 'TRANS_API_EDIT_HELP_POST_FAILED';


export const TRANS_SET_GET_IN_PROGRESS = 'TRANS_SET_GET_IN_PROGRESS';
export const TRANS_SET_POST_IN_PROGRESS = 'TRANS_SET_POST_IN_PROGRESS';
export const TRANS_SET_WS_IN_PROGRESS = 'TRANS_SET_WS_IN_PROGRESS';
//
export const TRANS_UPDATE_FORMDATA_FOR_BUG_REPORT = 'TRANS_UPDATE_FORMDATA_FOR_BUG_REPORT';



export const TRANS_SET_PROG_ID =   'TRANS_SET_PROG_ID';