import { Backdrop, Checkbox, CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import { Component } from "react";
import Moment from 'react-moment';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import Utils from "../../../felles/Utils";
import * as actions from '../../../store/actions/index';
import { AppState } from "../../../store/rootReducer";
import ACC1TableFooter from "./ACC1FormFooter";
import ACC1TableHead from "./ACC1FormHeader";
import EnhancedTableToolbar from "./ACC1FormToolbar";
import MenuItemTarget from "../../../models/MenuItemTarget";
import MenuEl from "../../../models/MenuEl";


class ACC1FormContent extends Component<Props> {
  state = {
    order: "desc",
    orderBy: "id",
    page: 0,
    rowsPerPage: -1 //10
  };

  componentDidMount() {
    this.props.getAccountungJobList();
  }


  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";
    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }
    this.setState({ order, orderBy });
  };

  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.props.onRequestSelected(this.props.data.map(n => n.id));
      return;
    }
    this.props.onRequestSelected([]);
  };

  handleCheckboxClick = (event, id: number) => {
    event.stopPropagation();
    const selectedIndex = this.props.numSelectedJob.indexOf(id as number);
    let newSelected: number[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(this.props.numSelectedJob, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(this.props.numSelectedJob.slice(1));
    } else if (selectedIndex === this.props.numSelectedJob.length - 1) {
      newSelected = newSelected.concat(this.props.numSelectedJob.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        this.props.numSelectedJob.slice(0, selectedIndex),
        this.props.numSelectedJob.slice(selectedIndex + 1)
      );
    }
    this.props.onRequestSelected(newSelected);
  };
  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  isSelected = (id: number) => this.props.numSelectedJob.indexOf(id) !== -1;

  render() {
    const { classes } = this.props;
    const { order, orderBy, rowsPerPage, page } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, this.props.data.length - page * rowsPerPage);
    //
    return (
      <div className={classes.root}>
        <Paper elevation={10}>
          <EnhancedTableToolbar numSelected={this.props.numSelectedJob.length} />
          <TableContainer className={classes.container}>
            <div className={classes.tableWrapper}>
              <Backdrop className={classes.backdrop} open={this.props.isFetchingACC1List}>
                <CircularProgress color="inherit" />
              </Backdrop>
              <Table stickyHeader className={classes.table} size="small" aria-labelledby="tableTitle">
                <ACC1TableHead
                  numSelected={this.props.numSelectedJob.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={this.handleSelectAllClick}
                  onRequestSort={this.handleRequestSort}
                  rowCount={this.props.data.length}
                />
                <TableBody>
                  {Utils.stableSort(this.props.data, Utils.getSorting(order, orderBy))
                    .map(n => {
                      const isSelected = this.isSelected(n.id as number);
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isSelected}
                          tabIndex={-1}
                          key={n.id}
                          selected={isSelected}
                        >
                          <TableCell padding="checkbox" sx={{ color: 'white', border: 'none' }}>
                            <Checkbox onClick={event => this.handleCheckboxClick(event, n.id)} checked={isSelected} sx={{ color: 'white', '&.Mui-checked': { color: "red" } }} />
                          </TableCell>
                          <TableCell className={classes.tableCell} align="center" component="th" scope="row" padding="none">{n.id}</TableCell>
                          <TableCell className={classes.tableCell} align="center" component="th" scope="row" padding="none"><Moment format="YYYY.MM.DD HH:mm">{n.created}</Moment></TableCell>
                          <TableCell className={classes.tableCell} align="center" component="th" scope="row" padding="none">{n.job}</TableCell>
                          <TableCell className={classes.tableCell} align="center" component="th" scope="row" padding="none">{n.userid}</TableCell>
                          <TableCell className={classes.tableCell} align="center" component="th" scope="row" padding="none">{n.returncode}</TableCell>
                          <TableCell className={classes.tableCell} align="center" component="th" scope="row" padding="none">{n.customer}</TableCell>
                          <TableCell className={classes.tableCell} align="center" component="th" scope="row" padding="none">{n.cost}</TableCell>
                          <TableCell className={classes.tableCell} align="center" component="th" scope="row" padding="none">{n.reference}</TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 49 * emptyRows }}><TableCell colSpan={9} /></TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </TableContainer>
          <ACC1TableFooter />
        </Paper>
      </div>
    );
  }
}

const ACC1FormContentStyled = withStyles(
  ACC1FormContent,
  (theme, props) => ({
    root: {
      marginTop: 65,
      marginLeft: 20,
      marginRight: 20,
      paddingTop: 0,
      width: '100%',//1900, //props.size.width + 'px',

      '@media only screen and (min-width: 1024px) and (max-height: 1366px)  and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 1.5)': {
        paddingTop: 0,
        marginTop: 8,

      },
    },
    backdrop: {      zIndex: theme.zIndex.drawer + 1,      color: '#fff',    },
    container: {
      height: `calc(100vh - ${210}px)`, backgroundColor: '#2E2D2C', border: 'none', "&.MuiTableContainer-root": {
        "&::-webkit-scrollbar": {
          width: "20px"
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "#4f4d4c",

        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#706c6a",

        }
      }
    },

    paper: { marginTop: 30, },
    table: {
      minWidth: 700,
      fontSize: 200,
    },
    tableCell: { paddingTop: 0, height: 24, color: 'white', border: 'none', },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  })
);

interface sentFromParentComponent {
  size: any,
  setCompactType?: (val: any) => void;
}
//
interface StateProps {
  data: any[];
  isFetchingACC1List: boolean;
  numSelectedJob: any[];
  onSelectAllClick?: any;
  order?: string;
  orderBy?: string;
  numSelected?: number;
  rowCount?: number;
  page?: number;
  rowsPerPage?: number;
  classes?: any;
  menuItemTarget?: MenuItemTarget;
  menuItems?: MenuEl[];
}
//
interface DispatchProps {
  onRequestSelected: (Selected: any[]) => void;
  getAccountungJobList: () => void;
  setLocationPath: (val: any) => void;
}

type Props = sentFromParentComponent & StateProps & DispatchProps;

export function mapStateToProps(state: AppState): StateProps {
  return {
    data: state.accounting.accountingJobList,
    isFetchingACC1List: state.accounting.isFetchingACC1List,
    numSelectedJob: state.accounting.selectedJobList,
    menuItemTarget: state.menuItem.menuItemTarget,
    menuItems: state.menuItem.menuItems,
  }
}

export function mapDispatchToProps(dispatch): DispatchProps {
  return {
    onRequestSelected: (Selected: number[]) => dispatch(actions.accountingJobListSelectedUpdated(Selected)),
    getAccountungJobList: () => dispatch(actions.accountingJobListGet()),
    setLocationPath: (val) => dispatch(actions.setLocationPath(val)),
  }
}

export default connect<StateProps, DispatchProps, sentFromParentComponent, AppState>(mapStateToProps, mapDispatchToProps)(ACC1FormContentStyled);