import { Autocomplete, Backdrop, TextField, Theme } from '@mui/material';
import classNames from 'classnames';
import { createRef } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import ButtonVitec from '../../../components/ButtonVitec';
import DialogVitec from '../../../components/DialogVitec';
import TalenesTale from '../../../models/TalenesTale';
import * as actions from '../../../store/actions/index';
import { AppState } from '../../../store/rootReducer';


const useStyles = makeStyles()((theme: Theme) => ({
  customTooltip: {
    // I used the rgba color for the standard "secondary" color
    backgroundColor: '#fff',
    fontSize: '1em',
    color: 'black',
    border: '1px solid #fff',
    margin: 0,
    marginTop: 10,
    marginLeft: -5,
  },
  customArrow: { color: ' #fff', },
  root: { marginLeft: 0, marginRight: 0, paddingTop: 0 },
  formWrapper: { width: '100vw', display: 'table-cell', verticalAlign: 'middle', height: '93vh', padding: 20, },
  formAdd: {
    marginTop: 'min(20%)',
    marginBottom: 'min(20%)',
    boxShadow: '3px 3px 3px #222',
    horizontalAlign: 'center',
      backgroundColor: '#f3f3f3',
    width: 630,
    margin: '0 auto',
    padding: 30,
    border: '1px solid #333',
    borderRadius: '5px',



    '& .MuiTextField-root': { padding: 0, width: '30ch', border: 'none' },


  },
  mInt: { width: '100%', padding: '10px', marginBottom: '14px', marginTop: '3px', border: '1px solid #ccc' },
  myLabel: { width: 600, fontSize: '1.2em' },
  myTitle: { width: 600, fontSize: '1.6em', marginBottom: '30px', color: 'black' },
  formError: { fontSize: '1.4em', marginTop: '30px', color: 'red', align: 'center' },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: '#fff' },
  checkboxWrapper: { color: '#000' },
  checkbox: { display: 'inline-flex', marginLeft: 10, marginBottom: 10 },
  grpCheckbox: { marginLeft: 4, color: '#000', align: 'top' },
  myButtons: { display: 'flex', alignItems: 'center', justifyContent: 'space-between', },
  dialogCustomizedWidth: { minWidth: '400px', maxWidth: '400px', minHeight: '150px' },
  myDialogTitle: { textAlign: 'center', fontSize: '1.4em', width: '100%', },
  rowField: { display: 'flex', },

  '@media only screen and (min-width: 1024px) and (max-height: 1366px)  and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 1.5)': {
    root: { marginLeft: 0, marginRight: 0, paddingTop: 0 },
    formWrapper: { padding: 5 },
    formAdd: { width: 700, height: 500, padding: 15 },
    mInt: { width: '100%', padding: '5px', marginBottom: '7px', marginTop: '0px', border: '1px solid #ccc' },
    myLabel: { width: 500, fontSize: '1em' },
    myTitle: { width: 500, fontSize: '1.2em', marginBottom: '10px', color: 'black' },
    formError: { fontSize: '1.4em', marginTop: '30px', color: 'red', align: 'center' },
    backdrop: { zIndex: theme.zIndex.drawer + 1, color: '#fff' },
    checkboxWrapper: { color: '#000' },
    checkbox: { display: 'inline-flex', marginLeft: 5, marginBottom: 5 },
    grpCheckbox: { marginLeft: 4, color: '#000', align: 'top' },
    myButtons: { display: 'inline-flex', verticalAlign: 'top', margin: '0 50%', transform: 'translateX(-295px)' },
    dialogCustomizedWidth: { minWidth: '400px', maxWidth: '400px', minHeight: '150px' },
  },
}));

const CriteriaItemEdit = (props) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const formRef: any = createRef();
  const criteriaItemEditState: number = useSelector((state) => (state as AppState).tallenesTale.criteriaItemEditState);
  //const isUpdatingCriteriaItem: boolean = useSelector((state) => (state as AppState).tallenesTale.isUpdatingCriteriaItem);
  const criteriaItemList: TalenesTale[] = useSelector((state) => (state as AppState).tallenesTale.criteriaItemList);
  const criteriaItem: any = criteriaItemList.find(obj => obj.id === props.id);
  const { register, setValue, handleSubmit, formState: { errors }, } = useForm({
    defaultValues: {
      id: criteriaItem.id,
      firmId: criteriaItem.firmId,
      varegruppe: criteriaItem.varegruppe,
      navn: criteriaItem.navn,
      hovedgruppe: criteriaItem.hovedgruppe,
      gruppe: criteriaItem.gruppe,
      summeringsgruppe: criteriaItem.summeringsgruppe,

    },
  })

  const onSubmit = (data: any = undefined) => {
    dispatch(
      actions.criteriaItemUpdated(
        new TalenesTale(
          data.id,
          data.firmId,
          data.varegruppe,
          data.navn,
          data.hovedgruppe,
          data.gruppe,
          data.summeringsgruppe,
        )
      )
    );
  };

  //
  const onCancel = () => {
    dispatch(actions.setCriteriaEditState(0));
    // Som clean up
  };

  //
  const handleDiagValidateUserSaved = () => {
    dispatch(actions.setCriteriaEditState(0));
    dispatch(actions.criteriaItemListGet());
  };
  //


  //
  const generateCriteriaEditionStates = () => {
    const uVGR = [...new Set(criteriaItemList.map(item => item.varegruppe + '').sort())];
    const uNavn = [...new Set(criteriaItemList.map(item => item.navn).sort())];
    const uHovedGruppe = [...new Set(criteriaItemList.map(item => item.hovedgruppe).sort())];
    const uGruppe = [...new Set(criteriaItemList.map(item => item.gruppe).sort())];
    const uSummerGr = [...new Set(criteriaItemList.map(item => item.summeringsgruppe).sort())];
  
    switch (true) {
      case criteriaItemEditState === 1:
        return (
          <>
            <form ref={formRef} className={classes.formAdd} >
              <div className={classes.myTitle}>Endring av varegruppe</div>
              <div className={classes.rowField}>
                <input className={classNames(classes.mInt)} {...register("id")} hidden />
                <input className={classNames(classes.mInt)} {...register("firmId")} hidden />
                <Autocomplete id="c-vgr" options={uVGR} sx={{ width: 300 }} renderInput={(p) =>
                  <TextField {...p} label="Varegruppe" variant="standard" size="small" />}
                  onChange={(e) => { setValue('varegruppe', (e.target as any).innerText); }} />
                <TextField className={classNames(classes.mInt)} {...register("varegruppe", { required: true })}
                  onChange={(e) => { setValue('varegruppe', (e.target as any).value); }} variant="standard" size="small" style={{ marginTop: 16, }} />
              </div>
              <div className={classes.rowField}>
                <Autocomplete disablePortal id="c-Navn" options={uNavn} sx={{ width: 300 }} renderInput={(p) =>
                  <TextField {...p} label="Navn" variant="standard" size="small" />}
                  onChange={(e) => { setValue('navn', (e.target as any).innerText); }} />
                <TextField className={classNames(classes.mInt)}  {...register("navn", { required: true })}
                  onChange={(e) => { setValue('navn', (e.target as any).value); }} variant="standard" size="small" style={{ marginTop: 16, }} />
              </div>
              <div className={classes.rowField}>
                <Autocomplete disablePortal id="c-hovedgruppe" options={uHovedGruppe} style={{ width: 300 }} renderInput={(p) =>
                  <TextField {...p} label="Hovedgruppe" variant="standard" size="small" />}
                  onChange={(e) => { setValue('hovedgruppe', (e.target as any).innerText); }}
                />
                <TextField className={classNames(classes.mInt)} {...register("hovedgruppe", { required: true })}
                  onChange={(e) => { setValue('hovedgruppe', (e.target as any).value); }} variant="standard" size="small" />
              </div>
              <div className={classes.rowField}>
                <Autocomplete disablePortal id="c-gruppe" options={uGruppe} sx={{ width: 300 }} renderInput={(p) =>
                  <TextField {...p} label="Gruppe" variant="standard" size="small" />}
                  onChange={(e) => { setValue('gruppe', (e.target as any).innerText); }} />
                <TextField className={classNames(classes.mInt)} {...register("gruppe", { required: true })}
                  onChange={(e) => { setValue('gruppe', (e.target as any).value); }} variant="standard" size="small" style={{ marginTop: 16 }}
                />
              </div>
              <div className={classes.rowField}>
                <Autocomplete disablePortal id="c-summeringsgruppe" options={uSummerGr} sx={{ width: 300 }} renderInput={(p) =>
                  <TextField {...p} label="Summeringsgruppe" variant="standard" size="small" />}
                  onChange={(e) => { setValue('summeringsgruppe', (e.target as any).innerText); }} />
                <TextField className={classNames(classes.mInt)} {...register("summeringsgruppe", { required: true })}
                  onChange={(e) => { setValue('summeringsgruppe', (e.target as any).value); }} variant="standard" size="small" style={{ marginTop: 16 }}
                />
              </div>
              <div className={classes.myButtons}>
                <ButtonVitec _onclick={handleSubmit(onSubmit)} _width={80} _height={37} _type={'submit'} _label={'Lagre'} />
                <ButtonVitec _onclick={() => onCancel()} _width={80} _height={37} _type={'button'} _label={'Avbryt'} />
              </div>
              <div className={classes.formError}>
                {errors.varegruppe && errors.varegruppe.type === 'required' ? <div>Vennligst skriv inn Varegruppe.</div> : null}
                {errors.navn && errors.navn.type === 'required' ? <div>Vennligst skriv inn Navn.</div> : null}
                {errors.hovedgruppe && errors.hovedgruppe.type === 'required' ? <div>Vennligst skriv inn Hovedgruppe.</div> : null}
                {errors.gruppe && errors.gruppe.type === 'required' ? <div>Vennligst skriv inn Gruppe.</div> : null}
                {errors.summeringsgruppe && errors.summeringsgruppe.type === 'required' ? <div>Vennligst skriv inn Summeringsgruppe.</div> : null}
              </div>
            </form>
          </>
        );
      case criteriaItemEditState === 2:
        return (
          <DialogVitec>
            <div className={classes.myDialogTitle}>Informasjon lagret</div>
            <div className={classes.myButtons}>
              <ButtonVitec _onclick={handleDiagValidateUserSaved} _width={80} _height={37} _type={'button'} _label={'Ok'} />
            </div>
          </DialogVitec>
        );
      default:
        return null;
    }
  };
  //
  return (
    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={true}>
        {generateCriteriaEditionStates()}
      </Backdrop>
    </div>
  );
};

export default CriteriaItemEdit;
