// TALLENES TALE
export const TCRIT_LIST_GET_INIT = 'TCRIT_LIST_GET_INIT';
export const TCRIT_LIST_GET = 'TCRIT_LIST_GET';
export const TCRIT_LIST_GET_SUCCESS = 'TCRIT_LIST_GET_SUCCESS';
export const TCRIT_LIST_GET_FAILED = 'TCRIT_LIST_GET_FAILED';

export const VOID_TCRIT_LIST = 'VOID_TCRIT_LIST';

// TALLENES TALE
export const TCRIT_LIST_GET_AS_EXCEL_INIT = 'TCRIT_LIST_GET_AS_EXCEL_INIT';
export const TCRIT_LIST_GET_AS_EXCEL = 'TCRIT_LIST_GET_AS_EXCEL';
export const TCRIT_LIST_GET_AS_EXCEL_SUCCESS = 'TCRIT_LIST_GET_AS_EXCEL_SUCCESS';
export const TCRIT_LIST_GET_AS_EXCEL_FAILED = 'TCRIT_LIST_GET_AS_EXCEL_FAILED';
//
export const TCRIT_LIST_SELECTED_UPDATED = 'TCRIT_LIST_SELECTED_UPDATED';
//
export const TCRIT_LIST_SELECTED_DELETION_POST_INIT = 'TCRIT_LIST_SELECTED_DELETION_POST_INIT';
export const TCRIT_LIST_SELECTED_DELETION_POST = 'TCRIT_LIST_SELECTED_DELETION_POST';
export const TCRIT_LIST_SELECTED_DELETION_POST_SUCCESS = 'TCRIT_LIST_SELECTED_DELETION_POST_SUCCESS';
export const TCRIT_LIST_SELECTED_DELETION_POST_FAILED = 'TCRIT_LIST_SELECTED_DELETION_POST_FAILED';
//
export const TCRIT_ITEM_UPDATED_POST_INIT = 'TCRIT_ITEM_UPDATED_POST_INIT';
export const TCRIT_ITEM_UPDATED_POST = 'TCRIT_ITEM_UPDATED_POST';
export const TCRIT_ITEM_UPDATED_POST_SUCCESS = 'TCRIT_ITEM_UPDATED_POST_SUCCESS';
export const TCRIT_ITEM_UPDATED_POST_FAILED = 'TCRIT_ITEM_UPDATED_POST_FAILED';
//
export const TCRIT_ITEM_DELETION_POST_INIT = 'TCRIT_ITEM_DELETION_POST_INIT';
export const TCRIT_ITEM_DELETION_POST = 'TCRIT_ITEM_DELETION_POST';
export const TCRIT_ITEM_DELETION_POST_SUCCESS = 'TCRIT_ITEM_DELETION_POST_SUCCESS';
export const TCRIT_ITEM_DELETION_POST_FAILED = 'TCRIT_ITEM_DELETION_POST_FAILED';
//
export const TCRIT_ITEM_REGISTRATION_POST_INIT = 'TCRIT_ITEM_REGISTRATION_POST_INIT';
export const TCRIT_ITEM_REGISTRATION_POST = 'TCRIT_ITEM_REGISTRATION_POST';
export const TCRIT_ITEM_REGISTRATION_POST_SUCCESS = 'TCRIT_ITEM_REGISTRATION_POST_SUCCESS';
export const TCRIT_ITEM_REGISTRATION_POST_FAILED = 'TCRIT_ITEM_REGISTRATION_POST_FAILED';
//
export const TCRIT_DELETION_STATE = 'TCRIT_DELETION_STATE';
export const TCRIT_UPDATED_STATE = 'TCRIT_UPDATED_STATE';
export const TCRIT_REGISTRATION_STATE = 'TCRIT_REGISTRATION_STATE';
//
export const TCRIT_LIST_EXPORT_TO_EXCELL_INIT = 'TCRIT_LIST_EXPORT_TO_EXCELL_INIT';
export const TCRIT_LIST_EXPORT_TO_EXCELL = 'TCRIT_LIST_EXPORT_TO_EXCELL';
export const TCRIT_LIST_EXPORT_TO_EXCELL_SUCCESS = 'TCRIT_LIST_EXPORT_TO_EXCELL_SUCCESS';
export const TCRIT_LIST_EXPORT_TO_EXCELL_FAILED = 'TCRIT_LIST_EXPORT_TO_EXCELL_FAILED';
//
export const TCRIT_DELETION_DIALOGUE_UPDATED = 'TCRIT_DELETION_DIALOGUE_UPDATED';

export const TCRIT_SET_SELETED_LIST = 'TCRIT_SET_SELETED_LIST';
export const TCRIT_SET_CRITERIA_ITEM_ID = 'TCRIT_SET_CRITERIA_ITEM_ID';
export const TCRIT_FAVORITTLISTE_STATE = 'TCRIT_FAVORITTLISTE_STATE';