import { push } from '@lagunovsky/redux-react-router';
import { call, put } from 'redux-saga/effects';
import { autoLoginToAutodataDotNetAPI, loginToAutodataDotNet } from '../../felles/APICalls_NET';
import {
  autoLoginToAutodata, getRequestLinkPasswordLinkRecovery, loginToAutodata, logoutFromHeirloomAPITerminal, logoutFromHeirloomWebSite, requestOfPasswordChange
} from '../../felles/APICalls_SPRING';
import Utils from '../../felles/Utils';
import * as actions from '../actions';

export function* formLoginSent(action: any) {

  let isLoginAutodataSpring = true;
  //let isLoginAutodataDotnet = true;

  const btsg = '' + new Date().getTime();

  /******************************************************  Login to AUTODATA's API  **************************************************** */
  yield put(actions.userLoginToAutodataGetInit());
  try {
    const resAutodataSpring = yield loginToAutodata(action.userCred, btsg);
    if (resAutodataSpring && resAutodataSpring.data) {
      sessionStorage.setItem('token', resAutodataSpring.data.token);
      sessionStorage.setItem('btsg', btsg);
      yield put(actions.setTabId(btsg));
      yield put(actions.updateAutodataUserJWT(resAutodataSpring.data.token));
      yield put(actions.setUserAuthenticatedAgainstAutodataAPI(true));
    }
  } catch (error) {
    isLoginAutodataSpring = false;
    yield put(actions.userLoginToAutodataGetFailed(error));
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('btsg');
  }
  //
  try {
    const resAutodataDotNet = yield loginToAutodataDotNet(action.userCred);
    if (resAutodataDotNet && resAutodataDotNet.data) {
      sessionStorage.setItem('ctoken', resAutodataDotNet.data.token);
    }
  } catch (error) {
    //isLoginAutodataDotnet = false;
    yield put(actions.userLoginToAutodataGetFailed(error));
    sessionStorage.removeItem('ctoken');
  }
  //
  if (isLoginAutodataSpring /* && isLoginAutodataDotnet*/) {
    yield put(actions.updateAutodataUserAuth(true));
    yield put(push(`${Utils.HOMEPAGE_CONTEXT}/menu`));
    yield put(actions.setIsLogged(2));
  } else {
    yield put(actions.setLoginDialogOpeningState(true));
  }
}

/************************************************************************************************************ */
/************************************************ AUTO LOGIN ************************************************ */
/************************************************************************************************************ */
/************************************************************************************************************ */

//TODO: Fabrice => find a solution why Axios pops up Basic Auth alert.
export function* autoLoginformLoginSent(action: any) {
  //const isLogged = yield select(selectors.isLogged);
  const btsg = '' + new Date().getTime();
  // Login to AUTODATA's API
  yield put(actions.userLoginToAutodataGetInit());
  try {
    const resAutodata = yield autoLoginToAutodata(action.token, btsg);
    if (resAutodata && resAutodata.data) {
      sessionStorage.setItem('token', resAutodata.data.token);
      sessionStorage.setItem('btsg', btsg);
      yield put(actions.setTabId(btsg));
      yield put(actions.updateAutodataUserAuth(true));
      yield put(actions.updateAutodataUserJWT(resAutodata.data.token));
      yield put(actions.setUserAuthenticatedAgainstAutodataAPI(true));
      yield put(actions.setUserAuthenticatedAgainstHeirloomAPI(true));
      yield put(actions.setSessionUIState(1));
      yield put(actions.setIsLogged(1));
      //
      if (!action.performSilent) {
        if (action.transId.toString() === '') {
          yield put(push(`${Utils.HOMEPAGE_CONTEXT}/menu`));
        } else {
          yield put(push(`${Utils.HOMEPAGE_CONTEXT}/vse?transid=${action.transId}`));
        }
      }
    }
  } catch (error) {
    yield put(actions.userLoginToAutodataGetFailed(error));
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('btsg');
  }

  try {
    const resAutodataDotNetAPI = yield autoLoginToAutodataDotNetAPI();
    if (resAutodataDotNetAPI && resAutodataDotNetAPI.data) {
      sessionStorage.setItem('ctoken', resAutodataDotNetAPI.data.token);
    }
  } catch (error) {
    yield put(actions.userLoginToAutodataGetFailed(error));
    sessionStorage.removeItem('ctoken');
  }

  yield put(actions.setIsloading(false));
}

/************************************************************************************************************ */
/*************************************************** LOGOUT ************************************************* */
/************************************************************************************************************ */
/************************************************************************************************************ */
export function* formLogoutSent(action: any /* No refresh / redirection*/) {
  // logout from the Heirloom's API
  try {
      // intialize the logout process
    yield put(actions.userLogoutStarted());
    // Heirloom's API endpoint to logout
    // Make an asynchronous request using fetch
    const response = yield call(fetch, `${Utils.HEIRLOOM_SERVICE}/index.html`, {
      method: 'GET',
      headers: {
        'Authorization': 'Basic ' + btoa('BAD:BAD')
      }
    });
    // If the request is successful, then we can logout from the Heirloom's API
    if (response.ok) {
      yield logoutFromHeirloomAPITerminal();
      yield logoutFromHeirloomWebSite();
      const http = new XMLHttpRequest();
      http.open('get', Utils.HEIRLOOM_SERVICE + '/index.html', false, 'BAD', 'BAD');
      http.send('');
      http.open('get', Utils.HEIRLOOM_SERVICE + '/menu.html', false, 'BAD', 'BAD');
      http.send('');
    }
    // If the request is not successful, then we can throw an error
    if (!response.ok) {
      if (process.env.NODE_ENV === 'development') {
        throw new Error('Network response was not ok');
      }
    }
  } catch (error) {
    yield put(actions.userLogoutFailed(error));
    if (process.env.NODE_ENV === 'development') {
      console.log('Error login out from the Heilooms API: ', error);
    }
  } finally {
    yield sessionStorage.clear();
    yield put(push(`${Utils.HOMEPAGE_CONTEXT}/logout`));
    yield put(actions.resetStore());
    yield put(actions.onClear(action.loginState, action.isAlreadyLoggedFromAnotherTab, action.tabListSize, action.activeTab));
  }
}

/*
 * Call to backend to get a recovery password link by email
 */
export function* formPasswordLinkRecovery(action: any) {
  try {
    yield put(actions.userPasswordLinkRecoveryPostInit());
    yield getRequestLinkPasswordLinkRecovery(action.userCred);
    yield put(actions.userPasswordLinkRecoveryPostSuccess());
  } catch (error) {
    if ((error as any).response) {
      yield put(actions.userPasswordLinkRecoveryPostFailed((error as any)?.response?.data));
    } else {
      yield put(actions.userPasswordLinkRecoveryPostFailed(error));
    }
  }
}

/*
 * Call to backend saving the new passowrd
 */
export function* formPasswordReset(action: any) {
  try {
    yield put(actions.userPasswordResetStarted());
    yield requestOfPasswordChange(action.passParam);
    yield put(actions.userPasswordResetPostSuccess());
  } catch (error) {
    // move that under userPasswordResetPostFailed
    if ((error as any).response) {
      yield put(actions.userPasswordResetPostFailed((error as any)?.response?.data));
    } else {
      yield put(actions.userPasswordResetPostFailed(error));
    }
  }
}
