import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import hjelp from '../assets/images/help.svg';
import poweroff from '../assets/images/poweroff.svg';
import profil from '../assets/images/profil.svg';
import Utils from '../felles/Utils';
import C from '../felles/UtilsCSS';
import UserCred from '../models/UserCred';
import * as actions from '../store/actions/index';
import { history } from '../store/history';
import { AppState } from '../store/rootReducer';

// Define styles
const useStyles = makeStyles()(() => ({
  root: {
    backgroundColor: 'transparent',
    display: 'flex',
    marginTop: 6,
    [C.IPAD_10]: { marginTop: 6, width: '100%' },
    [C.SAMSUNG]: { width: '100%' },
  },
  child: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '8px',
    marginLeft: 'auto',
    marginRight: '0',
    cursor: 'pointer',
    fontWeight: 400,
  },
  subchild: { paddingTop: '1px', paddingLeft: '4px', },
}));

export default function LPNavigationProfile() {
  const { classes } = useStyles();
  const dispatch = useDispatch();

  // Use typed state from AppState
  const user = useSelector((state: AppState) => state.auth.userJWT);
  const isAlreadyLoggedFromAnotherTab = useSelector(
    (state: AppState) => state.auth.isAlreadyLoggedFromAnotherTab
  );
  const tabListSize = useSelector((state: AppState) => state.auth.tabListSize);

  // UseCallback for performance optimization
  const handleLogoutClicked = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      event.preventDefault();
      // update the state
      dispatch(actions.setIsLogged(4));
      dispatch(actions.setTabAsActive(true));
      // Logout
      dispatch(
        actions.UserLogoutFetchValidation(
          new UserCred('BAD', 'BAD', '', false),
          false,
          4,
          isAlreadyLoggedFromAnotherTab,
          tabListSize,
          true // activeTab
        )
      );
    },
    [dispatch, isAlreadyLoggedFromAnotherTab, tabListSize]
  );
  //
  const handleProfileClickHandler = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.preventDefault();
      history.push(`${Utils.HOMEPAGE_CONTEXT}/BR04`);
    },
    []
  );


  const handleHjelpClickHandler = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.preventDefault();
      const newWindow = window.open('https://www.vitec-autosystemer.com/dokumentasjon-autodata/', '_blank', 'noopener,noreferrer');
      if (newWindow) newWindow.opener = null;
    },
    []
  );


  //
  return (
    <div className={classes.root}>
      <div className={classes.child} onClick={handleHjelpClickHandler}>
        <img src={hjelp} alt="Hjelp" />
        &nbsp;Hjelp
      </div>
      <div
        className={classes.child}
        onClick={handleProfileClickHandler}
        style={{ lineHeight: 1 }}
      >
        <img src={profil} alt="Profile" />
        <div className={classes.subchild}>{user?.sub}</div>
      </div>
      <div className={classes.child} onClick={handleLogoutClicked}>
        <img src={poweroff} alt="Logout" />
        &nbsp;Logg&nbsp;ut
      </div>
    </div>
  );
}